import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initialCapitalizeChar'
})
export class InitialCapitalizeChar implements PipeTransform {
  transform(value: string, args: any[]): string {
    if (value === null) {
      return 'N';
    }
    return value.charAt(0).toUpperCase();
  }
}
