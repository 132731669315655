export const MESSAGES = {
  CREDENTIALS_MISMATCH: 'Your credentials do not match. Please try again.',
  BULK_UPLOAD_FAILED: 'Upload failed! Please check the format of the template.',
  CLIENTS_DELETED: 'The Client(s) have been deleted successfully.',
  JOB_DELETED_WITHOUT_CLIENTS: 'Job(s) successfully deleted and Client(s) moved back to client list.',
  JOB_DELETED_WITH_CLIENTS: 'Job(s) and Client(s) successfully deleted.',
  //MULTIPLE_RESULT_SELECTION: 'Selected option has been submitted.',
  JOB_CREATED: 'Job has been created with selected Client(s).',

  //source error messages of JIP page
  SOURCE_COI_MISMATCH: 'Source and Client COI mismatch.',
  UNABLE_TO_DISPLAY_RETRY_SKIP: 'Unable to display results, Please try again or skip source.',
  UNABLE_TO_DISPLAY: 'Unable to display results.',
  NO_RESULTS_FOUND_FOR_CLIENT: 'No results found for Client.',
  SOURCE_IS_UNAVAILABLE_RETRY_SKIP: 'Source is currently unavailable, Please try again or skip source.',
  SOURCE_IS_UNAVAILALE: 'Source is currently unavailable.',
  CAPTCHA_SKIPPED: 'Captcha Skipped',
  NO_CLIENT_SELECTED_FROM_MULTIPLE_RESULT: 'No Client selected from mutiple results prompted.',
  SOURCE_ALREADY_IN_PROGRESS:
    'Only sources which are in pending status will be paused. Sources in progress will continue processing till data extraction is completed',
  JOB_ALREADY_IN_PROGRESS: 'Job cannot be paused, as processing has already started for all sources.',
  SOURCE_LICENSE_EXPIRED_AUTOSKIPPED: 'Source License expired. Please contact admin for access.',
  //   LICENSE_EXPIRED: 'License expired. Please contact admin for access.',
  //   CLIENT_LIMIT_EXCEDED: (count) => {
  //     if (count == 0) return `You have ${count} clients remaining in your account. Please contact admin for access.`;
  //     else return `You have only ${count} clients remaining in your account, please try again with fewer clients.`;
  //   },
};
