import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DiscardJobService {
  private selectedJobs: [] = [];
  private selectedClients: [] = [];
  public jobsSubject = new BehaviorSubject({});
  public clientsSubject = new BehaviorSubject({});
  public discardCompleted = new BehaviorSubject({});

  constructor() {}

  cacheSelectedJobs(data: { tabName: string; selectedJobs: [] }) {
    if (TAB_NAME.JOBS_IN_PROGRESS == data.tabName) {
      this.selectedJobs = data.selectedJobs;
    }
  }

  cacheSelectedClients(data: { tabName: string; selectedClients: [] }) {
    if (TAB_NAME.JOBS_IN_PROGRESS == data.tabName) {
      this.selectedClients = data.selectedClients;
    }
  }

  public getSelectedJobs() {
    return this.selectedJobs;
  }

  public getSelectedClients() {
    let clients = this.selectedClients;
    return clients;
  }

  public emptySavedData() {
    this.selectedClients = [];
    this.selectedJobs = [];
  }
}

const TAB_NAME = {
  JOBS_IN_PROGRESS: 'JOBS_IN_PROGRESS',
  COMPLETED_JOBS: 'COMPLETED_JOBS'
};
