import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { of as observableOf, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { throwError as observableThrowError } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionService } from './session.service';
import { environment } from 'src/environments/environment.prod';
import { UtilService } from '../common/util.service';
import { RootModuleConfiguraion } from '../root-module-config';
@Injectable()
export class AuthService {
  constructor(
    private rootModulePermissions: RootModuleConfiguraion,
    private injector: Injector,
    private http: HttpClient,
    private ss: SessionService,
    private router: Router
  ) {}

  refreshToken(): Observable<any> {
    const url = environment.API_BASE + 'unauth/user/login';
    const t = {
      refreshToken: this.ss.get(this.ss.sessionKeys.REFRESH_TOKEN),
    };
    return this.http.post(url, t).pipe(
      map(
        (response: any) => {
          this.isAuthorized(response);
          this.updateUserSession(response);
          return response[this.ss.sessionKeys.AUTH_TOKEN];
        },
        (error) => {}
      )
    );
  }

  private updateUserSession(res) {
    if (res[this.ss.sessionKeys.AUTH_TOKEN]) {
      this.ss.set(this.ss.sessionKeys.AUTH_TOKEN, res.auth_token);
      if (res.refreshToken.split(' ')[1]) {
        this.ss.set(this.ss.sessionKeys.REFRESH_TOKEN, res.refreshToken.split(' ')[1]);
      }
      this.rootModulePermissions.checkAvailableModules();
      this.ss.set(this.ss.sessionKeys.USER_INFO, JSON.stringify(res.userDetails));
      this.ss.set(this.ss.sessionKeys.IS_LICENSED, res.isLicensed);
      if (res.isLicensed) {
        this.ss.set(this.ss.sessionKeys.NOTIFY_USER, res.notifyUser);
      }
    }
  }

  private isAuthorized(res: any) {
    if (res) {
      if (res.isError) {
        this.goToLogin();
      }
    } else {
      this.goToLogin();
    }
  }

  private isNonAuthRoute() {
    let result = false;
    if (this.router.url === '/login/troubleshoot') {
      result = true;
    }
    return result;
  }

  private goToLogin() {
    if (!this.isNonAuthRoute()) {
      this.ss.flush();
      const utilService = this.injector.get(UtilService);
      const url = utilService.getApplicationBaseHref() + '#/login';
      window.open(url, '_self');
    }
  }

  private getRefreshToken() {
    const t = this.ss.get(this.ss.sessionKeys.REFRESH_TOKEN);
    if (t === undefined || t === null || t === '') {
      return undefined;
    } else {
      return t;
    }
  }

  getAuthToken() {
    const t = this.ss.get(this.ss.sessionKeys.AUTH_TOKEN);
    if (t === undefined || t === null || t === '') {
      this.goToLogin();
    } else {
      return t;
    }
  }

  isRefreshTokenExist() {
    if (this.getRefreshToken() === undefined) {
      this.goToLogin();
    }
  }
}
