
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild } from '@angular/router';

import { SessionService } from './session.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private router: Router, private session: SessionService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.isValidRoute(route, state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.isValidRoute(route, state);
    }

    isValidRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.session.isValid()) {
            return true;
        } else {
            this.router.navigate(['login']);
        }
        // const self = this;
        // if (!this.session.isValid()) {
        //     return self.session.reInitialize().map(isLoggedIn => {
        //         if (!isLoggedIn) {
        //             self.router.navigate(['login']);
        //         }
        //         return true;
        //     });
        // }
        // return true;

    }

}
