import { Injectable } from '@angular/core';

@Injectable()
export class IntertabEventChannelService {
  public BROADCAST_EVENTS = {
    RE_RUN_INITIATED: 'RE_RUN_INITIATED',
  };
  private rerunBroadCastChannel;
  constructor() {
    this.rerunBroadCastChannel = new BroadcastChannel(this.BROADCAST_EVENTS.RE_RUN_INITIATED);
  }

  reRunInitiated() {
    this.rerunBroadCastChannel.postMessage(this.BROADCAST_EVENTS.RE_RUN_INITIATED);
  }

  getReRunBroadCastChannel() {
    return this.rerunBroadCastChannel;
  }
}
