import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { DashboardAPIService } from './dashboard/services/dashboard.api.service';
import { RootModuleConfiguraion } from './root-module-config';

@Injectable()
export class RootGuard implements CanActivate, CanActivateChild {
  ADMIN_URL = '/admin';
  constructor(private router: Router, private rootConfig: RootModuleConfiguraion, private dashboardAPI: DashboardAPIService) {}

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   return this.isValidRoute(route, state);
  // }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>((resolve) => {
      this.dashboardAPI.availableModules().subscribe((res) => {
        if (res && res['login-permission-info']) {
          this.rootConfig.setConfigurationObject(res['login-permission-info']);
          resolve(this.isValidRoute(route, state));
        }
      });
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.isValidRoute(route, state);
  }

  isValidRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    debugger;
    if (this.isAllowed(state)) {
      return true;
    } else {
      this.router.navigate(['login']);
      return false;
    }
  }

  isAllowed(state: RouterStateSnapshot): boolean {
    let moduleName = this.moduleAccessed(state);
    let configuration = this.rootConfig.getConfiguration();

    return configuration[moduleName];
  }

  moduleAccessed(state: RouterStateSnapshot) {
    let url = state.url;
    let moduleName = '';
    console.log(state.url);
    console.log(url.indexOf(this.ADMIN_URL));
    if (url.indexOf(this.ADMIN_URL) != -1) {
      moduleName = 'admin';
    }
    return moduleName;
  }
}
