import { Injectable } from '@angular/core';

import { HTTPAPIService } from 'src/app/common/http.api.service';
import { DASHBOARD_API_CONSTANT } from 'src/app/dashboard/constants/dashboard.api.constant';
import { REPORT_API_CONSTANT } from '../constants/report.api.constant';
import { ResponseContentType, RequestOptions } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ReportsAPIService {
  constructor(private http: HTTPAPIService) {}

  getKeyControllerList(clientId) {
    const url = DASHBOARD_API_CONSTANT.KEYCONTROLLER_LIST + '/' + clientId;
    return this.http.get(url);
  }

  getShareHolderList(clientId) {
    const url = DASHBOARD_API_CONSTANT.SHAREHOLDER_LIST + clientId;
    return this.http.get(url);
  }

  getOwnershipList(clientId) {
    const url = DASHBOARD_API_CONSTANT.OWNERSHIP_LIST + clientId;
    return this.http.get(url);
  }

  getMockOwnershipList(clientId) {
    const url = DASHBOARD_API_CONSTANT.MOCKOWNERSHIP_LIST;
    return this.http.get(url);
  }

  getKeyControllerData() {
    const url = DASHBOARD_API_CONSTANT.KEYCONTROLLER_DATA;
    return this.http.get(url);
  }

  getShareHolderData() {
    const url = DASHBOARD_API_CONSTANT.SHAREHOLDER_DATA;
    return this.http.get(url);
  }

  getOwnershipData() {
    const url = DASHBOARD_API_CONSTANT.OWNERSHIP_DATA;
    return this.http.get(url);
  }

  getSourceInfoTab(id: string) {
    const url = DASHBOARD_API_CONSTANT.SOURCE_INFO_DATA + '/' + id;
    return this.http.get(url);
  }
  getSubsidiaries(clientId) {
    //clientId = '2555';
    const url = REPORT_API_CONSTANT.SUBSIDIARIES_COUNTRY + clientId;
    return this.http.get(url);
  }

  getSubsidiariesName() {
    const url = REPORT_API_CONSTANT.SUBSIDIARIES_NAME;
    return this.http.get(url);
  }
  getSubsidiariesNameConfig() {
    const url = REPORT_API_CONSTANT.SUBSIDIARIES_NAME_CONFIG;
    return this.http.get(url);
  }
  getBranches(clientId) {
    //clientId = '2555';
    const url = REPORT_API_CONSTANT.BRANCHES + clientId;
    return this.http.get(url);
  }

  getBranchesName() {
    const url = REPORT_API_CONSTANT.BRANCHES_NAME;
    return this.http.get(url);
  }
  getBranchesNameConfig() {
    const url = REPORT_API_CONSTANT.BRANCHES_NAME_CONFIG;
    return this.http.get(url);
  }

  getClientInfo(clientId) {
    const url = DASHBOARD_API_CONSTANT.CLIENT_LIST + '/' + clientId;
    return this.http.get(url);
  }

  getClientInfoByVersionId(versionId) {
    const url = DASHBOARD_API_CONSTANT.CLIENT_BY_VERSION_ID + '/' + versionId;
    return this.http.get(url);
  }

  getReportGeneratedDate(clientId) {
    const url = DASHBOARD_API_CONSTANT.REPORT_GENERATED_DATE + '/' + clientId;
    return this.http.get(url);
  }

  getFinancialsData(clientId) {
    const url = DASHBOARD_API_CONSTANT.FINANCIALS + '/' + clientId;
    return this.http.get(url);
  }

  getOwnershipGraphList() {
    const url = DASHBOARD_API_CONSTANT.MOCKOWNERSHIP_LIST;
    return this.http.get(url);
  }

  getDetailedClientProfileData(clienId) {
    const url = DASHBOARD_API_CONSTANT.CLIENT_DETAILED_PROFILE + '/' + clienId;
    return this.http.get(url);
  }

  getMiniClientProfile(clientId) {
    const url = DASHBOARD_API_CONSTANT.CLIENT_MINI_PROFILE + '/' + clientId;
    return this.http.get(url);
  }
  // getFinancialsData(clientId) {
  //   const url = DASHBOARD_API_CONSTANT.FINANCIALS;
  //   return this.http.get(url);
  // }

  // downloadFile(clientId: string, fileType: string) {
  //   const url = REPORT_API_CONSTANT.SOURCE_INFO_FILE_DOWNLOAD + '/' + clientId;
  //   return this.http
  //     .getFile(url, { responseType: ResponseContentType.Blob })
  //     .map(res => {
  //       //return new Blob([res.blob()], { type: fileType });
  //       return new Blob([res], { type: fileType });
  //     });
  // }

  // downloadReportZip(clientId: string) {
  //   const url = REPORT_API_CONSTANT.REPORT_ZIP_FILE_DOWNLOAD + '/' + clientId;
  //   return this.http
  //     .getFile(url, { responseType: ResponseContentType.Blob })
  //     .map(res => {
  //       return {
  //         // blobData: new Blob([res.blob()], { type: 'application/zip' }),
  //         blobData: new Blob([res], { type: 'application/zip' }),
  //         // headers: res.headers
  //       };
  //     });
  // }

  // downloadAnswerFile(clientId: string, sourceId: string, filetype: string) {
  //   const url =
  //     REPORT_API_CONSTANT.ANSWERS_FILE_DOWNLOAD +
  //     '?clientId=' +
  //     clientId +
  //     '&sourceId=' +
  //     sourceId;
  //   return this.http
  //     .getFile(url, { responseType: ResponseContentType.Blob })
  //     .map(res => {
  //       // return new Blob([res.blob()], { type: filetype });
  //       return new Blob([res], { type: filetype });
  //     });
  // }
}
