export class AC {
  public static PROD_MODE = false;
  public static KYC_PROFILE = 'profile';
  public static SANCTIONS = 'nameScreening';
  public static TRANSACTIONS = 'transactions';
  public static NNEWS = 'nn';

  public static KYC_PROFILE_URL_PATTERN = 'tab/profile';
  public static NN_URL_PATTERN = 'tab/nn';
  public static SANCTIONS_URL_PATTERN = 'tab/nameScreening';
  public static TRANSACTIONS_URL_PATTERN = 'tab/transactions';
  public static HEADER_VALUES = {
    profile: 'PROFILE MONITORING',
    nn: 'NEGATIVE NEWS',
    nameScreening: 'NAME SCREENING',
    transactions: 'TRANSACTIONS',
  };

  public static URL_MAPPING = {
    KYC_PROFILE_URL_PATTERN: AC.KYC_PROFILE,
    NN_URL_PATTERN: AC.NNEWS,
    SANCTIONS_URL_PATTERN: AC.SANCTIONS,
    TRANSACTIONS_URL_PATTERN: AC.TRANSACTIONS,
  };

  public static RDC = {
    ENTITIES: 'entities',
    ENTITIY_EVENTS: 'entityEvents',
    OWNERSHIPS: 'ownerships',
    OWNERSHIP_PARTIES: 'ownershipParties',
    OWNERSHIP_EVENTS: 'ownershipEvents',
    KEYCONTROLLERS: 'keyControllers',
    KEYCONTROLLER_PARTIES: 'keyControllerParties',
    KEYCONTROLLER_EVENTS: 'keyControllerEvents',
  };

  public static NN_BE_KEYS = {
    [AC.RDC.ENTITIES]: 'entity',
    [AC.RDC.ENTITIY_EVENTS]: 'event',
    [AC.RDC.OWNERSHIPS]: 'alert_ownership',
    [AC.RDC.OWNERSHIP_PARTIES]: 'alertMatchParty',
    [AC.RDC.OWNERSHIP_EVENTS]: 'event',
    [AC.RDC.KEYCONTROLLERS]: 'alert_kc',
    [AC.RDC.KEYCONTROLLER_PARTIES]: 'alertMatchParty',
    [AC.RDC.KEYCONTROLLER_EVENTS]: 'event',
  };

  public static DATA_STATE = {
    FRESH_LOAD: 'fresh',
    SAVING_DATA: 'saving',
    DATA_SAVED: 'saved',
    DATA_SUBMITTED: 'submitted',
    DATA_SUBMITTING: 'submitting',
  };

  public static RDC_DISCOUNT_DROPDOWN = [
    { name: 'Irrelevant' },
    { name: 'Entity is not related' },
    { name: 'Not related to any crime' },
    { name: 'Event is not about Entity' },
    { name: 'Other' },
  ];

  public static RDC_ESCALATE_DROPDOWN = [
    { name: 'Critical alert' },
    { name: 'Financial crime and critical' },
    { name: 'Non Financial crime and critical' },
    { name: 'Event is definitely about entity' },
    { name: 'Other' },
  ];

  public static RDC_ESCALATE_TO_LIST = [
    { name: 'Mortgage Team' },
    { name: 'Investigation Team' },
    { name: 'Remediation Team' },
    { name: 'Risk Management Team' },
  ];
}
