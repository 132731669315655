import { ComponentFixture } from '@angular/core/testing';
import { DialogService } from 'primeng/api';
import { ChooseSourceComponent } from './../../../dashboard/choose-source/choose-source.component';
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/primeng';

import { DashboardAPIService } from './../../../dashboard/services/dashboard.api.service';
import { DateTimeFormatPipe } from './../../pipes/date-time-format.pipe';
import { UtilService } from 'src/app/common/util.service';
import { DiscardJobService } from 'src/app/common/discard-job.service';
import { MESSAGES } from 'src/app/common/appMessages';
import { ReportsAPIService } from 'src/app/reports/services/report.api.service';

const TEMPLATE_NAME = {
  ACTION_TEMPLATE: 'ACTION_TEMPLATE',
  CHECKBOX_TEMPLATE: 'CHECKBOX_TEMPLATE',
  JOB_PROGRESS_TEMPLATE: 'JOB_PROGRESS_TEMPLATE',
  GET_DATE_TEMPLATE: 'GET_DATE_TEMPLATE',
  BUTTON_TEMPLATE: 'BUTTON_TEMPLATE',
  DOTS_TEMPLATE: 'DOTS_TEMPLATE',
  EXPAND_TEMPLATE: 'EXPAND_TEMPLATE',
  JP_CLIENT_STATUS_TEMPLATE: 'JP_CLIENT_STATUS_TEMPLATE',
  OVERLAY_PANEL_TEMPLATE: 'OVERLAY_PANEL_TEMPLATE',
  PROFILE_TYPE_TEMPLATE: 'PROFILE_TYPE_TEMPLATE',
  GET_ICON_TEMPLATE: 'GET_ICON_TEMPLATE',
  TOGGLABLE_LINK: 'TOGGLABLE_LINK',
};
declare var _: any;
declare var window: any;

@Component({
  selector: 'kyc-nested-data-table',
  templateUrl: './nested-data-table.component.html',
  styleUrls: ['./nested-data-table.component.scss'],
  providers: [DialogService],
})
export class NestedDataTableComponent implements OnInit {
  sources = [];
  _tabName: any;
  reportDescription: any;
  sourceErrorMessage: any;
  globalDialogRef;

  set tabName(val: any) {
    this._tabName = val;
  }

  @Input()
  get tabName() {
    return this._tabName;
  }

  @ViewChild('defaultTemplate')
  defaultTemplate: TemplateRef<any>;

  @ViewChild('toggelableLink')
  toggelableLink: TemplateRef<any>;

  @ViewChild('getProfileTypeTemplate')
  profileTypeTemplate: TemplateRef<any>;

  @ViewChild('getIconTemplate')
  iconTemplate: TemplateRef<any>;

  @ViewChild('checkboxTemplate')
  checkboxTemplate: TemplateRef<any>;

  @ViewChild('jobProgressTemplate')
  jobProgressTemplate: TemplateRef<any>;

  @ViewChild('getDateTemplate')
  getDateTemplate: TemplateRef<any>;

  @ViewChild('getButtonTemplate')
  getButtonTemplate: TemplateRef<any>;

  @ViewChild('getDotsTemplate')
  getDotsTemplate: TemplateRef<any>;

  @ViewChild('expandTemplate')
  expandTemplate: TemplateRef<any>;

  @ViewChild('jpClientStatusTemplate')
  jpClientStatusTemplate: TemplateRef<any>;

  @ViewChild('overlayTemplate')
  overlayTemplate: TemplateRef<any>;

  @Input()
  scrollable: any;

  @Input()
  scrollableHeight: any;

  _config: any;
  @Input()
  get config(): any {
    return this._config;
  }
  set config(val: any) {
    this._config = val;
    this.processTemplateConfig(this._config);
  }

  _tableData: any;
  @Input()
  get tableData(): any {
    return this._tableData;
  }
  set tableData(val: any) {
    this._tableData = val;
    if (!val) {
      this._tableData = [];
    }
    if (this._tableData) {
      // if (this._tableData.length > 10) {
      //   if (this._config.pagination) {
      //     this.isPaginatorRequired = true;
      //   }
      // }
      this.onTableDataUpdate();
    }
  }
  _selectTotalRecords: boolean;
  @Input()
  get selectTotalRecords(): any {
    return this._selectTotalRecords;
  }
  set selectTotalRecords(val: any) {
    this._selectTotalRecords = val;
    if (val) {
      this.selectedClients = this._tableData;
      this.setCheckboxState(this.selectedClients, true);
      this.tableHeaderChk = true;
      this.emitSelection();
    } else {
      if (this.selectedClients.length === this._tableData.length) {
        this.setCheckboxState(this.selectedClients, false);
        this.selectedClients = [];
        this.emitSelection();
      }
      this.tableHeaderChk = false;
    }
  }

  @Input()
  selectedData: any[];

  @Input()
  headerClass: string;
  @Output() rowSelectUnselect: EventEmitter<any> = new EventEmitter();
  @Output() triggerPullNotification: EventEmitter<any> = new EventEmitter();

  @ViewChild('tableInstance') tableInstance;

  isPaginatorRequired: boolean;
  cities2: any;
  pageSize: number;
  pageSizeModel: any;
  currentRowClicked = -1;
  selectedClients: any;
  tableHeaderChk: boolean;
  pageFirstIndex: number;
  // clientInfo: AddClientReq;
  clientInfo: any;
  // success = 0;
  // broken = 0;
  // conflict = 0;
  // progressing = 0;
  // paused = 0;
  // cancelled = 0;
  multiResultSrcArr = [];
  captchaRequiredArr = [];
  processingSrcArr = [];
  completeSrcArr = [];
  noResultArr = [];
  failureSrcArr = [];
  readySrcArr = [];
  runningSection = [];
  failedSection = [];
  pendingSection = [];
  pauseSection = [];
  numberofCards: any;
  runningSectionCol: any;
  failedSectionCol: any;
  pendingSectionCol: any;
  runningSectionSource: any;
  failedSectionSource: any;
  pendingSectionSource: any;

  constructor(
    private api: DashboardAPIService,
    public dateTimeFormatPipe: DateTimeFormatPipe,
    private location: Location,
    public dialogService: DialogService,
    private utilService: UtilService,
    private discardJobService: DiscardJobService,
    private report: ReportsAPIService
  ) {
    this.isPaginatorRequired = false;
    this._tableData = [];
    this.cities2 = [
      { name: '10', value: 10 },
      { name: '25', value: 25 },
      { name: '50', value: 50 },
      { name: '100', value: 100 },
    ];
    this.tableHeaderChk = false;
    this.pageSize = 10;
    this.pageSizeModel = this.cities2[0];
    this.pageFirstIndex = 0;
    this.selectedClients = [];
  }

  ngOnInit() {
    this.subscribeToDiscardService();
  }

  ngOnDestroy() {
    if (this.globalDialogRef) {
      this.globalDialogRef.close();
    }
  }
  subscribeToDiscardService() {
    if (this.discardJobService.getSelectedJobs().length > 0) {
      this.disableCheckboxes();
    }
    this.discardJobService.clientsSubject.subscribe((data: any) => {
      if (data.tabName == TAB_NAMES.JOBS_IN_PROGRESS && data.selectedJobs && data.selectedJobs.length > 0) {
        this.disableCheckboxes();
      } else {
        this.enableCheckboxes();
      }
    });
  }

  disableCheckboxes() {
    _.each(
      this._tableData,
      function (a, b) {
        // a.isChecked = false;
        a.checkBoxDisabled = true;
      },
      this
    );
  }

  enableCheckboxes() {
    _.each(
      this._tableData,
      function (a, b) {
        // a.isChecked = false;
        a.checkBoxDisabled = false;
      },
      this
    );
  }

  addDisableProperty() {
    _.each(
      this._tableData,
      function (a, b) {
        // a.isChecked = false;
        a.checkBoxDisabled = true;
      },
      this
    );
  }

  onTableDataUpdate() {
    this.pageFirstIndex = 0;
    this.tableHeaderChk = true;
    this.setCheckboxState(this.selectedClients, false);
    this.selectedClients = [];
    this.selectTotalRecords = false;
    this.emitSelection();
    if (this._config && this._config.doSelectAllRows && this._tableData) {
      this.doSelectAllRows();
    }
  }

  resetTable() {
    this.tableInstance.reset();
    this.onTableDataUpdate();
  }

  onPageChange(ev) {
    this.pageFirstIndex = ev.first;
    this.isAllRecordsSelectedOnCurrentPage();
  }

  doSelectAllRows() {
    this.selectedClients = [];
    this.dataAssignPostProcess();
    this.isAllRecordsSelectedOnCurrentPage();
  }

  private dataAssignPostProcess() {
    _.each(
      this._tableData,
      function (a, b) {
        if (a.isChecked) {
          this.selectedClients.push(a);
        }
      },
      this
    );
  }

  private setCheckboxState(data, state) {
    if (Array.isArray(data)) {
      _.each(
        data,
        function (a, b) {
          a.isChecked = state;
        },
        this
      );
    } else {
      data.isChecked = state;
    }
  }

  onHeaderCheckbox(ev) {
    // ev.stopPropagation();

    // ev.stopImmediatePropagation();
    this.unSelectCurrentRows();
    if (ev) {
      const tt = this._tableData.slice(this.pageFirstIndex, this.pageFirstIndex + this.pageSize);
      this.selectedClients = this.selectedClients.concat(tt);
      this.setCheckboxState(this.selectedClients, true);
    }

    this.emitSelection();
  }

  onPageSizeChanged(ev) {
    this.pageSize = ev.value.value;
    // this.tableInstance.reset();
    this.onTableDataUpdate();
  }

  onIndividualRowSelect(ev) {
    this.isAllRecordsSelectedOnCurrentPage();
    this.emitSelection();
  }

  onIndividualRowUnSelect(ev) {
    this.tableHeaderChk = false;
    this.emitSelection();
  }

  onIndividualRowCheckbox(ev, e) {
    if (ev) {
      this.selectedClients.push(e);
      this.onIndividualRowSelect(ev);
    } else {
      this.selectedClients = _.filter(
        this.selectedClients,
        function (a, b) {
          return a[this._config.dataKeyId] !== e[this._config.dataKeyId];
        },
        this
      );
      this.onIndividualRowUnSelect(ev);
    }
    this.notifyParentTable(this.selectedClients);
  }

  notifyParentTable(selectedClients) {
    let data = { tabName: this.tabName, selectedClients: selectedClients };
    this.discardJobService.jobsSubject.next(data);
  }

  isAllRecordsSelectedOnCurrentPage() {
    const tt = this._tableData.slice(this.pageFirstIndex, this.pageFirstIndex + this.pageSize);
    let c = _.pluck(tt, this._config.dataKeyId);
    let cc = _.filter(
      this.selectedClients,
      function (val, index) {
        return _.contains(c, val[this._config.dataKeyId]);
      },
      this
    );
    if (cc && (cc.length === this.pageSize || cc.length === this._tableData.length)) {
      this.tableHeaderChk = true;
    } else {
      this.tableHeaderChk = false;
    }
  }

  rowClicked(ev) {
    if (this._config.rowExpand) {
      _.each(
        this._tableData,
        function (a, b) {
          if (a[this._config.dataKeyId] === ev[this._config.dataKeyId]) {
            if (a.currentRowClicked) {
              a.currentRowClicked = false;
              a.isExpanded = false;
            } else {
              a.currentRowClicked = true;
              a.isExpanded = true;
            }
          } else {
            a.currentRowClicked = false;
            a.isExpanded = false;
          }
        },
        this
      );
    }

    if (this._config.tabName === TAB_NAMES.JOBS_IN_PROGRESS) {
      this.getJobSourceList(ev);
    } else if (this._config.tabName === TAB_NAMES.COMPLETED_JOBS) {
      this.getCompletedJobSourceList(ev);
    }
  }

  getJobSourceList(ev, state?: string) {
    if (state !== 'PULL') {
      // ev.isExpanded = !ev.isExpanded;
      if (!ev.isExpanded) {
        return;
      }
      // if (ev.sources) {
      //   return;
      // }
    }
    this.api.getJobSourceList(ev.jobId).subscribe(
      (response: any) => {
        if (response) {
          if (response.isError) {
          }
        }
        // if (ev.clientStatus === 'PAUSED') {
        //   response.sourceList.forEach((source, index) => {
        //     response.sourceList[index].status = 'PAUSED';
        //   });
        // }
        // ev.sources = response.sourceList;
        const res = this.addPriorirtyToSources(response);
        ev.sources = res.sourceList;
        ev.statusCount = res.statusCount;
        // this.addStatusToSources(ev);
        this.sourcesStatusArray(ev.sources);
      },
      (error) => {}
    );
  }

  private sourcesStatusArray(sources) {
    this.runningSection = [];
    this.failedSection = [];
    this.pendingSection = [];
    this.pauseSection = [];
    this.multiResultSrcArr = [];
    this.captchaRequiredArr = [];
    this.processingSrcArr = [];
    this.completeSrcArr = [];
    this.noResultArr = [];
    this.failureSrcArr = [];
    this.readySrcArr = [];

    sources.forEach((source) => {
      if (source.statusDetails === 'MULTIPLE_RESULTS') {
        this.multiResultSrcArr.push(source);
      }
      if (source.statusDetails === 'CAPTCHA_REQUIRED') {
        this.captchaRequiredArr.push(source);
      }
      if (source.statusDetails === 'PROCESSING' || source.statusDetails === 'RETRY') {
        this.processingSrcArr.push(source);
      }
      if (source.statusDetails === 'COMPLETE') {
        this.completeSrcArr.push(source);
      }
      if (
        source.statusDetails === 'NO_RESULT' ||
        source.statusDetails === 'AUTOSKIPPED-CLIENT_NOT_FOUND' ||
        source.statusDetails === 'AUTOSKIPPED-ERROR_COI_MISMATCH'
      ) {
        this.noResultArr.push(source);
      }
      if (
        source.statusDetails === 'ERROR' ||
        source.statusDetails === 'CANCELLED' ||
        source.statusDetails === 'SKIPPED' ||
        source.statusDetails === 'SKIPPED-CAPTCHA' ||
        source.statusDetails === 'SKIPPED-MULTIPLE_RESULTS' ||
        source.statusDetails === 'SKIPPED-USER_SKIPPED'
      ) {
        this.failureSrcArr.push(source);
      }
      if (source.statusDetails === 'READY' || source.statusDetails === 'WAITING') {
        this.readySrcArr.push(source);
      }
      if (source.statusDetails === 'PAUSED') {
        this.pauseSection.push(source);
      }
    });
    if (this.multiResultSrcArr.length > 0) {
      this.multiResultSrcArr.forEach((source) => {
        this.runningSection.push(source);
      });
    }
    if (this.captchaRequiredArr.length > 0) {
      this.captchaRequiredArr.forEach((source) => {
        this.runningSection.push(source);
      });
    }
    if (this.processingSrcArr.length > 0) {
      this.processingSrcArr.forEach((source) => {
        this.runningSection.push(source);
      });
    }
    if (this.completeSrcArr.length > 0) {
      this.completeSrcArr.forEach((source) => {
        this.runningSection.push(source);
      });
    }
    if (this.noResultArr.length > 0) {
      this.noResultArr.forEach((source) => {
        this.failedSection.push(source);
      });
    }
    if (this.failureSrcArr.length > 0) {
      this.failureSrcArr.forEach((source) => {
        this.failedSection.push(source);
      });
    }
    if (this.readySrcArr.length > 0) {
      this.readySrcArr.forEach((source) => {
        this.pendingSection.push(source);
      });
    }
    this.dyanamicClass();
  }

  dyanamicClass() {
    let resultCount = 0;
    if (this.runningSection && this.runningSection.length > 0) {
      resultCount += 1;
    }
    if (this.failedSection && this.failedSection.length > 0) {
      resultCount += 1;
    }
    if (this.pendingSection && this.pendingSection.length > 0) {
      resultCount += 1;
    }
    if (this.pauseSection && this.pauseSection.length > 0) {
      resultCount += 1;
    }
    //

    this.numberofCards = resultCount;
    switch (this.numberofCards) {
      case 1:
        this.runningSectionCol = 'p-col-12';
        this.failedSectionCol = 'p-col-12';
        this.pendingSectionCol = 'p-col-12';
        this.runningSectionSource = 'p-col-12';
        this.runningSectionSource = 'p-col-3';
        this.failedSectionSource = 'p-col-3';
        this.pendingSectionSource = 'p-col-3';

        break;
      case 2:
        this.runningSectionCol = 'p-col-6';
        this.failedSectionCol = 'p-col-6';
        this.pendingSectionCol = 'p-col-6';
        this.runningSectionSource = 'p-col-6';
        this.failedSectionSource = 'p-col-6';
        this.pendingSectionSource = 'p-col-6';
        break;
      case 3:
        this.runningSectionCol = 'p-col-6';
        this.failedSectionCol = 'p-col-3';
        this.pendingSectionCol = 'p-col-3';
        this.runningSectionSource = 'p-col-6';
        this.failedSectionSource = 'p-col-12';
        this.pendingSectionSource = 'p-col-12';
        break;
      default:
        this.runningSectionCol = 'p-col-6';
        this.failedSectionCol = 'p-col-3';
        this.pendingSectionCol = 'p-col-3';
        this.runningSectionSource = 'p-col-6';
        this.failedSectionSource = 'p-col-12';
        this.pendingSectionSource = 'p-col-12';
        break;
    }
  }

  public addPriorirtyToSources(ev) {
    ev.statusCount = {
      success: 0,
      broken: 0,
      conflict: 0,
      progressing: 0,
      paused: 0,
      cancelled: 0,
      waiting: 0,
    };
    ev.sourceList.forEach((element, index) => {
      if (element['status'] === 'READY' && element['statusDetails'] === 'MULTIPLE_RESULTS') {
        ev.sourceList[index].priority = 1;
        ev.statusCount.conflict = ev.statusCount.conflict + 1;
      }
      if (element['status'] === 'ERROR') {
        ev.sourceList[index].priority = 2;
        ev.statusCount.broken = ev.statusCount.broken + 1;
      }
      if (element['status'] === 'PAUSED') {
        ev.sourceList[index].priority = 3;
        ev.statusCount.paused = ev.statusCount.paused + 1;
      }
      if (
        (element['status'] === 'READY' && element['statusDetails'] === 'READY') ||
        element['status'] === 'PROCESSING' ||
        element['status'] === 'RETRY'
      ) {
        ev.sourceList[index].priority = 4;
        ev.statusCount.progressing = ev.statusCount.progressing + 1;
      }
      if (element['status'] === 'COMPLETE') {
        ev.sourceList[index].priority = 5;
        ev.statusCount.success = ev.statusCount.success + 1;
      }
      if (
        element['status'] === 'CANCELLED' ||
        element['status'] === 'SKIPPED' ||
        (element['status'] === 'READY' && element['statusDetails'] === 'CAPTCHA_TIMEOUT')
      ) {
        ev.sourceList[index].priority = 6;
        ev.statusCount.cancelled = ev.statusCount.cancelled + 1;
      }
      if (element['status'] === 'WAITING') {
        ev.sourceList[index].priority = 7;
        ev.statusCount.waiting = ev.statusCount.waiting + 1;
      }
    });
    ev.sourceList.sort((data1, data2) => {
      let result = null;

      result = data1.priority < data2.priority ? -1 : data1.priority > data2.priority ? 1 : 0;

      return result;
    });
    return ev;
  }

  addStatusToSources(ev) {
    ev.statusCount = {
      success: 0,
      broken: 0,
      conflict: 0,
      progressing: 0,
      paused: 0,
      cancelled: 0,
      waiting: 0,
    };
    ev.sources.forEach((src) => {
      if (src.status === 'COMPLETE') {
        ev.statusCount.success = ev.statusCount.success + 1;
      } else if (
        (src.status === 'READY' && src.statusDetails === 'READY') ||
        src.status === 'PROCESSING' ||
        src.status === 'RETRY'
      ) {
        ev.statusCount.progressing = ev.statusCount.progressing + 1;
      } else if (src.status === 'ERROR') {
        ev.statusCount.broken = ev.statusCount.broken + 1;
      } else if (src.status === 'READY' && src.statusDetails === 'MULTIPLE_RESULTS') {
        ev.statusCount.conflict = ev.statusCount.conflict + 1;
      } else if (src.status === 'PAUSED') {
        ev.statusCount.paused = ev.statusCount.paused + 1;
      } else if (
        src.status === 'CANCELLED' ||
        src.status === 'SKIPPED' ||
        (src.status === 'READY' && src.statusDetails === 'CAPTCHA_TIMEOUT')
      ) {
        ev.statusCount.cancelled = ev.statusCount.cancelled + 1;
      } else if (src.status === 'WAITING') {
        ev.statusCount.waiting = ev.statusCount.waiting + 1;
      }
    });
  }

  // getCompletedJobSourceList(ev) {
  //   this.api.getCompletedJobSourceList().subscribe(
  //     (response: any) => {
  //       if (response) {
  //         if (response.isError) {

  //         }
  //       }
  //       console.log(response.sourceList);
  //       ev.sources = response.sourceList;
  //       this.sources = response.sourceList;
  //     },
  //     error => {}
  //   );
  // }

  getCompletedJobSourceList(ev) {
    this.getJobSourceList(ev);
  }

  unSelectCurrentRows() {
    const tt = this._tableData.slice(this.pageFirstIndex, this.pageFirstIndex + this.pageSize);
    let c = _.pluck(tt, this._config.dataKeyId);
    this.selectedClients = _.filter(
      this.selectedClients,
      function (val, index) {
        if (!_.contains(c, val[this._config.dataKeyId])) {
          return true;
        } else {
          this.setCheckboxState(this.selectedClients[index], false);
          return false;
        }
      },
      this
    );
  }

  public emitSelection() {
    const exp = {
      pageSize: this.pageSize,
      selectedCount: this.selectedClients.length,
      selection: this.selectedClients,
    };
    this.rowSelectUnselect.emit(exp);
  }

  private processTemplateConfig(config: any): void {
    if (!config) {
      return;
    }
    config.columns.forEach((column) => {
      column.template = this.getTemplate(column.templateConfig);
    });
  }

  private getTemplate(templateConfig: any): TemplateRef<any> {
    let template: TemplateRef<any>;
    if (!templateConfig) {
      return this.defaultTemplate;
    }

    switch (templateConfig.name) {
      case TEMPLATE_NAME.CHECKBOX_TEMPLATE: {
        template = this.checkboxTemplate;
        break;
      }
      case TEMPLATE_NAME.TOGGLABLE_LINK: {
        template = this.toggelableLink;
        break;
      }
      case TEMPLATE_NAME.GET_ICON_TEMPLATE: {
        template = this.iconTemplate;
        break;
      }
      case TEMPLATE_NAME.PROFILE_TYPE_TEMPLATE: {
        template = this.profileTypeTemplate;
        break;
      }
      case TEMPLATE_NAME.JOB_PROGRESS_TEMPLATE: {
        template = this.jobProgressTemplate;
        break;
      }
      case TEMPLATE_NAME.GET_DATE_TEMPLATE: {
        template = this.getDateTemplate;
        break;
      }
      case TEMPLATE_NAME.BUTTON_TEMPLATE: {
        template = this.getButtonTemplate;
        break;
      }
      case TEMPLATE_NAME.DOTS_TEMPLATE: {
        template = this.getDotsTemplate;
        break;
      }
      case TEMPLATE_NAME.EXPAND_TEMPLATE: {
        template = this.expandTemplate;
        break;
      }
      case TEMPLATE_NAME.JP_CLIENT_STATUS_TEMPLATE: {
        template = this.jpClientStatusTemplate;
        break;
      }
      case TEMPLATE_NAME.OVERLAY_PANEL_TEMPLATE: {
        template = this.overlayTemplate;
        break;
      }

      default:
        template = this.defaultTemplate;
    }
    return template;
  }

  showClientInfo(event, overlaypanel: OverlayPanel, client) {
    this.clientInfo = client;
    event.stopPropagation();
    overlaypanel.toggle(event);
  }

  // toggelableLinkClicked(e, data) {
  //   let url = this.utilService.getApplicationBaseHref() + '#/miniProfile/' + data['clientId'];
  //   window.open(url, '_blank');
  //   e.stopPropagation();
  // }

  toggelableLinkClicked(e, data) {
    this.checkForMiniAndFull(e, data['clientId']);
  }

  checkForMiniAndFull(e, clientId) {
    this.report.getClientInfo(clientId).subscribe((response: any) => {
      console.log(response);
      if (response) {
        let url = '';
        if (response.isMiniProfile == 1) {
          url = this.utilService.getApplicationBaseHref() + '#/miniProfile/' + clientId;
        } else {
          url = this.utilService.getApplicationBaseHref() + '#/clientProfile/' + clientId;
        }
        window.open(url, '_blank');
        e.stopPropagation();
      }
    });
  }

  clientChecked(event) {
    event.stopPropagation();
  }

  openFullProfile(e, row) {
    const url = this.utilService.getApplicationBaseHref() + '#/clientProfile/' + row['clientId'];
    window.open(url, '_blank');
    e.stopPropagation();
  }

  openMiniProfile(e, row) {
    const url = this.utilService.getApplicationBaseHref() + '#/miniProfile/' + row['clientId'];
    window.open(url, '_blank');
    e.stopPropagation();
  }

  onAccordianHeaderClick(client) {
    this.api.getJobSourceList(client.jobId).subscribe(
      (response: any) => {
        if (response) {
          if (response.isError) {
          }
        }
        client.sources = response.sourceList;
      },
      (error) => {}
    );
  }

  onClickedOutside(e: Event, overlaypanel) {
    overlaypanel.hide();
  }

  onHeaderClick(e: Event) {
    this.onTableDataUpdate();
  }

  routeToReport(e: Event, row: any) {
    // const url = '/kycora/#/report/' + row['clientId'];
    const url = this.utilService.getApplicationBaseHref() + '#/report/' + row['clientVersionId'];

    window.open(url, '_blank');
    e.stopPropagation();
  }

  showDetailDescription(event, overlaypanel: OverlayPanel, report, field) {
    if (field) {
      const fieldDesc = field + 'Desc';
      this.reportDescription = {
        title: report[field],
        description: report[fieldDesc],
      };
    } else {
      this.reportDescription = {
        title: '',
        description: report,
      };
    }
    event.stopPropagation();
    // console.log(div.offsetWidth +'hovered'+div.scrollWidth);
    //if(div && div.offsetWidth < div.scrollWidth){
    overlaypanel.toggle(event);
    // }
  }

  showDetailDescriptionForSub(event, overlaypanel: OverlayPanel, report, field, div) {
    if (field) {
      let coreReplacedFromField = field.replace('Core', '');
      const fieldDesc = coreReplacedFromField + 'Desc';
      this.reportDescription = {
        title: report[field],
        description: report[fieldDesc],
      };
    } else {
      this.reportDescription = {
        title: '',
        description: report,
      };
    }
    event.stopPropagation();

    overlaypanel.toggle(event);
  }

  public retrySources(event, row) {
    event.stopPropagation();
    this.api.retrySource(row.jobId).subscribe(
      (response: any) => {
        if (response) {
          this.triggerPullNotification.emit(row);
          row.failedSourceCount = 0;
        }
      },
      (error) => {}
    );
  }

  public chooseSource(event, clientInfo) {
    event.stopPropagation();
    this.globalDialogRef = this.dialogService.open(ChooseSourceComponent, {
      showHeader: false,
      width: '80%',
      closeOnEscape: true,
      dismissableMask: true,
      data: {
        clientInfo: clientInfo,
      },
    });
    this.globalDialogRef.onClose.subscribe((res) => {
      clientInfo.statusDetails = '';
      clientInfo.clientStatus = 'READY';

      // this._tableData.forEach(client => {
      //   if (client.clientName === res.clientInfo.clientName) {
      //     client.clientStatus = 'PROCESSING';
      //     client.sources.forEach(src => {
      //       res.selectedSourceInfo.skipedSources.forEach(source => {
      //         if (src.soureName === source) {
      //           src.status = 'BROKEN';
      //         }
      //       });

      //       res.selectedSourceInfo.sources.forEach(source => {
      //         if (src.soureName === source.sourceName) {
      //           src.status = 'PROCESSING';
      //         }
      //       });
      //     });
      //   }
      // });
      this.triggerPullNotification.emit(clientInfo);
    });
  }

  skipSource(source, row) {
    source.multipleResults = false;
    this.api.skipSource(source).subscribe(
      (response: any) => {
        if (response) {
          if (!response.isError) {
            source.status = response.serviceTaskStatus;
            this.triggerPullNotification.emit(row);
          }
          // this.getJobSourceList(source,'PULL');
        }
      },
      (error) => {}
    );
  }

  showSourceStatusMessage(event, overlaypanel: OverlayPanel, source) {
    const childTab = this.config.tabName;
    if (source.errorMessage && source.errorMessage !== '') {
      if (source.errorMessage === 'ERROR_COI_MISMATCH' || source.errorMessage === 'AUTOSKIPPED-ERROR_COI_MISMATCH') {
        this.sourceErrorMessage = MESSAGES.SOURCE_COI_MISMATCH;
      } else if (source.errorMessage === 'ERROR_UNABLE_TO_DISPLAY_RESULTS') {
        if (childTab === 'JOBS_IN_PROGRESS') {
          this.sourceErrorMessage = MESSAGES.UNABLE_TO_DISPLAY_RETRY_SKIP;
        } else if (childTab === 'COMPLETED_JOBS') {
          this.sourceErrorMessage = MESSAGES.UNABLE_TO_DISPLAY;
        }
      } else if (source.errorMessage === 'ERROR_SOURCE_UN_IMPLEMENTED') {
        if (childTab === 'JOBS_IN_PROGRESS') {
          this.sourceErrorMessage = MESSAGES.SOURCE_IS_UNAVAILABLE_RETRY_SKIP;
        } else if (childTab === 'COMPLETED_JOBS') {
          this.sourceErrorMessage = MESSAGES.SOURCE_IS_UNAVAILALE;
        }
      } else if (source.errorMessage === 'AUTOSKIPPED-CLIENT_NOT_FOUND' || source.errorMessage === 'SKIPPED-USER_SKIPPED') {
        this.sourceErrorMessage = MESSAGES.NO_RESULTS_FOUND_FOR_CLIENT;
      } else if (source.errorMessage === 'SKIPPED-MULTIPLE_RESULTS') {
        this.sourceErrorMessage = MESSAGES.NO_CLIENT_SELECTED_FROM_MULTIPLE_RESULT;
      } else if (source.errorMessage === 'SKIPPED-CAPTCHA') {
        this.sourceErrorMessage = MESSAGES.CAPTCHA_SKIPPED;
      } else if (source.errorMessage === 'SOURCE_LICENSE_EXPIRED_AUTOSKIPPED') {
        this.sourceErrorMessage = MESSAGES.SOURCE_LICENSE_EXPIRED_AUTOSKIPPED;
      } else {
        this.sourceErrorMessage = MESSAGES.NO_RESULTS_FOUND_FOR_CLIENT;
      }
      event.stopPropagation();
      overlaypanel.toggle(event);
    }
  }

  dummyClick(e) {
    e.stopPropagation();
  }
  tooltipValue: string;
  displayTooltip(e, value, tooltip) {
    //if (e.offsetWidth < e.scrollWidth && tooltip === 'true') {
    if (e.offsetWidth < e.scrollWidth) {
      this.tooltipValue = value;
    } else {
      this.tooltipValue = '';
    }
  }
}

const TAB_NAMES = {
  JOBS_IN_PROGRESS: 'JOBS_IN_PROGRESS',
  COMPLETED_JOBS: 'COMPLETED_JOBS',
};
