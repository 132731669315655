import { HTTPAPIService } from './../../common/http.api.service';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SessionService } from '../../auth/session.service';
import { SearchSessionService } from '../../common/search.session.service';
import { SearchContainerComponent } from '../../search/search-container/search-container.component';
import { ReportsAPIService } from 'src/app/reports/services/report.api.service';
import { saveAs } from 'file-saver';
import { DashboardAPIService } from 'src/app/dashboard/services/dashboard.api.service';
import { OverlayPanel } from 'primeng/primeng';
import { REPORT_API_CONSTANT } from 'src/app/reports/constants/report.api.constant';
import * as jstz from 'jstz';
import {
  LicenseInfoService,
  IS_EXPIRED,
  LICENSE_VALID_UPTO,
  REMAINING_CLIENTS,
  REMAINING_DAYS,
} from 'src/app/auth/license-info.service';
import { UtilService } from 'src/app/common/util.service';
import { RootModuleConfiguraion } from 'src/app/root-module-config';
import { DashboardContainerComponent } from 'src/app/dashboard/dashboard-container/dashboard-container.component';

declare var $: any;
@Component({
  selector: 'kyc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
  
})
export class HeaderComponent implements OnInit {
  isReportPage: boolean = false;
  rootConfig;
  searchStr: any;
  isSearchEnable: boolean;
  userFullName: any;
  userEmail: any;
  dateString: any;
  currentDate = new Date();
  clientId: string;
  clientName: string;
  reportGeneratedDate: string;
  overlayActive: boolean = false;

  @ViewChild('searchInput') searchInput: ElementRef;
  constructor(
    private router: Router,
    private activateRoute : ActivatedRoute,
    private session: SessionService,
    private searchSessionService: SearchSessionService,
    private reportApi: ReportsAPIService,
    private licenseService: LicenseInfoService,
    private sessionService: SessionService,
    private dashBoardService: DashboardAPIService,
    private http: HTTPAPIService,
    private util: UtilService,
    private root: RootModuleConfiguraion
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.indexOf('report') !== -1) {
          this.isReportPage = true;
          this.clientId = this.getClientId();
          this.getReportGeneratedDate(this.clientId);
        } else {
          this.isReportPage = false;
        }
      }
    });
  }

  getClientInfo(clientid) {
    this.reportApi.getClientInfoByVersionId(clientid).subscribe(
      (response: any) => {
        if (response && response.clientName) {
          this.clientName = response.clientName.trim();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getClientId() {
    const t = this.router.url.substring(this.router.url.lastIndexOf('/'));

    let clientId = t.substr(1, t.length);
    this.getClientInfo(clientId);
    return clientId;
  }

  getReportGeneratedDate(clientId) {
    this.reportApi.getReportGeneratedDate(clientId).subscribe(
      (response: any) => {
        if (response && response.completedOn) {
          console.log(response);
          this.reportGeneratedDate = response.completedOn.trim();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  overlayClosed() {
    this.overlayActive = false;
  }

  isLicensed;
  overlayOpened() {
    this.overlayActive = true;

    if (this.isLicensed == 'true') {
      this.fetchLicensingInfo();
    }
  }

  licenseInfo: any;
  readonly IS_EXPIRED = IS_EXPIRED;
  readonly LICENSE_VALID_UPTO = LICENSE_VALID_UPTO;
  readonly REMAINING_CLIENTS = REMAINING_CLIENTS;
  readonly REMAINING_DAYS = REMAINING_DAYS;
  fetchLicensingInfo() {
    this.licenseInfo = this.licenseService.getLicenseInfoFromServer();
  }

  isSearchEmpty() {
    return $('.search-box').val().trim() == '';
  }

  ngOnInit() {
    // $('.icon-search').click(function() {
    //   if($('.search-input').val().trim() == ''){
    //     console.log('here');
    //     $('.search-box').toggleClass('expanded');
    //   }
    // });
    this.rootConfig = this.root.getConfiguration();
    let that = this;
    $('input[type=search]').on('search', function () {
      that.clearSearch();
    });
    this.getUserData();
    this.getDateString();
    this.isSearchEnable = false;

    this.clearSearch();
  }

  getUserData() {
    let userData = this.session.get(this.session.sessionKeys.USER_INFO);
    this.isLicensed = this.session.get(this.session.sessionKeys.IS_LICENSED);

    if (userData != 'undefined') {
      const userInfo = JSON.parse(userData);
      this.userFullName = userInfo.firstName + ' ' + userInfo.lastName;
      this.userEmail = userInfo.email;
    }
  }

  getDateString() {
    const weekDayArr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const today = new Date();
    const day = weekDayArr[today.getDay()];
    const dd = today.getDate();
    const month = monthArr[today.getMonth()];
    this.dateString = 'Today, ' + day + '. ' + dd + ' ' + month;
  }

  search() {
    const searchInput = {
      searchMethod: 'BASIC',
      searchInputData: { searchStr: this.searchStr },
    };
    this.isSearchEnable = true;
    if (this.searchStr != undefined && this.searchStr.length > 0) {
      //if some search string has been entered
      this.searchSessionService.setSearchInput(searchInput);
      if (this.router.url.indexOf('/search/') != -1) {
        this.searchSessionService.searchResult.next(searchInput);
      } else {
        this.router.navigate(['/search/clients']);
      }
    } else {
      this.searchSessionService.searchResult.next({ nothingEntered: true });
    }
    // else {
    //   if (this.router.url === '/search') {
    //     this.isSearchEnable = true;
    //     const searchInput = {
    //       searchMethod: 'BASIC',
    //       searchInputData: { searchStr: '' }
    //     };
    //     this.searchSessionService.searchResult.next(searchInput);
    //   } else {
    //     this.isSearchEnable = false;
    //     console.log('Enter some search string');
    //   }
    // }
  }

  focusInut() {
    this.searchInput.nativeElement.focus();
  }

  blurSearchElem() {
    $(document).click(function (e) {
      // e.stopPropagation();
      // if (!$(e.target).is('#search-icon-id')) {
      //   this.searchStr = '';
      //   if ($('.search-box').hasClass('expanded')) {
      //     if (!$(e.target).is('#search-input-id') && $('#search-input-id').val().trim() == '') {
      //       $('.search-box').toggleClass('expanded');
      //     }
      //   }
      // }
    });
  }

  makeThisBlank() {
    this.searchStr = '';
  }

  clearSearch() {
    this.searchSessionService.isSearchResultPresent.subscribe(
      () => {
        this.searchStr = null;
      },
      (error) => {
        console.log('Fetch Article API: ', error);
      }
    );
  }

  goToDashboard() {  
    //this.router.navigateByUrl('.', { skipLocationChange: true });
    this.router.navigateByUrl('/dashboard');
    //this.router.navigate(['/dashboard']);
    this.isSearchEnable = false;
    this.searchSessionService.isSearchResultPresent.next();
    this.searchSessionService.isTabChangesAfterSearch.next();
    setTimeout(function(){location.reload();}, 100);
    this.router.navigateByUrl('/dashboard/clients');
  }

  downloadReport() {
    const timezone = jstz.determine();
    //console.log('timetimetmie '+timezone1.name());
    //const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const url = REPORT_API_CONSTANT.REPORT_ZIP_FILE_DOWNLOAD + '/' + this.clientId + '/' + timezone.name();
    this.http.getFile(url).subscribe((resp) => {
      let contentDispo = '';
      const keys = resp.headers.keys();
      contentDispo = resp.headers.get('content-disposition');
      let fileName = this.getFileName(contentDispo);
      saveAs(resp.body, fileName);
    });
  }

  getFileName(contentDispo) {
    let fileName = 'file';
    if (contentDispo && contentDispo.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(contentDispo);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    return fileName;
  }

  //without api logout
  logOut() {
    this.sessionService.flush();
    this.router.navigate(['/login']);
  }

  routeToAdmin(e) {
    const url = this.util.getApplicationBaseHref() + '#/admin';

    window.open(url, '_blank');
    e.stopPropagation();
  }
}
