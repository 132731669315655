import { HTTPAPIService } from 'src/app/common/http.api.service';
import { REPORT_API_CONSTANT } from './../../../reports/constants/report.api.constant';
import { DateTimeFormatPipe } from './../../pipes/date-time-format.pipe';
import { DashboardAPIService } from './../../../dashboard/services/dashboard.api.service';
import { AddClientReq } from './../../../dashboard/models/clients.model';
import { OverlayPanel, SortEvent, DialogService, MessageService } from 'primeng/primeng';
import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  TemplateRef,
} from '@angular/core';
import { CalculateProgressPipe } from '../../pipes/calculate-progress.pipe';
import { DashboardConfigService } from 'src/app/dashboard/services/dashboard.config.service';
import { SearchSessionService } from '../../../common/search.session.service';
import { ReportsAPIService } from 'src/app/reports/services/report.api.service';
import { ReportConfigService } from '../../../reports/services/report.config.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NestedDataTableComponent } from '../nested-data-table/nested-data-table.component';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { DiscardJobService } from 'src/app/common/discard-job.service';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import * as jstz from 'jstz';
import { MESSAGES } from 'src/app/common/appMessages';
import { DASHBOARD_API_CONSTANT } from 'src/app/dashboard/constants/dashboard.api.constant';
import { UtilService } from 'src/app/common/util.service';
import { DataProviderService } from 'src/app/dashboard/alerts/services/data-provider.service';
import { GlobalSubscriptionService } from 'src/app/common/global.subscription.service';
import { SanctionDataService } from 'src/app/dashboard/alerts/services/sanctions-data.service';

declare var _: any;

const TEMPLATE_NAME = {
  ACTION_TEMPLATE: 'ACTION_TEMPLATE',
  CHECKBOX_TEMPLATE: 'CHECKBOX_TEMPLATE',
  JOB_PROGRESS_TEMPLATE: 'JOB_PROGRESS_TEMPLATE',
  JOB_ACTION_TEMPLATE: 'JOB_ACTION_TEMPLATE',
  GET_DATE_TEMPLATE: 'GET_DATE_TEMPLATE',
  GET_DATE_ONLY_TEMPLATE: 'GET_DATE_ONLY_TEMPLATE',
  EXPAND_TEMPLATE: 'EXPAND_TEMPLATE',
  ALL_ANS_ICONS_TEMPLATE: 'ALL_ANS_ICONS_TEMPLATE',
  FLAG_TEMPLATE: 'FLAG_TEMPLATE',
  RADIO_BUTTON_TEMPLATE: 'RADIO_BUTTON_TEMPLATE',
  GET_ARTICLE_COUNT_TEMPLATE: 'NAME_COUNT_TEMPLATE',
  PDF_ICON_TEMPLATE: 'PDF_ICON_TEMPLATE',
  ROW_FLAG_TEMPLATE: 'ROW_FLAG_TEMPLATE',
  ALERT_COUNT_TEMPLATE: 'ALERT_COUNT_TEMPLATE',
  DOWNLOAD_BUTTON_TEMPLATE: 'DOWNLOAD_BUTTON_TEMPLATE',
  MULTILINE_TEMPLATE: 'MULTILINE_TEMPLATE',
  COLORED_TEXT_TEMPLATE: 'COLORED_TEXT_TEMPLATE',
  COLORED_LINK_TEXT: 'COLORED_LINK_TEXT',
  ACTIVE_INACTIVE: 'ACTIVE_INACTIVE_TEMPLATE',
  DOTS_TEMPLATE: 'DOTS_TEMPLATE',
  TWO_LINER_TEMPLATE: 'TWO_LINER_TEMPLATE',
  TOGGLABLE_LINK: 'TOGGLABLE_LINK',
  ALERT_TYPE_CARDS: 'ALERT_TYPE_CARDS',
  ITEM_STATUS_TEMPLATE: 'ITEM_STATUS_TEMPLATE',
  TEST_TEMPLATE: 'TEST_TEMPLATE',
  SANCTIONS_TEMPLATE :  'SANCTIONS_TEMPLATE'
};

const TAB_NAME = {
  JOBS_IN_PROGRESS: 'JOBS_IN_PROGRESS',
  COMPLETED_JOBS: 'COMPLETED_JOBS',
  SHARE_HOLDERS: 'SHARE_HOLDERS',
  KEY_CONTROLLERS: 'KEY_CONTROLLERS',
  OWNERSHIP: 'OWNERSHIP',
  SUBSIDIARIES: 'SUBSIDIARIES',
  BRANCHES: 'BRANCHES',
  CLIENTS: 'CLIENTS',
  CLIENT_DUPLICATES: 'CLIENT_DUPLICATES',
};

@Component({
  selector: 'kyc-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  providers: [DateTimeFormatPipe, CalculateProgressPipe, DialogService, MessageService, SanctionDataService],
  encapsulation: ViewEncapsulation.None,
})
export class DataTableComponent implements OnInit {
  alertTypeTitles: any;
  
  @ViewChild('defaultTemplate')
  defaultTemplate: TemplateRef<any>;

  @ViewChild('sanctionsTemplate')
  sanctionsTemplate: TemplateRef<any>;

  @ViewChild('testTemplate')
  testTemplate: TemplateRef<any>;

  @ViewChild('alertTypeCards')
  alertTypeCards: TemplateRef<any>;

  @ViewChild('itemStatusTemplate')
  itemStatusTemplate: TemplateRef<any>;

  @ViewChild('toggelableLink')
  toggelableLink: TemplateRef<any>;

  @ViewChild('twoLinerTemplate')
  twoLinerTemplate: TemplateRef<any>;

  @ViewChild('checkboxTemplate')
  checkboxTemplate: TemplateRef<any>;

  @ViewChild('jobProgressTemplate')
  jobProgressTemplate: TemplateRef<any>;

  @ViewChild('jobActionTemplate')
  jobActionTemplate: TemplateRef<any>;

  @ViewChild('getDateTemplate')
  getDateTemplate: TemplateRef<any>;

  @ViewChild('getDateOnlyTemplate')
  getDateOnlyTemplate: TemplateRef<any>;

  @ViewChild('expandTemplate')
  expandTemplate: TemplateRef<any>;

  @ViewChild('actionTemplate')
  actionTemplate: TemplateRef<any>;

  @ViewChild('allAnsIconsTemplate')
  allAnsIconsTemplate: TemplateRef<any>;

  @ViewChild('flagTemplate')
  flagTemplate: TemplateRef<any>;

  @ViewChild('radioButtonTemplate')
  radioButtonTemplate: TemplateRef<any>;

  @ViewChild('getArticleCount')
  getArtcileCountTemplate: TemplateRef<any>;

  @ViewChild('pdfIconTemplate')
  pdfIconTemplate: TemplateRef<any>;

  @ViewChild('alertCountTemplate')
  alertCountTemplate: TemplateRef<any>;

  @ViewChild('rowFlagTemplate')
  rowFlagTemplate: TemplateRef<any>;

  @ViewChild('getButtonTemplate')
  downloadButtonTemplate: TemplateRef<any>;

  @ViewChild('multiLineTemplate')
  multiLineTemplate: TemplateRef<any>;

  @ViewChild('colorTextTemplate')
  coloredTextTemplate: TemplateRef<any>;

  @ViewChild('coloredLinkText')
  coloredLinkText: TemplateRef<any>;

  @ViewChild('activeInactive')
  activeInactive: TemplateRef<any>;

  @ViewChild('getDotsTemplate')
  getDotsTemplate: TemplateRef<any>;

  @Input()
  scrollable: any;

  @Input()
  scrollableHeight: any;

  _config: any;
  selectedPageSize;
  @Input()
  get config(): any {
    return this._config;
  }
  set config(val: any) {
    this._config = val;
    this.processTemplateConfig(this._config);
  }

  _tableData: any;
  @Input()
  get tableData(): any {
    return this._tableData;
  }
  set tableData(val: any) {
    //for build purpose as of now commented this code - search regarding
    // let data = [];
    // const getSelectedResult = this.searchSessionService.get();
    // if (getSelectedResult) {
    //   data = getSelectedResult.data;
    //   const isRecordSelected = this._tableData.filter(obj => {
    //     if (obj.id === data['id']) {
    //       obj.isSearchRecordFlag = true;
    //     }
    //     return obj;
    //   });
    // }
    this._tableData = val;
    if (!val) {
      this._tableData = [];
    }
    if (this._tableData) {
      if (this._tableData.length > 10) {
        if (this._config && this._config.pagination) {
          this.isPaginatorRequired = true;
          if (this._config.pageSize) {
            this.pageSize = this._config.pageSize;
            this.selectedPageSize = { name: this.pageSize.toString(), value: this.pageSize };
          } else {
            this.pageSize = 10;
          }
        } else {
          this.isPaginatorRequired = false;
          this.pageSize = this._tableData.length;
        }
      } else {
        this.isPaginatorRequired = false;
        this.pageSize = this._tableData.length;
      }
      this.onTableDataUpdate();
    }
  }
  _selectTotalRecords: boolean;
  @Input()
  get selectTotalRecords(): any {
    return this._selectTotalRecords;
  }
  set selectTotalRecords(val: any) {
    this._selectTotalRecords = val;
    if (val) {
      this.selectedClients = this._tableData;
      this.setCheckboxState(this.selectedClients, true);
      this.tableHeaderChk = true;
      this.emitSelection();
    } else {
      if (this.selectedClients.length === this._tableData.length) {
        this.setCheckboxState(this.selectedClients, false);
        this.selectedClients = [];
        this.emitSelection();
      }
      this.tableHeaderChk = false;
    }
  }

  @Input()
  selectedData: any[];

  @Input()
  headerClass: string;

  @Input()
  perRowClass: string;
  @Output() rowSelectUnselect: EventEmitter<any> = new EventEmitter();
  @Output() rowSelectUnselectOfJobClients: EventEmitter<any> = new EventEmitter();
  @Output() editJobScheduled: EventEmitter<any> = new EventEmitter();
  @Output() pauseJobEvent: EventEmitter<any> = new EventEmitter();
  @Output() showCaptchaEvent: EventEmitter<any> = new EventEmitter();
  @Output() resumeJobEvent: EventEmitter<any> = new EventEmitter();
  @Output() sourceSelectedEvent: EventEmitter<any> = new EventEmitter();
  @Output() rowExpandedEvent: EventEmitter<any> = new EventEmitter();
  @Output() skipCaptchaSourceEvent: EventEmitter<any> = new EventEmitter();
  @Output() triggerPullNotification: EventEmitter<any> = new EventEmitter();

  @ViewChild('tableInstance') tableInstance;
  @ViewChild(NestedDataTableComponent)
  nestedDataTableComponent: NestedDataTableComponent;

  _tabName: any = '';

  @Input()
  get tabName(): any {
    return this._tabName;
  }

  searchItemFoundAt: number;
  isLoadedBySearch: boolean;
  getSelectedResult: object;
  first: number;
  isPaginatorRequired: boolean;
  cities2: any;
  pageSize: number;
  pageSizeModel: any;
  currentRowClicked = -1;
  selectedClients: any;
  tableHeaderChk: boolean;
  pageFirstIndex: number;
  // clientInfo: AddClientReq;
  clientInfo: any;
  selectedClientForJob: any[] = [];
  jobsInProgressTab = false;
  completedJobsTab = false;
  isOnHover: any[] = [];
  isColumnSorted: any[] = [];
  expandedJobData: any;
  headerCheckBoxDisabled: boolean;
  tooltipData: string;
  //public isExpanded:boolean = false;
  public expandedRows = {};

  constructor(
    private messageService: MessageService,
    private api: DashboardAPIService,
    public dateTimeFormatPipe: DateTimeFormatPipe,
    private calculateProgressPipe: CalculateProgressPipe,
    private dashboardconfig: DashboardConfigService,
    private searchSessionService: SearchSessionService,
    private reportApiService: ReportsAPIService,
    private router: Router,
    private discardJobService: DiscardJobService,
    public dialogService: DialogService,
    private http: HTTPAPIService,
    private activatedRoute: ActivatedRoute,
    private util: UtilService,
    private nnService: DataProviderService,
    private globalSub: GlobalSubscriptionService,
    private sanctionDataService : SanctionDataService
  ) {
    this.alertTypeTitles = this.util.getAlertTypeTitles();
    this.isPaginatorRequired = false;
    this._tableData = [];
    this.cities2 = [
      { name: '10', value: 10 },
      { name: '25', value: 25 },
      { name: '50', value: 50 },
      { name: '100', value: 100 },
    ];
    this.tableHeaderChk = false;

    this.pageSizeModel = this.cities2[0];
    this.pageFirstIndex = 0;
    this.selectedClients = [];
    this.isLoadedBySearch = false;
    this.searchItemFoundAt = 0;
    this.first = 0;
  }
  trackByIndex(index) {
    return index;
  }

  ngOnInit() {
    this.clearSearchOnTabChange();
    this.subscribeToDiscardService();
    setTimeout(() => {
      this.onSortChange(), 5000;
    }); //a change to trigger selection of row on search in job in progress after search
  }

  globalPopupRef;
  ngOnDestroy() {
    if (this.globalPopupRef) {
      this.globalPopupRef.close();
    }
  }

  subscribeToDiscardService() {
    this.discardJobService.jobsSubject.subscribe((data: any) => {
      this.discardJobService.cacheSelectedClients({
        tabName: this.tabName,
        selectedClients: data.selectedClients,
      });

      if (data.tabName == TAB_NAME.JOBS_IN_PROGRESS && data.selectedClients && data.selectedClients.length > 0) {
        this.disableCheckboxes();
        this.disableExpansionOfOtherJobs();
      } else {
        this.enableCheckboxes();
        this.enableExpansionOfOtherJobs();
      }
    });

    this.discardJobService.discardCompleted.subscribe((res) => {
      if (res) {
        setTimeout(() => {
          this.enableCheckboxes();
          this.enableExpansionOfOtherJobs();
          if (this.expandedJobData) {
            this.getJobClientList(this.expandedJobData);
          }
        }, 2000);
      }
    });
  }

  enableExpansionOfOtherJobs() {
    _.each(
      this._tableData,
      function (a, b) {
        a.disableToggle = false;
      },
      this
    );
  }

  disableExpansionOfOtherJobs() {
    _.each(
      this._tableData,
      function (a, b) {
        a.disableToggle = true;
      },
      this
    );
  }

  disableCheckboxes() {
    this.headerCheckBoxDisabled = true;
    _.each(
      this._tableData,
      function (a, b) {
        // a.isChecked = false;
        a.checkBoxDisabled = true;
      },
      this
    );
  }

  enableCheckboxes() {
    this.headerCheckBoxDisabled = false;
    _.each(
      this._tableData,
      function (a, b) {
        // a.isChecked = false;
        a.checkBoxDisabled = false;
      },
      this
    );
  }

  searchProcess() {
    let data = [];
    this.getSelectedResult = this.searchSessionService.get();
    if (this.getSelectedResult) {
      data = this.getSelectedResult['data'];

      const isRecordSelected = this._tableData.filter((obj, index) => {
        if (obj[this._config.dataKeyId] === data['id']) {
          this.isLoadedBySearch = true;
          this.searchItemFoundAt = index;
          this.first = index - (index % this.pageSize);
          // obj.currentRowClicked = false;
          obj.isSearchRecordFlag = true;
        }
        return obj;
      }, this);
    } else {
      this.isLoadedBySearch = false;
    }
  }

  onTableDataUpdate() {
    this.pageFirstIndex = 0;
    this.tableHeaderChk = true;
    this.setCheckboxState(this.selectedClients, false);
    this.selectedClients = [];
    this.selectTotalRecords = false;
    this.emitSelection();
    if (this._config && this._config.doSelectAllRows && this._tableData) {
      this.doSelectAllRows();
    }
    if (this.tableInstance) {
      this.tableInstance.first = this.first;
    }
  }

  resetTable() {
    this.tableInstance.reset();
    this.onTableDataUpdate();
  }

  onPageChange(ev) {
    this.pageFirstIndex = ev.first;
    this.clearSearchSessionCB();
    this.isAllRecordsSelectedOnCurrentPage();
  }

  onSortChange() {
    this.searchProcess();
    if (this.tableInstance) {
      if (this.isLoadedBySearch) {
        this.isLoadedBySearch = false;
        this.tableInstance.first = this.first;
        // setTimeout(() => {

        //   // if (this.getSelectedResult['type'] === 'JIP') {
        //   //   this._tableData[this.searchItemFoundAt].currentRowClicked = false;
        //   //   this.rowClicked(this._tableData[this.searchItemFoundAt]);
        //   // }

        // });
      }
    }
  }

  doSelectAllRows() {
    this.selectedClients = [];
    this.dataAssignPostProcess();
    this.isAllRecordsSelectedOnCurrentPage();
  }

  private dataAssignPostProcess() {
    _.each(
      this._tableData,
      function (a, b) {
        //if (a.isChecked) {
        a.isChecked = true;
        this.selectedClients.push(a);
        //}
      },
      this
    );
  }

  private setCheckboxState(data, state) {
    if (Array.isArray(data)) {
      _.each(
        data,
        function (a, b) {
          a.isChecked = state;
        },
        this
      );
    } else {
      data.isChecked = state;
    }
  }

  onHeaderCheckbox(ev) {
    //ev.stopPropagation();

    // ev.stopImmediatePropagation();

    this.unSelectCurrentRows();
    if (ev) {
      const tt = this._tableData.slice(this.pageFirstIndex, this.pageFirstIndex + this.pageSize);
      this.selectedClients = this.selectedClients.concat(tt);
      this.setCheckboxState(this.selectedClients, true);
    }
    if (this.tabName == TAB_NAME.JOBS_IN_PROGRESS) {
      this.discardEventHandling();
    }
    this.emitSelection2();
  }

  discardEventHandling() {
    var data = {
      tabName: 'JOBS_IN_PROGRESS',
      selectedJobs: this.selectedClients,
    };
    this.discardJobService.cacheSelectedJobs(data);
    this.discardJobService.clientsSubject.next(data);
  }

  onPageSizeChanged(ev) {
    this.pageSize = ev.value.value;
    this.clearSearchSessionCB();
    // this.tableInstance.reset();
    this.onTableDataUpdate();
  }

  onIndividualRowSelect(ev) {
    this.isAllRecordsSelectedOnCurrentPage();
    this.emitSelection();
  }

  onIndividualRowUnSelect(ev) {
    this.tableHeaderChk = false;
    this.emitSelection();
  }

  dummyClick(e) {
    e.stopPropagation();
  }

  onIndividualRowCheckbox(ev, e) {
    if (ev) {
      this.selectedClients.push(e);
      this.onIndividualRowSelect(ev);
    } else {
      this.selectedClients = _.filter(
        this.selectedClients,
        function (a, b) {
          return a[this._config.dataKeyId] !== e[this._config.dataKeyId];
        },
        this
      );
      this.onIndividualRowUnSelect(ev);
    }
    this.notifyNestedTable(this.selectedClients);
  }

  notifyNestedTable(selectedClients) {
    let data = { tabName: this.tabName, selectedJobs: selectedClients };
    this.discardJobService.cacheSelectedJobs(data);
    this.discardJobService.clientsSubject.next(data);
  }

  isAllRecordsSelectedOnCurrentPage() {
    const tt = this._tableData.slice(this.pageFirstIndex, this.pageFirstIndex + this.pageSize);
    let c = _.pluck(tt, this._config.dataKeyId);
    let cc = _.filter(
      this.selectedClients,
      function (val, index) {
        return _.contains(c, val[this._config.dataKeyId]);
      },
      this
    );
    if (cc && (cc.length === this.pageSize || cc.length === this._tableData.length)) {
      this.tableHeaderChk = true;
    } else {
      this.tableHeaderChk = false;
    }
    return this.tableHeaderChk;
  }

  nestedtTableConfig: any;

  resetDataObject() {
    this.expandedRows = {};
  }
  updateNNReadStatus(ev) {
    if (ev.isRead != 1) {
      let payLoad = {
        clientId: ev.clientId,
        isRead: 1,
      };
      this.api.updateNNReadStatus(payLoad).subscribe((res) => {
        console.log('Read status updated');
      }),
        (err) => console.log(err);
    }
  }
  updateReadStatus(ev) {
    if (ev.isRead != 1) {
      let payLoad = {
        clientId: ev.clientId,
        isRead: 1,
      };
      this.api.updateReadStatus(payLoad).subscribe((res) => {
        console.log('Read status updated');
      }),
        (err) => console.log(err);
    }
  }

  updateReadStatusOfJob(runId) {
    this.api.updateReadStatusOfJob(runId).subscribe(
      (reponse) => {
        console.log('Read status updated');
      },
      (err) => console.log(err)
    );
  }

  updateReadStatusOfDuplicates(id) {
    this.api.updateReadStatusOfDuplicates(id).subscribe(
      (reponse) => {
        console.log('Read status updated');
      },
      (err) => console.log(err)
    );
  }

  updateReadStatusOfPendingSanctionClients(id) {
    this.api.updateRedaStatusOfPendingSnactions(id).subscribe(
      (reponse) => {
        console.log('Read status updated');
      },
      (err) => console.log(err)
    );
  }

  //open the detailed nn news events
  @Output() openDetailedNN = new EventEmitter<any>();
  rowClicked(ev, rowIndex) {
    if (this.tabName == 'COMPLETED_JOBS') {
      if (ev.isRead == 0) {
        this._tableData[rowIndex].isRead = 1;
        let newCount = this._tableData.filter((job) => job.isRead == 0).length;
        this.globalSub.completedJobRead.next(newCount);
        this.updateReadStatusOfJob(ev.runId);        
      }
      this.rowExpend(ev);
    } else if (this.tabName == 'SANCTION_PENDING_CLIENTS') {
      if (this._tableData[rowIndex].status == 'COMPLETE') {
        if (ev.isRead == 0) {
          this._tableData[rowIndex].isRead = 1;
          this.updateReadStatusOfPendingSanctionClients(ev.id);
        }
        this.sanctionDataService.updateClientName(this._tableData[rowIndex].clientName);
        this.router.navigate(['nameScreeningDetails', 'pending', ev.id], { relativeTo: this.activatedRoute.parent });
      }
    } else if (this.tabName == 'SANCTION_COMPLETED_CLIENTS') {
      this.router.navigate(['nameScreeningDetails', 'completed', ev.id], { relativeTo: this.activatedRoute.parent });
    } else if (this.tabName == 'CLIENT_DUPLICATES') {
      if (ev.isRead == 0) {
        this._tableData[rowIndex].isRead = 1;
        this.updateReadStatusOfDuplicates(ev.id);
      }
      this.rowExpend(ev);
    } else if (this.tabName == 'KYC_PROFILE') {
      this.updateReadStatus(ev);
      this.router.navigate([ev.clientId], { relativeTo: this.activatedRoute });
    } else if (this.tabName == 'BRIEF_NNEWS') {
      this.updateNNReadStatus(ev);
      this.router.navigate([ev.clientId], { relativeTo: this.activatedRoute });
    } else if (ev.sourceName !== undefined) {
      ev.checked = 'true';
      this.onRadioButtonClicked(ev);
    } else {
      this.rowExpend(ev);
    }

    // if (ev.currentRowClicked) {
    this.fetchDataForExpandedRow(ev);
  }

  rowExpend(ev){
    if (this._config.rowExpand) {
      _.each(
        this._tableData,
        function (a, b) {
          if (a[this._config.dataKeyId] === ev[this._config.dataKeyId]) {
            let data = [];
            this.getSelectedResult = this.searchSessionService.get();
            if (this.getSelectedResult) {
              data = this.getSelectedResult['data'];

              if (a[this._config.dataKeyId] !== data['id']) {
                this.clearSearchSessionCB();
              }
            }
            if (a.currentRowClicked) {
              a.currentRowClicked = false;
            } else {
              a.currentRowClicked = true;
            }
          } else {
            a.currentRowClicked = false;
          }
          if (a.clients) {
            _.each(a.clients, function (c, d) {
              c.isExpanded = false;
            });
          }
        },
        this
      );
    }
  }

  fetchDataForExpandedRow(ev) {
    this.expandedJobData = ev;
    if (this.tabName == TAB_NAME.JOBS_IN_PROGRESS) {
      //if (ev.clients) {
      //  return;
      //}
      this.getJobClientList(ev);
    } else if (this.tabName == TAB_NAME.COMPLETED_JOBS) {
      this.getCompletedJobClientList(ev);
    } else if (this.tabName == TAB_NAME.KEY_CONTROLLERS) {
      this.getKeyControllerDetailedTab(ev);
    } else if (this.tabName == TAB_NAME.OWNERSHIP) {
      // } else if (this.tabName == TAB_NAME.SHARE_HOLDERS) {
      //   this.getShareHolderData(ev);
      // }

      this.getOwnershipData(ev);
    } else if (this.tabName === TAB_NAME.SUBSIDIARIES) {
      this.getSubsidiariesData(ev);
    } else if (this.tabName === TAB_NAME.BRANCHES) {
      this.getBranchesData(ev);
    }
  }

  /**
   * @method getJobClientList
   * This function fetches the client list from the server as well as
   * checks tha if some job is selected via checkbox.
   * If yes, then this function adds a disabled property to the
   * clients which are about to be expanded
   *  */
  getJobClientList(ev) {
    this.api.getJobClientList(ev.runId).subscribe(
      (response: any) => {
        if (response) {
          if (response.isError) {
          }
        }
        ev.clients = response.clientList;

        /****************************/
        if (this.discardJobService.getSelectedJobs().length != 0) {
          this.addDisabledPropertyForChild(ev.clients);
        }

        this.rowExpandedEvent.emit(ev);
      },
      (error) => {}
    );
  }

  addDisabledPropertyForChild(clients: []) {
    _.each(
      clients,
      function (a, b) {
        // a.isChecked = false;
        a.checkBoxDisabled = true;
      },
      this
    );
  }

  onTriggerPullNotification(ev) {
    this.rowExpandedEvent.emit(ev);
  }

  // getCompletedJobClientList(ev) {
  //   this.api.getCompletedJobClientList().subscribe(
  //     (response: any) => {
  //       if (response) {
  //         if (response.isError) {

  //         }
  //       }
  //       ev.clients = response.clientList;
  //     },
  //     error => {}
  //   );
  // }

  getCompletedJobClientList(ev) {
    this.getJobClientList(ev);
  }

  keyControllerData: any;

  // getKeyControllerDetailedTab(ev) {
  //   console.log(ev);
  //   this.reportApiService.getKeyControllerData().subscribe(
  //     (response: any) => {
  //       if (response) {
  //         if (response.isError) {
  //           console.log(response);
  //         }
  //       }
  //       this.keyControllerData = response;
  //     },
  //     error => {}
  //   );
  // }

  getKeyControllerDetailedTab(ev) {
    this.keyControllerData = ev;
  }

  // shareHolderData: any;

  // getShareHolderData(ev) {
  //   // this.reportApiService.getShareHolderData().subscribe(
  //   //   (response: any) => {
  //   //     if (response) {
  //   //       if (response.isError) {
  //   //         console.log(response);
  //   //       }
  //   //     }
  //   //     this.shareHolderData = response;
  //   //     console.log('this.shareHolderData: ', this.shareHolderData);
  //   //   },
  //   //   error => {}
  //   //  );
  //   //this.shareHolderData.
  // }

  ownerShipData: any;

  getOwnershipData(ev) {
    this.ownerShipData = ev;
  }

  unSelectCurrentRows() {
    const tt = this._tableData.slice(this.pageFirstIndex, this.pageFirstIndex + this.pageSize);
    let c = _.pluck(tt, this._config.dataKeyId);
    this.selectedClients = _.filter(
      this.selectedClients,
      function (val, index) {
        if (!_.contains(c, val[this._config.dataKeyId])) {
          return true;
        } else {
          this.setCheckboxState(this.selectedClients[index], false);
          return false;
        }
      },
      this
    );
  }

  public emitSelection() {
    const exp = {
      pageSize: this.pageSize,
      selectedCount: this.selectedClients.length,
      selection: this.selectedClients,
    };
    this.rowSelectUnselect.emit(exp);
  }

  public emitSelection2() {
    let headerChecked = this.isAllRecordsSelectedOnCurrentPage();
    const exp = {
      pageSize: this.pageSize,
      selectedCount: this.selectedClients.length,
      selection: this.selectedClients,
      fromHeader: true,
      headerChecked: headerChecked,
    };
    this.rowSelectUnselect.emit(exp);
  }

  private processTemplateConfig(config: any): void {
    if (!config) {
      return;
    }
    if (config.columns !== undefined) {
      config.columns.forEach((column, i) => {
        column.template = this.getTemplate(column.templateConfig);
        if (column.defaultSort) {
          this.isOnHover[i] = true;
          this.isColumnSorted[i] = true;
        } else {
          this.isOnHover[i] = false;
          this.isColumnSorted[i] = false;
        }
      });
    }
  }

  private getTemplate(templateConfig: any): TemplateRef<any> {
    let template: TemplateRef<any>;
    if (!templateConfig) {
      return this.defaultTemplate;
    }

    switch (templateConfig.name) {
      case TEMPLATE_NAME.CHECKBOX_TEMPLATE: {
        template = this.checkboxTemplate;
        break;
      }
      case TEMPLATE_NAME.SANCTIONS_TEMPLATE: {
        template = this.sanctionsTemplate;
        break;
      }
      case TEMPLATE_NAME.TEST_TEMPLATE: {
        template = this.testTemplate;
        break;
      }
      case TEMPLATE_NAME.ITEM_STATUS_TEMPLATE: {
        template = this.itemStatusTemplate;
        break;
      }
      case TEMPLATE_NAME.ALERT_TYPE_CARDS: {
        template = this.alertTypeCards;
        break;
      }
      case TEMPLATE_NAME.TOGGLABLE_LINK: {
        template = this.toggelableLink;
        break;
      }
      case TEMPLATE_NAME.JOB_PROGRESS_TEMPLATE: {
        template = this.jobProgressTemplate;
        break;
      }
      case TEMPLATE_NAME.JOB_ACTION_TEMPLATE: {
        template = this.jobActionTemplate;
        break;
      }
      case TEMPLATE_NAME.GET_DATE_TEMPLATE: {
        template = this.getDateTemplate;
        break;
      }
      case TEMPLATE_NAME.GET_DATE_ONLY_TEMPLATE: {
        template = this.getDateOnlyTemplate;
        break;
      }
      case TEMPLATE_NAME.EXPAND_TEMPLATE: {
        template = this.expandTemplate;
        break;
      }
      case TEMPLATE_NAME.ACTION_TEMPLATE: {
        template = this.actionTemplate;
        break;
      }
      case TEMPLATE_NAME.ALL_ANS_ICONS_TEMPLATE: {
        template = this.allAnsIconsTemplate;
        break;
      }
      case TEMPLATE_NAME.FLAG_TEMPLATE: {
        template = this.flagTemplate;
        break;
      }

      case TEMPLATE_NAME.RADIO_BUTTON_TEMPLATE: {
        template = this.radioButtonTemplate;
        break;
      }
      case TEMPLATE_NAME.GET_ARTICLE_COUNT_TEMPLATE: {
        template = this.getArtcileCountTemplate;
        break;
      }
      case TEMPLATE_NAME.PDF_ICON_TEMPLATE: {
        template = this.pdfIconTemplate;
        break;
      }
      case TEMPLATE_NAME.ROW_FLAG_TEMPLATE: {
        template = this.rowFlagTemplate;
        break;
      }
      case TEMPLATE_NAME.ALERT_COUNT_TEMPLATE: {
        template = this.alertCountTemplate;
        break;
      }
      case TEMPLATE_NAME.DOWNLOAD_BUTTON_TEMPLATE: {
        template = this.downloadButtonTemplate;
        break;
      }
      case TEMPLATE_NAME.MULTILINE_TEMPLATE: {
        template = this.multiLineTemplate;
        break;
      }
      case TEMPLATE_NAME.COLORED_TEXT_TEMPLATE: {
        template = this.coloredTextTemplate;
        break;
      }
      case TEMPLATE_NAME.COLORED_LINK_TEXT: {
        template = this.coloredLinkText;
        break;
      }
      case TEMPLATE_NAME.ACTIVE_INACTIVE: {
        template = this.activeInactive;
        break;
      }
      case TEMPLATE_NAME.DOTS_TEMPLATE: {
        template = this.getDotsTemplate;
        break;
      }
      case TEMPLATE_NAME.TWO_LINER_TEMPLATE: {
        template = this.twoLinerTemplate;
        break;
      }

      default:
        template = this.defaultTemplate;
    }
    return template;
  }

  showClientInfo(event, overlaypanel: OverlayPanel, client) {
    console.log(client);
    this.clientInfo = client;
    event.stopPropagation();
    overlaypanel.toggle(event);
  }

  clientChecked(event) {
    event.stopPropagation();
  }

  onAccordianHeaderClick(client) {
    this.api.getJobSourceList(client.jobId).subscribe(
      (response: any) => {
        if (response) {
          if (response.isError) {
          }
        }
        client.sources = response.sourceList;
      },
      (error) => {}
    );
  }

  onClickedOutside(e: Event, overlaypanel) {
    overlaypanel.hide();
  }

  onHeaderClick(e: Event, i) {
    if (e.isTrusted && !/msie\s|trident\/|edge\//i.test(window.navigator.userAgent)) {
      this.clearSearchSessionCB();
    }
    this.onTableDataUpdate();
    this.isOnHover.forEach((ele, index) => {
      if (index === i) {
        this.isOnHover[index] = true;
        this.isColumnSorted[index] = true;
      } else {
        this.isOnHover[index] = false;
        this.isColumnSorted[index] = false;
      }
    });
  }

  onClientForJobCheckbox(ev, client) {
    if (this.selectedClientForJob.length >= 1) {
      const isClientSelected = this.selectedClientForJob.findIndex((item) => item.clientId === client.clientId);
      if (isClientSelected === -1) {
        client.hasChecked = true;
        this.selectedClientForJob.push(client);
      } else {
        client.hasChecked = false;
        this.selectedClientForJob = this.selectedClientForJob.filter((obj) => {
          return obj.hasChecked === true;
        });
      }
    } else {
      client.hasChecked = true;
      this.selectedClientForJob.push(client);
    }
    this.emitClientsOfJobSelection();
  }

  public emitClientsOfJobSelection() {
    const exp = {
      selectedCount: this.selectedClientForJob.length,
      selection: this.selectedClientForJob,
    };
    this.rowSelectUnselectOfJobClients.emit(exp);
  }

  public editScheduledJob(event, jobData) {
    event.stopPropagation();
    let j = JSON.parse(JSON.stringify(jobData));
    this.editJobScheduled.emit(j);
  }

  public pauseJob(event, jobData) {
    event.stopPropagation();
    this.api.pauseJob(jobData.runId, jobData).subscribe(
      (response: any) => {
        if (response) {
          this.pauseOrResumeStatus(response, jobData);
        }

        // jobData.status = response.run.status;
        // if (jobData.currentRowClicked) {
        //   jobData.clients.forEach(client => {
        //     const filteredClient = response.run.clientList.filter(cli => {
        //       return cli.jobId === client.jobId;
        //     });
        //     client.clientStatus = filteredClient[0].status;
        //     if (client.currentRowClicked) {
        //       client.sources.forEach(source => {
        //         const filteredSource = filteredClient[0].sourceList.filter(
        //           src => {
        //             return src.sourceId === source.id;
        //           }
        //         );
        //         source.status = filteredSource[0].status;
        //         this.nestedDataTableComponent.addStatusToSources(client);
        //       });
        //     }
        //   });
        // }
        ////////////////////////// this.pauseJobEvent.emit(response);
      },
      (error) => {}
    );
  }

  public resumeJob(event, jobData) {
    event.stopPropagation();
    this.api.resumeJob(jobData.runId, jobData).subscribe(
      (response: any) => {
        if (response) {
          this.pauseOrResumeStatus(response, jobData);
        }

        // jobData.status = response.run.status;
        // if (jobData.currentRowClicked) {
        //   jobData.clients.forEach(client => {
        //     const filteredClient = response.run.clientList.filter(cli => {
        //       return cli.jobId === client.jobId;
        //     });
        //     client.clientStatus = filteredClient[0].status;
        //     if (client.currentRowClicked) {
        //       client.sources.forEach(source => {
        //         const filteredSource = filteredClient[0].sourceList.filter(
        //           src => {
        //             return src.sourceId === source.id;
        //           }
        //         );
        //         source.status = filteredSource[0].status;
        //         this.nestedDataTableComponent.addStatusToSources(client);
        //       });
        //     }
        //   });
        // }
        /// ////////// this.resumeJobEvent.emit(response);
      },
      (error) => {}
    );
  }

  pauseOrResumeStatus(response, jobData) {
    this.notificationLogic(response);
    jobData.status = response.run.status;
    this.onTriggerPullNotification(undefined);
    if (jobData.currentRowClicked) {
      jobData.clients.forEach((client) => {
        const filteredClient = response.run.clientList.filter((cli) => {
          return cli.jobId === client.jobId;
        });
        client.clientStatus = filteredClient[0].status;
        if (client.currentRowClicked) {
          client.sources.forEach((source) => {
            const filteredSource = filteredClient[0].sourceList.filter((src) => {
              return src.sourceId === source.id;
            });
            source.status = filteredSource[0].status;
            this.nestedDataTableComponent.addStatusToSources(client);
          });
        }
      });
    }
  }

  notificationLogic(response) {
    if (response.run && response.run.showErrorMessage && response.run.errorMessageCode == PauseMessages.PARTIAL_PAUSED) {
      this.displayNotification(
        'Only sources which are in pending status will be Paused.',
        'Sources in progress will continue processing till data extraction is completed.',
        'icon icon-error'
      );
    } else if (
      response.run &&
      response.run.showErrorMessage &&
      response.run.errorMessageCode == PauseMessages.SORRY_CAN_NOT_PAUSED
    ) {
      this.displayNotification(MESSAGES.JOB_ALREADY_IN_PROGRESS, '', 'icon icon-error');
    }
    if (response.errorMessage) {
      this.displayNotification(response.errorMessage, '', 'icon icon-error');
    }
  }

  displayNotification(message1: string, message2: string, icon: string) {
    this.globalPopupRef = this.dialogService.open(NotificationDialogComponent, {
      width: '60%',
      showHeader: false,
      data: {
        messageLine1: message1,
        messageLine2: message2,
        icon: icon,
      },
    });
    setTimeout(() => {
      this.globalPopupRef.close();
    }, 6000);
  }

  checkOverflow(element) {
    if (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth) {
      return true;
    } else {
      return false;
    }
  }

  set tabName(value: any) {
    this._tabName = value;
    this.conditionalData(this._tabName);
  }

  private conditionalData(tabName: any) {
    if (!tabName) {
      return '';
    }

    switch (tabName) {
      case TAB_NAME.JOBS_IN_PROGRESS: {
        this.dashboardconfig.getJobsInProgressClientsConfig().subscribe((response: any) => {
          this.nestedtTableConfig = response.components.dataTable;
        });
        break;
      }
      case TAB_NAME.CLIENT_DUPLICATES: {
        this.dashboardconfig.getNestedClientDuplicateConfig().subscribe((response: any) => {
          this.nestedtTableConfig = response.components.dataTable;
        });
        break;
      }
      case TAB_NAME.COMPLETED_JOBS: {
        this.dashboardconfig.getCompletedJobClientsConfig().subscribe((response: any) => {
          this.nestedtTableConfig = response.components.dataTable;
        });
        break;
      }
      case TAB_NAME.SUBSIDIARIES: {
        this.reportApiService.getSubsidiariesNameConfig().subscribe((response: any) => {
          this.nestedtTableConfig = response.components.dataTable;
        });
        break;
      }
      case TAB_NAME.BRANCHES: {
        this.reportApiService.getBranchesNameConfig().subscribe((response: any) => {
          this.nestedtTableConfig = response.components.dataTable;
        });
        break;
      }
    }
  }

  public showSortIcon(i) {
    this.isOnHover[i] = true;
  }

  public hideSortIcon(i) {
    if (!this.isColumnSorted[i]) {
      this.isOnHover[i] = false;
    }
  }

  customSort(event: SortEvent) {
    let array = [];
    if (event.field === 'status') {
      array = this.sortArrayByDate(event.data, event.order);
    } else {
      array = event.data;
    }
    array.sort((data1, data2) => {
      const value1 =
        data1[event.field] !== undefined
          ? isNaN(data1[event.field])
            ? data1[event.field].toLowerCase()
            : data1[event.field]
          : '';

      const value2 =
        data2[event.field] !== undefined
          ? isNaN(data2[event.field])
            ? data2[event.field].toLowerCase()
            : data2[event.field]
          : '';
      let result = null;

      if (
        event.field === 'received' ||
        event.field === 'createDate' ||
        event.field === 'startDateTime' ||
        event.field === 'finishDate' ||
        event.field === 'publishedDate' ||
        event.field === 'receivedDate' ||
        event.field === 'alertDate' ||
        event.field === 'addedOn' ||
        event.field == 'changeDate'
      ) {
        const date1 = Date.parse(value1);
        const date2 = Date.parse(value2);
        result = date1 < date2 ? -1 : date1 > date2 ? 1 : 0;
      } else if (event.field === 'status') {
        result = data1.priority < data2.priority ? -1 : data1.priority > data2.priority ? 1 : 0;
      } else if (event.field === 'relevancy') {
        result = data1.relevancySort < data2.relevancySort ? -1 : data1.relevancySort > data2.relevancySort ? 1 : 0;
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }

      return event.order * result;
    });
  }

  public sortArrayByDate(array, order) {
    return array.sort((a, b) => {
      const date1 = Date.parse(a.createDate);
      const date2 = Date.parse(b.createDate);
      // const date1 = Number(a.createDate);
      // const date2 = Number(b.createDate);
      const result = date1 > date2 ? -1 : date2 > date1 ? 1 : 0;
      return result;
    });
  }

  public openCaptcha($event) {
    event.stopPropagation();
    this.showCaptchaEvent.emit($event);
  }
  public skipCaptchaSource($event, rowData) {
    event.stopPropagation();
    let source = rowData.captcha[0];
    source.multipleResults = false;
    this.api.skipCaptchaSource(source, rowData.runId).subscribe(
      (response: any) => {
        if (response) {
          if (!response.isError) {
            $event[0].isSkipped = true;
            //this.triggerPullNotification.emit(rowData);
            this.onTriggerPullNotification(undefined);
          }
        }
      },
      (error) => {}
    );
  }

  clearSearchSessionCB() {
    this.searchSessionService.isSearchResultPresent.next();
    this.searchSessionService.isTabChangesAfterSearch.next();
  }

  clearSearchOnTabChange() {
    this.searchSessionService.isTabChangesAfterSearch.subscribe(
      () => {
        let data = [];
        if (this.getSelectedResult) {
          data = this.getSelectedResult['data'];
          const isRecordSelected = this._tableData.filter((obj, index) => {
            if (obj[this._config.dataKeyId] === data['id']) {
              if (this.getSelectedResult['type'] === 'JIP') {
                obj.currentRowClicked = false;
                obj.isSearchRecordFlag = false;
              } else {
                obj.isSearchRecordFlag = false;
              }
              this.isLoadedBySearch = true;
              this.searchItemFoundAt = index;
              this.first = index - (index % this.pageSize);
            }
            return obj;
          }, this);
          //this.getSelectedResult = undefined;
        }
      },
      (error) => {
        console.log('Fetch Article API: ', error);
      }
    );
  }

  // getSubsidiariesData(ev) {
  //   console.log(ev);
  //   this.reportApiService.getSubsidiariesName().subscribe(
  //     (response: any) => {
  //       if (response) {
  //         if (response.isError) {
  //         }
  //       }
  //       ev.clients = response.subsidiaries;
  //     },
  //     error => {}
  //   );
  // }

  getSubsidiariesData(ev) {
    ev.clients = ev.subArray;
    console.log(ev);
  }

  // getBranchesData(ev) {
  //   this.reportApiService.getBranchesName().subscribe(
  //     (response: any) => {
  //       if (response) {
  //         if (response.isError) {
  //         }
  //       }
  //       ev.clients = response.branches;
  //     },
  //     error => {}
  //   );
  // }

  getBranchesData(ev) {
    ev.clients = ev.subArray;
    console.log(ev);
  }

  onRadioButtonClicked(row) {
    console.log(row);
    this.sourceSelectedEvent.emit(row);
  }

  // downloadSeeAllAnswerFile(row) {
  //   console.log(row);
  //   let fileType = row.referenceContentType;
  //   let sourceFieldId = row.sourceId;
  //   let clientId = this.getClientId();
  //   let extensionType = this.getExtensionFromContentType(fileType);
  //   this.reportApiService
  //     .downloadAnswerFile(clientId, sourceFieldId, fileType)
  //     .subscribe(data => {
  //       saveAs(data, sourceFieldId + '.' + extensionType);
  //     });
  // }

  downloadSeeAllAnswerFile(row) {
    console.log(row);
    const fileType = row.referenceContentType;
    const sourceFieldId = row.sourceFileId;
    const clientId = this.getClientId();
    const extensionType = this.getExtensionFromContentType(fileType);
    const url = REPORT_API_CONSTANT.ANSWERS_FILE_DOWNLOAD + '?clientVersionId=' + clientId + '&sourceFileId=' + sourceFieldId;
    this.http.getFile(url).subscribe((resp) => {
      let contentDispo = '';

      contentDispo = resp.headers.get('content-disposition');
      let fileName = this.util.getFileName(contentDispo);
      saveAs(resp.body, fileName + '.' + extensionType);
    });
  }

  getExtensionFromContentType(fileType: string) {
    let name: string[] = fileType.split('/');
    return name[1];
  }

  getClientId() {
    const t = this.router.url.substring(this.router.url.lastIndexOf('/'));
    let clientId = t.substr(1, t.length);
    return clientId;
  }

  tooltipValue: string;
  displayTooltip(e, value, col) {
    // console.log(e.offsetWidth+ '-'+ e.scrollWidth);
    // console.log(col);
    //if (e.offsetWidth < e.scrollWidth && tooltip === 'true') {
    if (col && col.templateConfig && col.templateConfig.name == 'GET_DATE_TEMPLATE') {
      value = new DateTimeFormatPipe('en-US').transform(value);
    }

    if (e.offsetWidth < e.scrollWidth) {
      this.tooltipValue = value;
    } else {
      this.tooltipValue = '';
    }
  }

  downloadReport(ev, rowData) {
    ev.stopImmediatePropagation();
    const jobId = rowData.runId;
    const timezone = jstz.determine();
    const url = REPORT_API_CONSTANT.JOB_LEVEL_VIEW_REPORT + '/' + jobId + '/' + timezone.name();
    rowData['downloading'] = true;
    this.http.getFile(url).subscribe(
      (resp) => {
        let contentDispo = '';
        const keys = resp.headers.keys();
        contentDispo = resp.headers.get('content-disposition');
        let fileName = this.util.getFileName(contentDispo);
        saveAs(resp.body, fileName);
        rowData['downloading'] = false;
        this.displayToast('downloadSuccess', rowData);
      },
      (err) => {
        rowData['downloading'] = false;
        this.displayToast('downloadFail', rowData);
      }
    );
  }

  displayToast(type: any, jobInfo: any) {
    this.messageService.add({ key: type, summary: jobInfo.runName, sticky: type == 'downloadFail' });
  }

  downloadNNPdf(e, rowData) {
    e.stopImmediatePropagation();
    const clientId = rowData.clientId;

    const url = DASHBOARD_API_CONSTANT.DOWNLOAD_NN_PDF + '/' + clientId;
    rowData['downloading'] = true;
    this.http.getFile(url).subscribe(
      (resp) => {
        let contentDispo = '';
        const keys = resp.headers.keys();
        contentDispo = resp.headers.get('content-disposition');
        let fileName = this.util.getFileName(contentDispo);
        saveAs(resp.body, fileName);
        rowData['downloading'] = false;
        this.displayNNPdfToast('downloadSuccess', fileName);
      },
      (err) => {
        rowData['downloading'] = false;
        //this.displayNNPdfToast('downloadFail', fileName);
      }
    );
  }

  displayNNPdfToast(type: any, fileName: any) {
    this.messageService.add({ key: type, summary: fileName, sticky: type == 'downloadFail' });
  }

  coloredLinkClicked(e, data) {
    e.preventDefault();
    let url = this.util.getApplicationBaseHref() + '#/clientProfile/' + data['clientId'];
    window.open(url, '_blank');
    e.stopPropagation();
  }

  toggelableLinkClicked(e, data) {
    this.checkForMiniAndFull(e, data['clientId']);
  }

  checkForMiniAndFull(e, clientId) {
    this.reportApiService.getClientInfo(clientId).subscribe((response: any) => {
      console.log(response);
      if (response) {
        let url = '';
        if (response.isMiniProfile == 1) {
          url = this.util.getApplicationBaseHref() + '#/miniProfile/' + clientId;
        } else {
          url = this.util.getApplicationBaseHref() + '#/clientProfile/' + clientId;
        }
        window.open(url, '_blank');
        e.stopPropagation();
      }
    });
  }
}

const PauseMessages = {
  SORRY_CAN_NOT_PAUSED: 'SORRY_CAN_NOT_PAUSED',
  PARTIAL_PAUSED: 'PARTIAL_PAUSED',
};
