import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

import { UtilService } from './util.service';

// import { HttpInterceptor } from '../auth/http.interceptor.service';
@Injectable({
  providedIn: 'root'
})
export class HTTPAPIService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private utilService: UtilService
  ) {}

  get(url, params?: any) {
    url = this.getFullUrl(url);
    const req = { params: params };
    return this.http.get(url, req).pipe(
      map(
        response => {
          // this.isAuthorized(response);
          return response;
        },
        error => {}
      )
    );
  }

  getFile(url): Observable<HttpResponse<any>> {
    url = this.getFullUrl(url);
    return this.http
      .get(url, { responseType: 'blob', observe: 'response' })
      .pipe(
        tap(
          // Log the result or error
          data => console.log(data),
          error => console.log(error)
        )
      );
  }

  post(url, data) {
    url = this.getFullUrl(url);
    return this.http.post(url, data).pipe(
      map(
        response => {
          // this.isAuthorized(response);
          return response;
        },
        error => {}
      )
    );
  }

  put(url, data) {
    url = this.getFullUrl(url);
    return this.http.put(url, data).pipe(
      map(
        response => {
          // this.isAuthorized(response);
          return response;
        },
        error => {}
      )
    );
  }

  delete(url) {
    url = this.getFullUrl(url);
    return this.http.delete(url).pipe(
      map(
        response => {
          // this.isAuthorized(response);
          return response;
        },
        error => {}
      )
    );
  }

  private getFullUrl(url: string): string {
    if (url.indexOf('assets/') >= 0) {
      return url;
    }

    return environment.API_BASE + url; //  TODO: use this to add dev or prod base url.
  }
}
