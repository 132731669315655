import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { ReRunMiniToFull } from 'src/app/dashboard/models/clients.model';
import { DashboardAPIService } from '../dashboard/services/dashboard.api.service';
import * as moment from 'moment';

import { LicenseInfoService, REMAINING_CLIENTS, IS_EXPIRED, REMAINING_DAYS } from 'src/app/auth/license-info.service';
import { MESSAGES } from 'src/app/common/appMessages';

declare var _: any;

@Component({
  selector: 'app-re-run-mini-to-full',
  templateUrl: './re-run-mini-to-full.component.html',
  styleUrls: ['./re-run-mini-to-full.component.scss'],
})
export class ReRunMiniToFullComponent implements OnInit {
  dataObject: ReRunMiniToFull;
  scheduleDate;
  scheduleTime;
  jobName;
  selectedSourceCount: number = 0;
  currentDateObj;

  constructor(
    private licenseService: LicenseInfoService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private api: DashboardAPIService
  ) {
    if (this.config) {
      this.dataObject = this.config.data;
      this.selectedSourceCount = this.getCountSelectedSources();
    }
  }

  editMode;
  enableEditMode() {
    this.editMode = true;
  }

  disableEditMode() {
    this.editMode = false;
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    let selectedSources = this.dataObject.sources.filter((item: any) => item.isSelected == true);
    let reqParam = {
      runName: this.jobName,
      description: this.dataObject.description,
      clientIds: [this.dataObject.clientId],
      selectedSourcesIds: _.pluck(selectedSources, 'id'),
      startDateTime: this.getDateTimeObj(this.scheduleDate, this.scheduleTime),
      isScheduled: this.checkIsCurrentDateTime(),
      miniToFull: this.dataObject.action == 'miniToFull' ? true : false,
    };
    this.api.createJobForClients(reqParam).subscribe((response: any) => {
      if (response.isSuccess) {
        this.contentMessage = MESSAGES.JOB_CREATED;
        this.contentHeader = 'Success!';
        this.finalPopup = true;
        setTimeout(() => {
          this.ref.close({ action: true, runId: response.runId });
        }, 2000);
      } else {
        if (response.userLicenseInfo) {
          this.displayLicenseInfo(response.userLicenseInfo);
        }
      }
    });
  }

  failed: boolean;
  finalPopup: boolean = false;
  contentHeader;
  contentMessage;
  displayLicenseInfo(licenseInfo) {
    this.licenseService.setLicenseInfo(licenseInfo);
    this.finalPopup = true;
    if (licenseInfo[IS_EXPIRED].toLowerCase() == 'true') {
      this.failed = true;
      this.contentHeader = 'Failed!';
      this.contentMessage = `Your license has expired, Please contact admin for access`;
    } else if (licenseInfo[REMAINING_CLIENTS] != '0') {
      this.failed = true;
      this.contentHeader = 'Failed!';
      this.contentMessage = `You have only ${licenseInfo.remainingClients} clients remaining in your account, Please try again with fewer clients`;
    } else if (licenseInfo[REMAINING_CLIENTS] == '0') {
      this.failed = true;
      this.contentHeader = 'Failed!';
      this.contentMessage = `You have 0 clients remaining in your account, Please contact admin for access`;
    }
    setTimeout(() => {
      this.ref.close();
    }, 4000);
  }

  private checkIsCurrentDateTime() {
    const currentDateTime = this.getDateTimeObj(this.currentDateObj, this.currentDateObj);
    const scheduledDateObj = this.getDateTimeObj(this.scheduleDate, this.scheduleTime);
    if (currentDateTime === scheduledDateObj) {
      return false;
    } else {
      return true;
    }
  }

  private getDate(date) {
    date = new Date(date);
    const yyyy = date.getFullYear();
    const mm = ('0' + (date.getMonth() + 1)).slice(-2);
    const dd = ('0' + date.getDate()).slice(-2);
    return yyyy + '-' + mm + '-' + dd;
  }

  private getTimeOnly(time) {
    time = new Date(time);
    const hrs = ('0' + time.getHours()).slice(-2);
    const mins = ('0' + time.getMinutes()).slice(-2);
    return hrs + ':' + mins + ':01';
  }

  private getDateTimeObj(date, time) {
    const modifiedDate = new Date(this.getDate(date) + ' ' + this.getTimeOnly(time)).getTime();
    return moment(modifiedDate).format();
  }

  getCountSelectedSources() {
    let count = this.dataObject.sources.filter((item: any) => item.isSelected == true).length;
    return count;
  }

  selectSource(e, source) {
    source.isSelected = !source.isSelected;
    this.selectedSourceCount = this.getCountSelectedSources();
  }

  selectedAllSources(allSelected) {
    this.dataObject.sources.forEach((item: any) => {
      item.isSelected = allSelected;
    });
    this.selectedSourceCount = allSelected ? this.dataObject.sources.length : 0;
  }

  ngOnInit() {
    const uniqueId = Math.floor(Date.now() / 1000);
    this.jobName = 'JN-' + uniqueId;
    this.scheduleDate = new Date();
    this.scheduleTime = new Date();
    this.currentDateObj = JSON.parse(JSON.stringify(this.scheduleDate));
  }
}
