export const DASHBOARD_API_CONSTANT = {
  // CLIENT_LIST: 'assets/json-data/mock/clients.json',
  CLIENT_LIST: 'auth/kyc/clients',
  CLIENT_BY_VERSION_ID: 'auth/kyc/clientByVersionId',
  COUNTRY_OPERATION_LIST: 'auth/kyc/listCountries',
  COUNTRY_INCORPORATION_LIST: 'auth/kyc/listCountries',
  CLIENT_TYPE_LIST: 'auth/kyc/listClientType',
  HIERARCHY_LEVEL_LIST: 'auth/kyc/listHierachyLevel',
  UPLOAD_CLIENTS: 'auth/kyc/clients',
  UPLOAD_INDIVIDUALS: 'auth/kyc/clientsIndividual',
  ADD_SINGLE_CLIENT: 'auth/kyc/client',
  // SOURCES: 'assets/json-data/mock/sources.json',
  ADD_SINGLE_CLIENT_WITH_JOB: 'auth/kyc/clientWithJob',
  SOURCES: 'auth/kyc/getSourceLists',
  CREATE_JOB: 'auth/kyc/run',
  DELETE_ALL_CLIENT: 'auth/kyc/deleteAllClients ',
  DELETE_CLIENTS: 'auth/kyc/deleteMultipleClients?ids=',
  GET_SOURCE_SESSION: 'auth/kyc/getSourceSession',
  ADD_SOURCE_SESSION: 'auth/kyc/addSourceSession',
  UPDATE_SOURCE_SESSION: 'auth/kyc/updateSourceSession',
  DELETE_SOURCE_SESSION: 'auth/kyc/deleteSourceSession',
  // JOB_LIST: 'assets/json-data/mock/jobs.json',
  // JOB_LIST: 'assets/json-data/mock/jobs1.json',
  JOB_LIST: 'auth/kyc/runs',
  JOB_CLIENT_LIST: 'auth/kyc/job-status',
  // JOB_CLIENT_LIST: 'assets/json-data/mock/listJobClients1.json',
  JOB_SOURCE_LIST: 'auth/kyc/client-status',
  // JOB_SOURCE_LIST: 'assets/json-data/mock/listJobSources1.json',
  DELETE_JOBS: 'auth/kyc/deleteRun?runIds=',
  UPDATE_JOB: 'auth/kyc/updateRun',
  // COMPLETED_JOB_LIST: 'assets/json-data/mock/completedJobs.json',
  COMPLETED_JOB_LIST: 'auth/kyc/runs?status=complete',
  FILTER_COMPLETED_JOB_LIST: 'auth/kyc/runs?status=complete&filterParams=',
  COMPLETED_JOB_CLIENT_LIST: 'assets/json-data/mock/completesJobClients.json',
  COMPLETED_JOB_SOURCE_LIST: 'assets/json-data/mock/completedJobSourceList.json',
  //QUESTIONS_AND_ANSWERS_LIST: 'auth/report/questions/',
  QUESTIONS_AND_ANSWERS_LIST: 'auth/report/questions-version/',
  //KEYCONTROLLER_LIST: 'auth/report/keycontrollers',
  KEYCONTROLLER_LIST: 'auth/report/keycontrollers-version',
  //SHAREHOLDER_LIST: 'assets/json-data/mock/shareHolderTab/shareHolderApi.json',
  SHAREHOLDER_LIST: 'auth/report/shareholders-version/',
  //SHAREHOLDER_LIST: 'auth/report/shareholders/',
  KEYCONTROLLER_DATA: 'assets/json-data/mock/keyControllerTab/keyControllerExp.json',
  SHAREHOLDER_DATA: 'assets/json-data/mock/shareHolderTab/shareHolderExp.json',
  PAUSE_JOB: 'auth/kyc/pauseRun',
  RESUME_JOB: 'auth/kyc/resumeRun',
  JIP_NOTIFICATION_PULL: 'auth/kyc/run-status?runids=',
  PROFILE_RUN_STATUS: 'auth/kyc/profile-run-status',
  OWNERSHIP_LIST: 'auth/report/ownerships-version/',
  MOCKOWNERSHIP_LIST: 'assets/json-data/mock/ownershipTab/ownershipApi.json',
  CLIENT_DETAILED_PROFILE: 'auth/report/clientProfileDetail',
  CLIENT_MINI_PROFILE: 'auth/kyc/miniProfileDetail',
  OWNERSHIP_DATA: 'assets/json-data/mock/ownershipTab/ownershipExp.json',
  SOURCE_INFO_DATA: 'auth/report/sourcesInformation-version',
  JIP_SOURCE_PROXY_CAPTCHA: 'unauth/kyc/proxy?url=',
  JIP_SOURCE_CAPTCHA_SUBMIT: 'auth/kyc/frontend/captchaCompleted?login=true&url=',
  LIST_MULTIPLE_RESULT: 'auth/kyc/multiple-results/',
  UPDATE_MULTIPLE_RESULT: 'auth/kyc/multiple-result',
  SKIP_SOURCE: 'auth/kyc/skipSource',
  SKIP_MUTIPLE_SOURCE: 'auth/kyc/skipMultipleSources',
  SKIP_CATPCHA_SOURCE: 'auth/kyc/skipRunSource',
  RETRY_SOURCES: 'auth/kyc/retryJob',
  DISCARD_JOB: 'auth/kyc/discardJobs',
  LOGOUT: 'auth/kyc/logout',
  FINANCIALS: 'auth/report/financials-version',
  //REPORT_GENERATED_DATE: 'auth/report/jobDetails',
  REPORT_GENERATED_DATE: 'auth/report/jobDetails-version',
  BRIEF_NN_NEWS_DATA: 'auth/nn/nnLandingPage',
  LAZY_LOAD_NN_DATA: 'auth/nn/nnLandingLazyLoading',
  FILTERED_NN_NEWS_DATA: 'auth/nn/filterArticles',
  DETAILED_NN_NEWS_DATA: 'auth/nn/getClientArticles',
  ALERT_DASHBOARD_PARAMS: 'auth/nn/alertsDashboard',
  CLIENT_INFO_ALERT: 'auth/nn/getNNClientDetail',
  DISCOUNT_NN_ARTICLE: 'auth/nn/updateDiscountParams',
  UPDATE_STARRED_STATUS: 'auth/nn/updateStarredParams',
  ESCALATE_ARTICLE: 'auth/nn/updateEscalateParams',
  UPDATE_ARTICLE_FINDINGS: 'auth/nn/updateRelevancyParam',
  GET_FINDINGS_DROPDOWN_VALUES: 'auth/nn/listFindings',
  GET_DISCOUNT_DROPDOWN_VALUES: 'auth/nn/listDiscount',
  KYC_PROFILE_DATA: 'auth/nn/clientProfileAlerts',
  FILTERED_KYC_PROFILE: 'auth/nn/filterKycArticles',
  DETAILED_KYC_PROFILE: 'auth/nn/perpetualComparision',
  UPDATE_READ_STATUS: 'auth/nn/updateReadStatus',
  UPDATE_NN_READ_STATUS: 'auth/nn/updateAtricleReadStatus',
  UPDATE_STARRED_STATUS_PROFILE: 'auth/nn/updateClient',
  LICENSE_INFO: 'auth/kyc/getUserLicenseDetail',
  RDC_NN_DATA: 'assets/json-data/mock/nnnewsData.json',
  GET_CLIENT_ENTITIES: 'auth/nn/getNNClientInfo',
  GET_EVENT_DETAIL: 'auth/nn/getEventDetails',
  GET_ENTITY_DETAIL: 'auth/nn/alertEntityDetail',
  GET_PARTY_DETAIL: 'auth/nn/matchPartyEntityDetail',
  NN_ACTION_TAKEN: 'auth/nn/executeAlertAction',
  NN_GLOBAL_ACTION_TAKEN: 'auth/nn/bulkAlertAction',
  GET_SAVED_NN_ACTION: 'auth/nn/getAlertActionData',
  DOWNLOAD_NN_PDF: 'auth/nn/downloadNNPDF',
  ATTACHMENT_NN: 'auth/nn/downloadAlertAttachement',
  NN_DELETE_ATTACHMENT: 'auth/nn/deleteAttachment',
  UPDATE_READSTATUS_JOB: 'auth/kyc/readRun',
  GET_CLIENT_PROFILES: 'auth/kyc/clientProfiles',
  POLL_NEW_CLIENT_PROFILES: 'auth/kyc/clientProfilesByDate',
  // GET_DUPLICATES: 'assets/json-data/mock/duplicates.json',
  GET_DUPLICATES: 'auth/kyc/duplicateClients',
  ACTION_ON_DUPLICATES: 'auth/kyc/actionOnDuplicate',
  UPDATE_DUPLICATE_READ_STATUS: 'auth/kyc/updateDupClientReadStatus',

  GET_SANCTION_DATA: 'auth/kyc/name-screening-clients',
  GET_SANCTION_LEFT_PANE: 'auth/kyc/nameScreeningMatchEntity',
  GET_PENDING_CLIENT_DETAILS: 'auth/kyc/nameScreeningMatchingEntities',
  GET_SANCTION_CLIENT_ITEM_DETAILS: 'auth/kyc/nameScreeningDetail',
  SAVE_SANCTION_DATA: 'auth/kyc/name-screening-action',
  SUBMIT_PENDING_RESOLUTION: 'auth/kyc/updateNameScreeningStatus',
  SANCTION_READ_STATUS: 'auth/kyc/readNameScreening',
  POLL_SERVER_FOR_SANCTION_STATUS: 'auth/kyc/nameScreeningEntityStatus',
  UPDATE_MONITOR_STATUS: 'auth/kyc/monitorClient',
  PERMISSION_INFO: 'auth/kyc/permission-info',
};
