import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kyc-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  @Input() contentHeader: any;
  @Input() contentMessage: any;
  @Input() failed: boolean;
  constructor() {}

  ngOnInit() {}
}
