import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { KycFileuploadComponent } from './components/kyc-fileupload/kyc-fileupload.component';
import { ButtonModule } from 'primeng/button';
import { DataTableComponent } from './components/data-table/data-table.component';
import { EventListenersDirective } from './directives/event-listeners.directive';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ClickOutsideModule } from 'ng4-click-outside';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';

import { ProgressBarModule, MessagesModule, SharedModule, CalendarModule } from 'primeng/primeng';
import { KycProgressbarComponent } from './components/kyc-progressbar/kyc-progressbar.component';
import { DateTimeFormatPipe } from './pipes/date-time-format.pipe';
import { CalculateProgressPipe } from './pipes/calculate-progress.pipe';
import { NestedDataTableComponent } from './components/nested-data-table/nested-data-table.component';
import { HighlightSearch } from './pipes/highlight-search.pipe';
import { InitialCapitalizeChar } from './pipes/initial-capitalize-char.pipe';
import { RandomArrayValue } from './pipes/random-array-value.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SuccessComponent } from './components/success/success.component';
import { KycTickComponent } from './components/kyc-tick/kyc-tick.component';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { KycLoadmoreComponent } from './components/kyc-loadmore/kyc-loadmore.component';
import { GenericMessageComponent } from './generic-message/generic-message.component';
import { GenericLoadMoreComponent } from './components/generic-load-more/generic-load-more.component';

@NgModule({
  declarations: [
    DataTableComponent,
    EventListenersDirective,
    KycFileuploadComponent,
    KycProgressbarComponent,
    DateTimeFormatPipe,
    CalculateProgressPipe,
    NestedDataTableComponent,
    HighlightSearch,
    InitialCapitalizeChar,
    RandomArrayValue,
    SafePipe,
    SuccessComponent,
    KycTickComponent,
    NotificationDialogComponent,
    KycLoadmoreComponent,
    GenericMessageComponent,
    GenericLoadMoreComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TabViewModule,
    SharedModule,
    ButtonModule,
    ProgressBarModule,
    MessagesModule,
    TableModule,
    TabViewModule,
    DropdownModule,
    TooltipModule,
    AccordionModule,
    CheckboxModule,
    OverlayPanelModule,
    ClickOutsideModule,
    ScrollPanelModule,
    RadioButtonModule,
    ToastModule,
    CalendarModule,
  ],
  exports: [
    DataTableComponent,
    EventListenersDirective,
    KycFileuploadComponent,
    KycProgressbarComponent,
    DateTimeFormatPipe,
    CalculateProgressPipe,
    HighlightSearch,
    InitialCapitalizeChar,
    RandomArrayValue,
    SafePipe,
    SuccessComponent,
    KycTickComponent,
    KycLoadmoreComponent,
    ScrollPanelModule,
    CheckboxModule,
    CalendarModule,
    GenericLoadMoreComponent,
  ],
  entryComponents: [SuccessComponent, NotificationDialogComponent, GenericMessageComponent],
})
export class KYCSharedModule {}
