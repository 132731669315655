import { Injectable } from '@angular/core';
import { DashboardAPIService } from '../../services/dashboard.api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DashboardConfigService } from '../../services/dashboard.config.service';
import { AC } from '../alert-constants';
import { ModuleData } from '../../models/clients.model';
import { RootModuleConfiguraion } from 'src/app/root-module-config';
import { UtilService } from 'src/app/common/util.service';

@Injectable({
  providedIn: 'root',
})
export class DataProviderService {
  private alertDashboardInfo;
  private nnTableData;
  private nnTableConfig;
  private kycProfileConfig;
  private kycProfileData;

  
  constructor(
    private api: DashboardAPIService,
    private config: DashboardConfigService,
    private util: UtilService,
    private root: RootModuleConfiguraion
  ) {}

  public getAlertConfiguration() {
    let rootConfig = this.root.getConfiguration();
    if(rootConfig.isExos){
      rootConfig.nn = false;
      rootConfig.profile = false;
      rootConfig.transactions = false;
    }
    let config = (({ profile, nn, nameScreening, transactions }) => ({ profile, nn , nameScreening, transactions }))(rootConfig);
    
    
    return config;
  }

  public getNNTableData() {
    if (this.nnTableData) {
      return Observable.of(this.nnTableData);
    } else {
      return this.api.getBriefNNnewsData();
    }
  }

  public getLazyLoadedNNData(pageNumber, pageSize) {
    return this.api.getLazyLoadedNNData(pageNumber + 1, pageSize);
  }

  public getFilteredNNTableData(params) {
    return this.api.getFilteredNNnewsData(params);
  }

  public getNNTableConfig() {
    if (this.nnTableConfig) {
      return Observable.of(this.nnTableConfig);
    } else {
      return this.config.getBriefNNConfig();
    }
  }

  public getKycProfileConfig() {
    if (this.kycProfileConfig) {
      return Observable.of(this.kycProfileConfig);
    } else {
      return this.config.getKycProfileConfig();
    }
  }

  public getKycProfileData() {
    if (this.kycProfileData) {
      return Observable.of(this.kycProfileData);
    } else {
      return this.api.getKycProfileData();
    }
  }

  public setnnTableData(nnData) {
    this.nnTableData = nnData;
  }

  public setnnTableConfig(nnConfig) {
    this.nnTableConfig = nnConfig;
  }

  public setKycProfileConfig(kycConfig) {
    this.kycProfileConfig = kycConfig;
  }

  public setAlertDashboardInfo(info) {
    this.alertDashboardInfo = info;
  }

  public getAlertDashboardInfo() {
    return this.alertDashboardInfo;
  }

  public getCountsOfAlerts() {
    let countObj: {} = {};
    if (this.alertDashboardInfo) {
      let kycProfileCount =
        this.alertDashboardInfo.kycProfileAlert.materialCount + this.alertDashboardInfo.kycProfileAlert.administartiveCount;
      let sanctionsCount =
        this.alertDashboardInfo.sanctionAlert.previousPendingClient +
        this.alertDashboardInfo.sanctionAlert.currentDayPendingClient;

      let nnCount = 0;
      if (this.alertDashboardInfo.negativeNewsAlert) {
        nnCount =
          this.alertDashboardInfo.negativeNewsAlert.critical +
          this.alertDashboardInfo.negativeNewsAlert.investigative +
          this.alertDashboardInfo.negativeNewsAlert.valuable +
          this.alertDashboardInfo.negativeNewsAlert.probative;
      }

      countObj[AC.KYC_PROFILE] = this.util.formatNumber(kycProfileCount);
      countObj[AC.SANCTIONS] = this.util.formatNumber(sanctionsCount);

      countObj[AC.NNEWS] = this.util.formatNumber(nnCount);
      countObj[AC.TRANSACTIONS] = 0;
    }
    return countObj;
  }

  public displayProdModules(config): ModuleData {
    let moduleData: ModuleData = {
      profile: { display: false },
      nn: { display: false },
      transactions: { display: false },
      nameScreening: { display: false },
    };
    let keys = Object.keys(config);
    for (let key of keys) {
      if (config[key]) {
        moduleData[key] = {
          key: key,
          display: true,
          disable: false,
        };
      } else {
        moduleData[key] = {
          key: key,
          display: false,
          disable: true,
        };
      }
    }
    return moduleData;
  }

  public displayDemoModules(config): ModuleData {
    let moduleData: ModuleData = {
      profile: { display: false },
      nn: { display: false },
      transactions: { display: false },
      nameScreening: { display: false },
    };
    let keys = Object.keys(config);
    for (let key of keys) {
      if (config[key]) {
        moduleData[key] = {
          key: key,
          display: true,
          disable: false,
        };
      } else {
        moduleData[key] = {
          key: key,
          display: true,
          disable: true,
        };
      }
    }
    return moduleData;
  }
}
