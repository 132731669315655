import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { timer } from 'rxjs';

@Component({
  selector: 'kyc-tick',
  templateUrl: './kyc-tick.component.html',
  styleUrls: ['./kyc-tick.component.scss']
})
export class KycTickComponent implements OnInit, OnDestroy {
  @Output() triggerCaptcha: EventEmitter<any> = new EventEmitter();
  @Output() triggerCaptchaSource: EventEmitter<any> = new EventEmitter();
  @Input()
  _data: any;
  @Input()
  get data(): any {
    return this._data;
  }
  set data(val: any) {
    this._data = val;
    if (this.data) {
      this.initalizeTimer();
    }
  }

  @Input()
  runId: any;
  minutes: any;
  seconds = 0;
  subscription: any;
  source: any;

  constructor() {
    this.subscription = undefined;
  }

  ngOnInit() {}

  initalizeTimer() {
    if (this.data.timer > 0) {
      this.calculateTick();
      if (this.subscription === undefined) {
        this.subscription = timer(1000, 1000).subscribe(t => {
          this.data.timer = this.data.timer - 1;
          this.calculateTick();
        });
      }
    } else {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
  }

  calculateTick() {
    if (this.data) {
      this.minutes = this.data.timer / 60;
      this.seconds = this.data.timer % 60;
      this.minutes = parseInt(this.minutes, 10);
    }
  }

  public openCaptcha() {
    event.stopPropagation();
    this.triggerCaptcha.emit([this.data, this.runId]);
  }

  public skipCaptchaSource() {
    event.stopPropagation();
    this.triggerCaptchaSource.emit([this.data, this.runId]);
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
