import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appEventListeners]'
})
export class EventListenersDirective {
  @Output()
  keyboardEventListener: EventEmitter<any> = new EventEmitter();

  constructor() {}

  @HostListener('document:keydown', ['$event'])
  onkeydown(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.keyboardEventListener.emit(event);
    }
  }
}
