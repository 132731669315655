import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalSubscriptionService {
  constructor() {}

  tabChangedSubject = new Subject();
  completedTabDataSubject = new Subject();
  updateAlertCount = new Subject();
  reportTab = new Subject();
  completedJobRead = new Subject();
}
