import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Network, Node, Edge } from 'vis-network';
import { DataSet } from 'vis-data/peer/esm/vis-data';
import { ReportsAPIService } from 'src/app/reports/services/report.api.service';

declare var $: any;

@Component({
  selector: 'kyc-cluster',
  templateUrl: './cluster.component.html',
  styleUrls: ['./cluster.component.scss'],
})
export class ClusterComponent implements OnInit {
  // canvasHeight = 2000;
  // canvasWidth = 4000;
  // tableView: boolean = true;
  @Input() firstLevelOnly: boolean = false;
  @Input()
  ownerships: any;
  //tableConfig: any;
  //stickyTableHeaderClass: String;
  isNoData: boolean = true;
  clientId;
  private uniqSources: string[] = [];
  dropdownValues: { name: string }[] = [];
  selectedSource: { name: string } = { name: '' };
  filteredRecords: any = [];
  preferenceMatrix = ['BVD', 'D&B'];

  constructor(private api: ReportsAPIService) {}

  ngOnInit() {
    // this.getOwnershipData();
  }

  @Input() get _ownerships(): any {
    return this.ownerships;
  }
  set _ownerships(value: any) {
    this.ownerships = value ? value.data : undefined;
    if (this.ownerships) {
      this.ownerships = this.sortByKey(this.ownerships, 'hierarchyLevel');
      if (this.ownerships && this.ownerships.length > 0) {
        this.processData();
        this.unparsedResponse = value;
      }
    }
  }

  sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  private getOwnershipData() {
    this.api.getMockOwnershipList(this.clientId).subscribe(
      (response: any) => {
        this.ownerships = this.sortByKey(response.data, 'hierarchyLevel');
        if (this.ownerships && this.ownerships.length > 0) {
          this.processData();
          this.unparsedResponse = response;
        }
      },
      (error) => {}
    );
  }

  private handleAmbiguosEnityType() {
    this.ownerships.forEach((el) => {
      if (el.entityType.toLowerCase() == 'individuals') {
        el.entityType = 'i';
      }
    });
  }

  private processData() {
    if (this.ownerships) {
      this.fetchUniqSources(this.ownerships);
      this.handleAmbiguosEnityType();
      this.selectedSource.name = this.dropdownValues[0].name;
      this.filterData();
    }
  }

  fetchUniqSources(data) {
    let localValues = [];
    this.uniqSources = [];
    for (let item of data) {
      if (!this.uniqSources.includes(item.sourceName)) {
        this.uniqSources.push(item.sourceName);
        localValues.push({ name: item.sourceName });
      }
    }
    localValues = this.sortValuesForPrefMatrix(localValues);
    this.dropdownValues = localValues;
  }

  sortValuesForPrefMatrix(localValues) {
    localValues.sort((a: any, b: any) => {
      return this.preferenceMatrix.indexOf(a.name) - this.preferenceMatrix.indexOf(b.name);
    });
    return localValues;
  }
  displayTable() {
    this.filteredRecords = this.ownerships.filter((item) => {
      return item.sourceName == this.selectedSource.name && item.entityType.toLowerCase() != 'i';
    });
  }
  filterData() {
    this.isNoData = true;

    this.drawCluster();

    if (this.filteredRecords.length > 0) {
      this.isNoData = false;
    }
  }

  public onSourceChange(event) {
    this.filterData();
  }

  //OWNERSHIP STRUCTURE CODE
  public nodes: Node;
  public edges: Edge;
  public network: Network;
  public dataURL: string;
  public jsCtx;
  public unparsedResponse;
  nodeArray = [];
  edgeArray = [];
  options = {
    interaction: {
      zoomView: false,
      dragView: false,
    },
    physics: {
      repulsion: {
        springLength: 1000,
        nodeDistance: 1000,
      },
      stabilization: true,
    },

    nodes: {
      shape: 'dot',
      scaling: {
        customScalingFunction: function (min, max, total, value) {
          return value / total;
        },
        min: 5,
        max: 15,
      },
      size: 18,
      font: {
        size: 12,
        color: '#000000',
      },
      borderWidth: 2,
    },
    edges: {
      width: 1.25,
      //length: 200,
      // color: '#a6a6a6',
    },
  };

  alreadyExists(entityId) {
    let numberOfRecords = this.nodeArray.filter((item) => item.id == entityId).length;
    if (numberOfRecords > 0) {
      return true;
    }
    return false;
  }

  getTitle(entityId, parentId) {
    let itemsTitle = this.unparsedResponse.data.filter((item) => item.entityId == entityId && item.parentId == parentId)[0]
      .realOwnership;

    return itemsTitle;
  }

  parseResponseForNodeData(data) {
    this.nodeArray = [];
    for (let i = 0; i < data.length; i++) {
      let entityId = data[i].entityId;
      if (this.alreadyExists(entityId)) {
        continue;
      }
      let entityName = data[i].entityName;
      let modifiedEntityName = this.addLineBreak(entityName);
      let nodeObject = {
        id: data[i].entityId,
        label: modifiedEntityName,
        tempTitle: `<div> ${data[i].realOwnership} </div>`, //vis-tooltip class
        parents: [],
        entityType: data[i].entityType,
        shape: 'image',
      };
      for (let j = 0; j < data.length; j++) {
        if (data[j].entityId == entityId) {
          if (data[j].parentId == 'N/A') {
            continue;
          }
          nodeObject.parents.push(data[j].parentId);
        }
      }
      // nodeObject['group'] = this.getGroup(nodeObject, i);
      nodeObject['image'] = this.getImage(nodeObject, i);
      if (i == 0) {
        nodeObject['size'] = 25;
      }
      this.nodeArray.push(nodeObject);
    }
  }

  addLineBreak(str) {
    return str.replace(/(\S+\s*){1,2}/g, '$&\n');
  }

  getImage(nodeObject, index) {
    let path = '/assets/img/';
    if (index == 0) {
      return path + 'root-node.png';
    } else if (nodeObject.parents.length == 0 && nodeObject.entityType.toLowerCase() != 'i') {
      return path + 'company-node.png';
    } else if (nodeObject.entityType.toLowerCase() == 'i') {
      return path + 'individual-node.png';
    } else {
      return path + 'company-node-hover.png';
    }
  }
  initializeCluster() {
    this.nodes = new DataSet([this.nodeArray[0]]);
    this.edges = new DataSet([]);
    let container = document.getElementById('mynetwork');
    let data = {
      nodes: this.nodes,
      edges: this.edges,
    };

    this.network = new Network(container, data, this.options);
    this.drawparents(this.nodeArray[0].id);
    this.focusOnRoot();
    this.network.on('afterDrawing', (ctx) => {
      this.jsCtx = ctx;
    });

    this.network.on('click', (data) => {
      if (!this.firstLevelOnly && data.nodes.length > 0) {
        let selectedNodeId = data['nodes'][0];
        this.drawparents(selectedNodeId);
      }
    });
  }

  // expandAll() {
  //   for (let i = 1; i < this.nodeArray.length; i++) {
  //     this.drawparents(this.nodeArray[i].id);
  //   }
  //   //this.focusOnRoot();
  // }

  focusOnRoot() {
    setTimeout(() => {
      let root = this.network.getPosition(this.nodeArray[0].id);
      let domRoot = this.network.canvasToDOM(root);
      let divHeight = $('.scrollable').height() / 2;
      let divWidth = $('.scrollable').width() / 2;
      $('.scrollable').animate({ scrollTop: domRoot.y - divHeight, scrollLeft: domRoot.x - divWidth }, { duration: 200 });
    }, 0);
  }

  collapseAll() {
    this.initializeCluster();
    //this.focusOnRoot();
  }

  getparentsIds(nodeId) {
    let selectedNode = this.nodeArray.filter((obj) => {
      return obj.id == nodeId;
    });
    return selectedNode[0].parents;
  }

  drawparents(nodeId) {
    let parentNodeIds = this.getparentsIds(nodeId);
    this.createparentNodes(nodeId, parentNodeIds);
  }

  createparentNodes(nodeId, parentNodeIds) {
    let parentNodes = this.nodeArray.filter((obj) => {
      return parentNodeIds && parentNodeIds.includes(obj.id);
    });

    for (let i in parentNodes) {
      if (!this.nodeExists(parentNodes[i])) {
        this.nodes.add(parentNodes[i]);
      }
    }
    for (let i in parentNodes) {
      let localEdge = {
        from: nodeId,
        to: parentNodes[i].id,
        arrows: { to: { enabled: true } },
        // color: parentNodes[i].parents.length > 0 ? '#2048aa' : '#a6a6a6',
        arrowStrikethrough: false,
        color: {
          color: parentNodes[i].parents.length > 0 ? '#4070e4' : '#a6a6a6',
          highlight: '#2048aa',
          hover: '#2048aa',
          // inherit: 'from',
          // opacity:1.0
        },
      };
      if (!this.edgeExists(localEdge)) {
        let oldTitle = this.nodes.get(parentNodes[i].id).title;
        if (!oldTitle) {
          oldTitle = '';
        }
        let itemTitle = this.getTitle(nodeId, parentNodes[i].id);

        this.nodes.update({ id: parentNodes[i].id, title: oldTitle + '\n' + itemTitle });
        //localEdge['label'] = this.getTitle(nodeId, parentNodes[i].id);
        this.edges.add(localEdge);
      }
    }
    this.network.stabilize();

    // if (parentNodes.length > 0) {
    //   setTimeout(() => {
    //     this.canvasHeight = this.canvasHeight + 100;
    //     //this.network.focus(nodeId, { scale: 1 });
    //   }, 500);
    // }
  }

  nodeExists(localNode) {
    let nodeData = this.network.body.nodeIndices;
    for (let i in nodeData) {
      let nodeId = nodeData[i];

      if (localNode.id == nodeId) {
        console.log('node already exists');
        return true;
      }
    }
    return false;
  }
  edgeExists(localNewEdge) {
    let edgeData = this.network.body.edges;
    for (let i in edgeData) {
      let to = edgeData[i].toId;
      let from = edgeData[i].fromId;

      if (localNewEdge.from == from && localNewEdge.to == to) {
        console.log('edge already exists');
        return true;
      }
    }
    return false;
  }

  drawCluster() {
    setTimeout(() => {
      this.filteredRecords = this.ownerships.filter((item) => {
        return item.sourceName == this.selectedSource.name;
      });
      this.parseResponseForNodeData(this.filteredRecords);
      this.initializeCluster();
    }, 700);
  }
}
