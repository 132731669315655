import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SanctionDataService {
  public pendingClientsDataAvailable = new Subject();
  public completedClientsDataAvailable = new Subject();
  public completedClientsCount = new Subject();
  private completedClients = [];
  private pendingClients = [];
  pollingTime = 30000;
  
  
  clientName = new Subject();

  updateClientName(clientName){
    this.clientName = new Subject();
    this.clientName.next(clientName);
  }


  setPollingTiming(time) {
    this.pollingTime = time * 1000;
  }

  getPollingTime() {
    return this.pollingTime;
  }

  setPendingClients(pendingClients) {
    pendingClients.forEach((el) => {
      el.isUpdate = 0;
      el.entityCount = el.entityCount == 1 ? 'Y' : 'N';
    });
    this.pendingClients = pendingClients;
    this.pendingClientsDataAvailable.next(this.pendingClients);
  }
  getPendingClients() {
    return this.pendingClients;
  }
  setCompletedClients(completedClients) {
    completedClients.forEach((el) => {
      el.isUpdate = 0;
      el.entityCount = el.entityCount == 1 ? 'Y' : 'N';
      el.changeDateSanctionsCompleted = el.changeDate;
    });
    this.completedClients = completedClients;
    this.completedClientsDataAvailable.next(this.completedClients);
  }
  getCompletedClients() {
    return this.completedClients;
  }
}
