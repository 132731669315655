import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClusterComponent } from './cluster/cluster.component';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [ClusterComponent],
  exports: [ClusterComponent],
  imports: [CommonModule],
})
export class OwnershipClusterModule {}
