import { Injectable } from '@angular/core';

declare var $: any;
declare var window: any;

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor() {}

  getApplicationBaseHref() {
    return window.location.pathname;
  }

  getStickyHeaderClass(page: string, pageModule: string, sClass: any) {
    let class1 = '';
    let class2 = '';

    switch (page) {
      case 'CLIENT_LIST':
        {
          // class1 = 'sticky-header';
          // class2 = 'sticky-table-header';
        }
        break;
      case 'JOBS_IN_PROGRESS':
      case 'COMPLETED_JOBS':
        {
          // class1 = 'sticky-table-header sticky-job-header';
        }
        break;
      case 'DASHBOARD_CONTAINER':
        {
          // class1 = 'sticky-tabs-panel';
        }
        break;
      case 'REPORT_CONTAINER':
        {
          // class1 = 'sticky-tabs';
        }
        break;
      case 'KEY_CONTROLLER':
      case 'OWNERSHIP':
      case 'SHARE_HOLDER':
        {
          // class1 = 'sticky-table-header';
        }
        break;
    }

    let t = 0;
    let hh = 130;
    if (pageModule === 'REPORT') {
      hh = 60;
    } else {
      t = $('.ui-tabview-nav').first().outerHeight();
      if (typeof t === 'number') {
        if (sClass.sticky1 === class1) {
          hh = t + 60;
        } else {
          hh = t + 60 + 47;
        }
      }
    }

    if (window.pageYOffset > hh) {
      sClass.sticky1 = class1;
      sClass.sticky2 = class2;
    } else {
      sClass.sticky1 = '';
      sClass.sticky2 = '';
    }
    return sClass;
  }

  dummyClickForIE() {
    setTimeout(() => {
      //   const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
      //   if(isIEOrEdge){
      //    // $('#search-input-id').focus();
      //    // $('#search-input-id').blur();
      //   }
    }, 1000);
  }

  replaceWierdCharacters(data: string) {
    let newdata = data
      .replace(/&amp;/g, '&')
      .replace(/&nbsp;/g, '')
      .replace(/â/g, '')
      .replace(/€/g, '')
      .replace(/™/g, '')
      .replace(/Ã/g, '')
      .replace(/©/g, '')
      .replace(/œ/g, '')
      .replace(/&AMP;/g, '')
      .replace(/Ã©/g, '');
    return newdata;
  }

  isIE() {
    return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  }

  handleNoData(val) {
    if (val && val.trim() != '') {
      return val;
    }
    return 'N/A';
  }

  getFileName(contentDispo) {
    let fileName = 'file';
    if (contentDispo && contentDispo.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(contentDispo);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    return fileName;
  }

  formatNumber(number): string {
    if (number == 0) {
      return '00';
    }
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

  getAlertTypeTitles() {
    return {
      obs: 'Other Bodies',
      let: 'Law Enforcement',
      ret: 'Regulatory Enforcement',
      pep: 'Politically Exposed Person',
      san: 'Sanctions',
    };
  }
}
