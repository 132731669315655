import { Injectable } from '@angular/core';
import { HTTPAPIService } from 'src/app/common/http.api.service';
import { environment } from 'src/environments/environment.prod';
import { map, tap } from 'rxjs/operators';

import { DASHBOARD_API_CONSTANT } from '../constants/dashboard.api.constant';
declare var _: any;

@Injectable({
  providedIn: 'root',
})
export class DashboardAPIService {
  constructor(private http: HTTPAPIService) {}

  getClientList() {
    const url = DASHBOARD_API_CONSTANT.CLIENT_LIST;
    return this.http.get(url);
  }

  getFilteredClientList(params) {
    const url = DASHBOARD_API_CONSTANT.CLIENT_LIST + '?filterParams=' + JSON.stringify(params);
    return this.http.get(url);
  }

  getFilteredCompletedJobList(params) {
    const url = DASHBOARD_API_CONSTANT.FILTER_COMPLETED_JOB_LIST + JSON.stringify(params);
    return this.http.get(url);
  }

  getSourcesList() {
    const url = DASHBOARD_API_CONSTANT.SOURCES;
    return this.http.get(url);
  }

  getCoutryOperationList() {
    const url = DASHBOARD_API_CONSTANT.COUNTRY_OPERATION_LIST;
    return this.http.get(url);
  }

  getCoutryIncorporationList() {
    const url = DASHBOARD_API_CONSTANT.COUNTRY_INCORPORATION_LIST;
    return this.http.get(url);
  }

  getClientTypeList() {
    const url = DASHBOARD_API_CONSTANT.CLIENT_TYPE_LIST;
    return this.http.get(url);
  }

  getHierarchyLevelist() {
    const url = DASHBOARD_API_CONSTANT.HIERARCHY_LEVEL_LIST;
    return this.http.get(url);
  }

  addSingleClient(clients) {
    const url = DASHBOARD_API_CONSTANT.ADD_SINGLE_CLIENT;
    return this.http.post(url, clients);
  }

  addSingleClientWithJob(clients) {
    const url = DASHBOARD_API_CONSTANT.ADD_SINGLE_CLIENT_WITH_JOB;
    return this.http.post(url, clients);
  }


  addBulkClients(clients) {
    const url = DASHBOARD_API_CONSTANT.UPLOAD_CLIENTS;
    return this.http.post(url, clients);
  }

  addBulkIndividuals(clients) {
    const url = DASHBOARD_API_CONSTANT.UPLOAD_INDIVIDUALS;
    return this.http.post(url, clients);
  }

  createJobForClients(jobData) {
    const url = DASHBOARD_API_CONSTANT.CREATE_JOB;
    return this.http.post(url, jobData);
  }

  getSourceSession(sourceId) {
    const url = DASHBOARD_API_CONSTANT.GET_SOURCE_SESSION + '/' + sourceId;
    return this.http.get(url);
  }

  addSourceSession(sourceInfo) {
    const url = DASHBOARD_API_CONSTANT.ADD_SOURCE_SESSION;
    return this.http.post(url, sourceInfo);
  }

  updateSourceSession(sourceId, sourceInfo) {
    const url = DASHBOARD_API_CONSTANT.UPDATE_SOURCE_SESSION + '/' + sourceId;
    return this.http.put(url, sourceInfo);
  }

  deleteSourceSession(sourceId) {
    const url = DASHBOARD_API_CONSTANT.DELETE_SOURCE_SESSION + '/' + sourceId;
    return this.http.delete(url);
  }

  deleteClients(params) {
    const url = DASHBOARD_API_CONSTANT.DELETE_CLIENTS + params;
    return this.http.delete(url);
  }

  deleteAllClients() {
    const url = DASHBOARD_API_CONSTANT.DELETE_ALL_CLIENT;
    return this.http.delete(url);
  }

  getJobList() {
    const url = DASHBOARD_API_CONSTANT.JOB_LIST;
    return this.http.get(url);
  }

  getCompletedJobList() {
    const url = DASHBOARD_API_CONSTANT.COMPLETED_JOB_LIST;
    return this.http.get(url);
  }

  getJobClientList(runId) {
    // const url = DASHBOARD_API_CONSTANT.JOB_CLIENT_LIST; // + '/' + runId;
    const url = DASHBOARD_API_CONSTANT.JOB_CLIENT_LIST + '/' + runId;
    return this.http.get(url);
  }

  getJobSourceList(jobId) {
    // const url = DASHBOARD_API_CONSTANT.JOB_SOURCE_LIST; // + '/' + jobId;
    const url = DASHBOARD_API_CONSTANT.JOB_SOURCE_LIST + '/' + jobId;
    return this.http.get(url);
  }

  deleteJobs(params) {
    const url = DASHBOARD_API_CONSTANT.DELETE_JOBS + params;
    return this.http.delete(url);
  }

  updateJobDetails(runId, jobInfo) {
    const url = DASHBOARD_API_CONSTANT.UPDATE_JOB + '/' + runId;
    return this.http.put(url, jobInfo);
  }

  getCompletedJobClientList() {
    const url = DASHBOARD_API_CONSTANT.COMPLETED_JOB_CLIENT_LIST;
    return this.http.get(url);
  }

  getCompletedJobSourceList() {
    const url = DASHBOARD_API_CONSTANT.COMPLETED_JOB_SOURCE_LIST;
    return this.http.get(url);
  }

  getQuestionsAndAnswersList(clientId) {
    const url = DASHBOARD_API_CONSTANT.QUESTIONS_AND_ANSWERS_LIST + clientId;
    return this.http.get(url);
  }

  pauseJob(runId, jobInfo) {
    const url = DASHBOARD_API_CONSTANT.PAUSE_JOB + '/' + runId;
    return this.http.put(url, jobInfo);
  }

  resumeJob(runId, jobInfo) {
    const url = DASHBOARD_API_CONSTANT.RESUME_JOB + '/' + runId;
    return this.http.put(url, jobInfo);
  }

  getCaptchaRequired(jRunId: any) {
    let lurl = '';
    _.each(jRunId, function (a, index) {
      lurl = lurl + a;
      if (index < jRunId.length - 1) {
        lurl = lurl + ',';
      }
    });
    const url = DASHBOARD_API_CONSTANT.JIP_NOTIFICATION_PULL + lurl;
    // const url = DASHBOARD_API_CONSTANT.JIP_NOTIFICATION_PULL;
    return this.http.get(url);
  }

  captchaLogin(url) {
    return this.http.post(url, {});
  }

  getProxySourceCaptchaUrl() {
    const url = environment.API_BASE + DASHBOARD_API_CONSTANT.JIP_SOURCE_PROXY_CAPTCHA;
    return url;
  }

  getSourceCaptchaSubmitUrl() {
    const url = DASHBOARD_API_CONSTANT.JIP_SOURCE_CAPTCHA_SUBMIT;
    return url;
  }

  getListMultipleResult(clientInfo) {
    const url = DASHBOARD_API_CONSTANT.LIST_MULTIPLE_RESULT + clientInfo.clientId;
    return this.http.get(url);
  }

  updateMultipleResult(sourceInfo) {
    const url = DASHBOARD_API_CONSTANT.UPDATE_MULTIPLE_RESULT;
    return this.http.post(url, sourceInfo);
  }

  skipSource(sourceInfo) {
    const url = DASHBOARD_API_CONSTANT.SKIP_SOURCE + '/' + sourceInfo.serviceTaskId;
    return this.http.put(url, sourceInfo);
  }

  skipMultipleSources(sourceInfo) {
    const url = DASHBOARD_API_CONSTANT.SKIP_MUTIPLE_SOURCE;
    return this.http.put(url, sourceInfo);
  }

  getLicensingInfo() {
    const url = DASHBOARD_API_CONSTANT.LICENSE_INFO;
    return this.http.get(url);
  }

  skipCaptchaSource(sourceInfo, runId) {
    const url = DASHBOARD_API_CONSTANT.SKIP_CATPCHA_SOURCE + '/' + runId + '/' + sourceInfo.sourceId;
    return this.http.put(url, sourceInfo);
  }

  retrySource(clientId) {
    const url = DASHBOARD_API_CONSTANT.RETRY_SOURCES + '/' + clientId;
    return this.http.put(url, clientId);
  }

  discardJobOrClients(data) {
    const url = DASHBOARD_API_CONSTANT.DISCARD_JOB;
    return this.http.put(url, data);
  }

  logOut() {
    const url = DASHBOARD_API_CONSTANT.LOGOUT;
    return this.http.delete(url);
  }

  getBriefNNnewsData() {
    const url = DASHBOARD_API_CONSTANT.BRIEF_NN_NEWS_DATA;
    return this.http.get(url);
  }

  getLazyLoadedNNData(pageNumber, pageSize) {
    const url = DASHBOARD_API_CONSTANT.LAZY_LOAD_NN_DATA + '?pageSize=' + pageSize + '&pageNumber=' + pageNumber;
    return this.http.get(url);
  }

  getFilteredNNnewsData(params) {
    const url = DASHBOARD_API_CONSTANT.FILTERED_NN_NEWS_DATA + '?filterParams=' + JSON.stringify(params);
    return this.http.get(url);
  }

  getFilteredProfileData(params) {
    const url = DASHBOARD_API_CONSTANT.FILTERED_KYC_PROFILE + '?filterParams=' + JSON.stringify(params);
    return this.http.get(url);
  }

  getNNArticlesData(clientId) {
    const url = DASHBOARD_API_CONSTANT.DETAILED_NN_NEWS_DATA + '/' + clientId;
    return this.http.get(url);
  }

  getClientInfoAlerts(clientId) {
    const url = DASHBOARD_API_CONSTANT.CLIENT_INFO_ALERT + '/' + clientId;
    return this.http.get(url);
  }

  getAlertDashboardParameters() {
    const url = DASHBOARD_API_CONSTANT.ALERT_DASHBOARD_PARAMS;
    return this.http.get(url);
  }

  updateStarredStatus(payload) {
    const url = DASHBOARD_API_CONSTANT.UPDATE_STARRED_STATUS;
    return this.http.put(url, payload);
  }

  discountArtcile(payload) {
    const url = DASHBOARD_API_CONSTANT.DISCOUNT_NN_ARTICLE;
    return this.http.put(url, payload);
  }

  escalateArticle(payload) {
    const url = DASHBOARD_API_CONSTANT.ESCALATE_ARTICLE;
    return this.http.put(url, payload);
  }

  updateArticleFindings(payload) {
    const url = DASHBOARD_API_CONSTANT.UPDATE_ARTICLE_FINDINGS;
    return this.http.put(url, payload);
  }

  getFindingsDropDownReasons() {
    const url = DASHBOARD_API_CONSTANT.GET_FINDINGS_DROPDOWN_VALUES;
    return this.http.get(url);
  }

  getDiscountDropDownReasons() {
    const url = DASHBOARD_API_CONSTANT.GET_DISCOUNT_DROPDOWN_VALUES;
    return this.http.get(url);
  }

  getKycProfileData() {
    const url = DASHBOARD_API_CONSTANT.KYC_PROFILE_DATA;
    return this.http.get(url);
  }

  getDetailedKycProfile(clientId) {
    const url = DASHBOARD_API_CONSTANT.DETAILED_KYC_PROFILE + '/' + clientId;
    return this.http.get(url);
  }

  updateReadStatus(payload) {
    const url = DASHBOARD_API_CONSTANT.UPDATE_READ_STATUS;
    return this.http.put(url, payload);
  }

  updateNNReadStatus(payload) {
    const url = DASHBOARD_API_CONSTANT.UPDATE_NN_READ_STATUS;
    return this.http.put(url, payload);
  }

  updateStarredStatusForProfile(payload) {
    const url = DASHBOARD_API_CONSTANT.UPDATE_STARRED_STATUS_PROFILE;
    return this.http.put(url, payload);
  }

  getRDCNNData() {
    const url = DASHBOARD_API_CONSTANT.RDC_NN_DATA;
    return this.http.get(url);
  }

  getRDCNNDeatiledData(clientId) {
    const url = DASHBOARD_API_CONSTANT.GET_CLIENT_ENTITIES + '/' + clientId;
    return this.http.get(url);
  }

  getEventDetail(eventId) {
    const url = DASHBOARD_API_CONSTANT.GET_EVENT_DETAIL + '/' + eventId;
    return this.http.get(url);
  }

  getEntityDetail(entityId) {
    const url = DASHBOARD_API_CONSTANT.GET_ENTITY_DETAIL + '/' + entityId;
    return this.http.get(url);
  }

  getPartyDetail(partyId) {
    const url = DASHBOARD_API_CONSTANT.GET_PARTY_DETAIL + '/' + partyId;
    return this.http.get(url);
  }

  nnSubmitActionData(formData) {
    const url = DASHBOARD_API_CONSTANT.NN_ACTION_TAKEN;
    return this.http.post(url, formData);
  }

  nnGetSavedData(params) {
    const url = DASHBOARD_API_CONSTANT.GET_SAVED_NN_ACTION + '?data=' + JSON.stringify(params);
    return this.http.get(url);
  }

  nnSubmitGlobalActionData(formData) {
    const url = DASHBOARD_API_CONSTANT.NN_GLOBAL_ACTION_TAKEN;
    return this.http.post(url, formData);
  }

  nnDeleteAttachment(params) {
    const url = DASHBOARD_API_CONSTANT.NN_DELETE_ATTACHMENT + '?data=' + JSON.stringify(params);
    return this.http.delete(url);
  }

  updateReadStatusOfJob(runId) {
    const url = DASHBOARD_API_CONSTANT.UPDATE_READSTATUS_JOB + '/' + runId;
    return this.http.put(url, '');
  }

  updateReadStatusOfDuplicates(id) {
    const url = DASHBOARD_API_CONSTANT.UPDATE_DUPLICATE_READ_STATUS + '/' + id;
    return this.http.put(url, '');
  }

  updateRedaStatusOfPendingSnactions(id) {
    const url = DASHBOARD_API_CONSTANT.SANCTION_READ_STATUS + '/' + id;
    return this.http.put(url, '');
  }

  getClientProfiles() {
    const url = DASHBOARD_API_CONSTANT.GET_CLIENT_PROFILES;
    return this.http.get(url);
  }

  pollForNewProfiles() {
    const url = DASHBOARD_API_CONSTANT.GET_CLIENT_PROFILES + '?toDate=' + new Date().toJSON().slice(0, 19).replace('T', ' ');
    return this.http.get(url);
  }

  getClientDuplicates() {
    const url = DASHBOARD_API_CONSTANT.GET_DUPLICATES;
    return this.http.get(url);
  }

  actionOnDuplicates(formData) {
    const url = DASHBOARD_API_CONSTANT.ACTION_ON_DUPLICATES;
    return this.http.post(url, formData);
  }

  profileRunStatus(runId) {
    const url = DASHBOARD_API_CONSTANT.PROFILE_RUN_STATUS + '/' + runId;
    return this.http.get(url);
  }

  getSanctionData() {
    const url = DASHBOARD_API_CONSTANT.GET_SANCTION_DATA;
    return this.http.get(url);
  }

  getSanctionLeftPane(id) {
    const url = DASHBOARD_API_CONSTANT.GET_SANCTION_LEFT_PANE + '?nameScreeningDashboardId=' + id;
    return this.http.get(url);
  }
  getSanctionClientsDetails(id) {
    const url = DASHBOARD_API_CONSTANT.GET_PENDING_CLIENT_DETAILS + '?id=' + id;
    return this.http.get(url);
  }

  saveSanctionData(data) {
    const url = DASHBOARD_API_CONSTANT.SAVE_SANCTION_DATA;
    return this.http.put(url, data);
  }

  submitPendingResData(id) {
    const url = DASHBOARD_API_CONSTANT.SUBMIT_PENDING_RESOLUTION + '/' + id;
    return this.http.put(url, { icompleted: true });
  }

  getSanctionClientItemDetail(id) {
    const url = DASHBOARD_API_CONSTANT.GET_SANCTION_CLIENT_ITEM_DETAILS + '?nameScreeningEntityDetailId=' + id;
    return this.http.get(url);
  }

  pollForUpdatesInSanctionStatus(processingItems) {
    const url = DASHBOARD_API_CONSTANT.POLL_SERVER_FOR_SANCTION_STATUS + '?entityIds=[' + processingItems + ']';
    return this.http.get(url);
  }

  updateMonitorStatus(id, updatedObj) {
    const url = DASHBOARD_API_CONSTANT.UPDATE_MONITOR_STATUS + '/' + id;
    return this.http.put(url, updatedObj);
  }

  availableModules() {
    const url = DASHBOARD_API_CONSTANT.PERMISSION_INFO;
    return this.http.get(url);
  }

}
