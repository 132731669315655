import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'randomArrayValue'
})
export class RandomArrayValue implements PipeTransform {
  transform(value: any, args: any): any {
    const classArray = ['yellow', 'pink', 'green', 'cobalt', 'purple'];
    const randomIndex = Math.floor(Math.random() * classArray.length);
    return classArray[randomIndex];
  }
}
