import { Component, OnInit } from '@angular/core';
import { ProgressBar } from 'primeng/primeng';

@Component({
  selector: 'kyc-progressbar',
  templateUrl: './kyc-progressbar.component.html',
  styleUrls: ['./kyc-progressbar.component.scss']
})
export class KycProgressbarComponent extends ProgressBar {

  constructor() { 
    super();
  }

  ngOnInit() {
  }

}
