export const DASHBOARD_CONFIG_CONSTANT = {
  CLIENT: 'assets/json-data/configs/clients.json',
  CLIENTS_FOR_JOB: 'assets/json-data/configs/clients-for-jobs.json',
  SELECTED_CLIENTS_FOR_JOB: 'assets/json-data/configs/selected-job-clients.json',
  MY_JOBS: 'assets/json-data/configs/my-jobs.json',
  EDIT_CLIENTS_FOR_JOB: 'assets/json-data/configs/edit-job.json',
  EDIT_SELECTED_CLIENTS_FOR_JOB: 'assets/json-data/configs/edit-selected-job-clients.json',
  COMPLETED_JOBS: 'assets/json-data/configs/completed-jobs.json',
  COMPLETED_JOB_CLIENTS: 'assets/json-data/configs/completed-job-clients.json',
  JOBS_IN_PROGRESS_CLIENTS: 'assets/json-data/configs/jobs-in-progress-clients.json',
  KEYCONTROLLER_TAB_CONFIG: 'assets/json-data/configs/keyControllerTab.json',
  SHAREHOLDER_TAB_CONFIG: 'assets/json-data/configs/shareHoldersTab.json',
  OWNERSHIP_TAB_CONFIG: 'assets/json-data/configs/ownershipTab.json',
  ALL_ANS_CONFIG: 'assets/json-data/configs/allAnswers.json',
  CHOOSE_SOURCE_CONFIG: 'assets/json-data/configs/choose-source.json',
  CHOOSE_SOURCE_DNB_CONFIG: 'assets/json-data/configs/choose-source-DnB.json',
  CHOOSE_SOURCE_BvD_CONFIG: 'assets/json-data/configs/choose-source-BVD.json',
  CHOOSE_SOURCE_LEI_CONFIG: 'assets/json-data/configs/choose-source-LEI.json',
  SELECTED_SOURCE_CONFIG: 'assets/json-data/configs/selected-source.json',
  SELECTED_SOURCE_DNB_CONFIG: 'assets/json-data/configs/selected-source-DnB.json',
  NN_BRIEF_TABLE: 'assets/json-data/configs/nnews.json',
  KYC_PROFILE_TABLE: 'assets/json-data/configs/kycprofile.json',
  SELECTED_SOURCE_BVD_CONFIG: 'assets/json-data/configs/selected-source-BVD.json',
  SELECTED_SOURCE_LEI_CONFIG: 'assets/json-data/configs/selected-source-LEI.json',
  CLIENT_PROFILE: 'assets/json-data/configs/clientProfile.json',
  DUPLICATE_CONFIG: 'assets/json-data/configs/duplicates.json',
  NESTED_DUPLICATE_CONFIG: 'assets/json-data/configs/duplicates-inner.json',
  SANCTION_PENDING_CLIENT: 'assets/json-data/configs/sanctionsPendingClients.json',
  SANCTION_COMPLETED_CLIENT: 'assets/json-data/configs/sanctionsCompletedClients.json',
};

export const URL_PATTERN = {
  alerts: '/dashboard/alerts',
  admin: '/dashboard/admin',
};
