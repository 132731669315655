import { DashboardAPIService } from 'src/app/dashboard/services/dashboard.api.service';
import { DashboardConfigService } from 'src/app/dashboard/services/dashboard.config.service';
import { DynamicDialogConfig, DynamicDialogRef, DialogService } from 'primeng/api';
import { Component, OnInit } from '@angular/core';
import { SelectedSourceOptionsComponent } from '../selected-source-options/selected-source-options.component';

declare var _: any;
@Component({
  selector: 'kyc-choose-source',
  templateUrl: './choose-source.component.html',
  styleUrls: ['./choose-source.component.scss']
})
export class ChooseSourceComponent implements OnInit {
  constructor(
    public config: DynamicDialogConfig,
    public dashConfig: DashboardConfigService,
    public dashAPI: DashboardAPIService,
    private ref: DynamicDialogRef,
    public dialogService: DialogService
  ) {}
  clientInfo: any;
  tableConfig: {} = {};
  tableConfigDnB: {} = {};
  tableConfigBvD: {} = {};
  tableConfigLei: {} = {};
  sourceList: any;
  sourceListInfo: any;
  selectedSourceInfo = { sources: [], skipedSources: [] };
  cancelFromSelectedSourcePopup: Boolean;
  step = 1;
  selectedSourceList: any;
  groupBySelectedSource: any;
  selectedSourceTableConfig: {} = {};
  selectedSourceDnBTableConfig: {} = {};
  selectedSourceBvDTableConfig: {} = {};
  selectedSourceLeiTableConfig: {} = {};
  skipedSources1: string[] = [];
  contentMessage: string;
  
  ngOnInit() {
    this.clearAll();
    this.clientInfo = this.config.data.clientInfo;
    this.getConfigAsPerSource();
    this.dashAPI.getListMultipleResult(this.clientInfo).subscribe(
      (response: any) => {
        if (response.resultList) {
          this.sourceListInfo = _.groupBy(response.resultList, 'sourceName');
          this.sourceList = _.uniq(_.pluck(response.resultList, 'sourceName'));
        }
      },
      error => {}
    );
    this.sourceListInfo = this.getSortDataByMatchScore(this.sourceListInfo);
  }
  getSortDataByMatchScore(sourceListInfo){
    let sortedStudents = sourceListInfo.sort((a,b) => (a.matchPercentage < b.matchPercentage) ? -1 : ((b.matchPercentage > a.matchPercentage) ? 1 : 0))
  }
  getConfigAsPerSource() {
    this.dashConfig.getChooseSourceConfig().subscribe(
      (response: any) => {
        this.tableConfig = response.components.dataTable;
      },
      error => {}
    );
    this.dashConfig.getChooseSourceDnBConfig().subscribe(
      (response: any) => {
        this.tableConfigDnB = response.components.dataTable;
      },
      error => {}
    );

    this.dashConfig.getChooseSourceBvDConfig().subscribe(
      (response: any) => {
        this.tableConfigBvD = response.components.dataTable;
      },
      error => {}
    );
    this.dashConfig.getChooseSourceLeiConfig().subscribe(
      (response: any) => {
        this.tableConfigLei = response.components.dataTable;
      },
      error => {}
    );
  }

  closeChooseSourcePopup() {
    this.ref.close();
  }

  submitSelectedSource() {
    this.step = 2;
    this.selectedSourceList = _.uniq(_.pluck(this.selectedSourceInfo['sources'], 'sourceName'));
    this.groupBySelectedSource = _.groupBy(this.selectedSourceInfo['sources'], 'sourceName');
    this.dashConfig.getSelectedSourceConfig().subscribe(
      (response: any) => {
        this.selectedSourceTableConfig = response.components.dataTable;
      },
      error => {}
    );
    this.dashConfig.getSelectedSourceDnBConfig().subscribe(
      (response: any) => {
        this.selectedSourceDnBTableConfig = response.components.dataTable;
      },
      error => {}
    );

    this.dashConfig.getSelectedSourceBvDConfig().subscribe(
      (response: any) => {
        this.selectedSourceBvDTableConfig = response.components.dataTable;
      },
      error => {}
    );
    this.dashConfig.getSelectedSourceLeiConfig().subscribe(
      (response: any) => {
        this.selectedSourceLeiTableConfig = response.components.dataTable;
      },
      error => {}
    );
    
  }

  addSelectedSource(event) {
    const sourceExist = this.selectedSourceInfo['sources'].filter(source => {
      return source.sourceName === event.sourceName;
    });
    if (sourceExist.length === 0) {
      this.selectedSourceInfo['sources'].push(event);
    } else {
      this.sourceListInfo[event.sourceName].forEach((source, index) => {
        if (source.resultId === event.resultId) {
          source.checked = event.checked;
        } else {
          source.checked = 'false';
        }
      });

      this.selectedSourceInfo['sources'].forEach((source, index) => {
        if (source.sourceName === event.sourceName) {
          this.selectedSourceInfo['sources'][index] = event;
        }
      });
    }
    /// on radio button select, deselect checkbox
    this.selectedSourceInfo['skipedSources'] = this.selectedSourceInfo['skipedSources'].filter(item => {
      return item !== event.sourceName;
    });
  }

  onCheckboxSelected(event, source) {
    /// on checkbox select, deselect radio button
    this.selectedSourceInfo['sources'] = this.selectedSourceInfo['sources'].filter((src, index) => {
      return src.sourceName !== source;
    });
    this.sourceListInfo[source].forEach((src, index) => {
      this.sourceListInfo[source][index].checked = 'false';
    });
  }

  closeSelectedSourcePopup() {
    this.step = 1;
  }

  submitSelectedSourceOptions() {
    const sourceInfo = [];
    let skippedSourcesPayload = [];
    let unselectedData = [];

    //create array of selected sources
    this.selectedSourceInfo.sources.forEach(src => {
      sourceInfo.push({
        resultId: src.resultId,
        serviceTaskId: src.serviceTaskId,
        jobId: this.clientInfo.jobId,
        selected: true
      });
    });

    //add unselected with a flag also
    for (let i = 0; i < this.sourceList.length; i++) {
      let sourceName = this.sourceList[i];
      let serviceTaskId = this.sourceListInfo[sourceName][0].serviceTaskId;
      if (!this.checkIfSkipped(serviceTaskId)) {
        if (!this.checkIfSourceIsAlreadySelected(serviceTaskId)) {
          sourceInfo.push({
            resultId: this.sourceListInfo[sourceName][0].resultId,
            serviceTaskId: serviceTaskId,
            jobId: this.clientInfo.jobId,
            selected: false
          });
        }
      }
    }

    this.selectedSourceInfo.skipedSources.forEach(src => {
      if (this.sourceListInfo[src][0]) {
        const t = {
          serviceTaskId: this.sourceListInfo[src][0].serviceTaskId,
          multipleResults: true
        };
        skippedSourcesPayload.push(t);
      }
    });

    if (skippedSourcesPayload.length > 0) {
      this.dashAPI.skipMultipleSources(skippedSourcesPayload).subscribe(
        (response: any) => {
          console.log(response);
        },
        error => {}
      );
    }

    if (sourceInfo.length > 0 && this.checkIfSomeSourceSelected(sourceInfo)) {
      this.dashAPI.updateMultipleResult(sourceInfo).subscribe(
        (response: any) => {
          console.log(response);
        },
        error => {}
      );
    }

    this.step = 3;
    this.clientInfo.statusDetails = '';
    this.clientInfo.clientStatus = 'PROCESSING';

    this.contentMessage = 'Selected option has been submitted.';
    setTimeout(() => {
      this.ref.close({
        selectedSourceInfo: this.selectedSourceInfo,
        clientInfo: this.clientInfo
      });
    }, 2000);
  }

  checkIfSkipped(serviceTaskId) {
    for (let x = 0; x < this.selectedSourceInfo.skipedSources.length; x++) {
      if (serviceTaskId == this.sourceListInfo[this.selectedSourceInfo.skipedSources[x]][0].serviceTaskId) {
        return true;
      }
    }
    return false;
  }
  checkIfSourceIsAlreadySelected(serviceTaskId) {
    for (let x = 0; x < this.selectedSourceInfo.sources.length; x++) {
      if (serviceTaskId == this.selectedSourceInfo.sources[x].serviceTaskId) {
        return true;
      }
    }
    return false;
  }

  checkIfSomeSourceSelected(sourceInfo) {
    if (sourceInfo) {
      for (let i = 0; i < sourceInfo.length; i++) {
        if (sourceInfo[i].selected) {
          return true;
        }
      }
      return false;
    }
  }

  isAllSourcesDirty() {
    if (
      this.sourceList !== undefined &&
      (this.selectedSourceInfo['sources'].length > 0 || this.selectedSourceInfo['skipedSources'].length > 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearAll() {
    this.tableConfig = {};
    this.tableConfigDnB = {};
    this.tableConfigBvD = {};
    this.tableConfigLei = {};
    this.selectedSourceTableConfig = {};
    this.selectedSourceDnBTableConfig = {};
    this.selectedSourceBvDTableConfig = {};
    this.selectedSourceLeiTableConfig = {};
  }
}
