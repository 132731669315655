export const REPORT_API_CONSTANT = {
  // SUBSIDIARIES_COUNTRY:
  //   'assets/json-data/mock/subsidiaries/subsidiaries-country.json',
  SUBSIDIARIES_COUNTRY: 'auth/report/subsidaries-version/',
  SUBSIDIARIES_NAME: 'assets/json-data/mock/subsidiaries/subsidiaries-name.json',
  SUBSIDIARIES_NAME_CONFIG: 'assets/json-data/configs/subsidiaries-name.json',
  BRANCHES: 'auth/report/branches-version/',
  //BRANCHES: 'assets/json-data/mock/branches/branches.json',
  BRANCHES_NAME: 'assets/json-data/mock/branches/branches-name.json',
  BRANCHES_NAME_CONFIG: 'assets/json-data/configs/branches-name.json',
  SOURCE_INFO_FILE_DOWNLOAD: 'auth/kyc/report/downloadSourceFile',
  REPORT_ZIP_FILE_DOWNLOAD: 'auth/report/report-version',
  JOB_LEVEL_VIEW_REPORT: 'auth/report/reports',
  ANSWERS_FILE_DOWNLOAD: 'auth/report/downloadQueReportSourceFiles-version',
  //ANSWERS_FILE_DOWNLOAD: 'auth/report/downloadQueReportSourceFiles',
};
