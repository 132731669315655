export const environment = {
  // API_BASE: 'https://devmrsapp02sc:28188/connect/medreview/rest/',
  // API_BASE: 'https://10.162.17.107:8181/connect/medreview/rest/',
  // API_BASE: 'https://10.162.17.69:8181/connect/medreview/rest/',
  // API_BASE: 'https://10.162.17.110:8181/connect/medreview/rest/',

  //API_BASE: 'http://172.16.92.96:18080/connect/medreview/rest/',
  // API_BASE: 'https://172.16.110.67:18181/connect/kyc/rest/',
  
  //API_BASE: 'http://172.16.92.95:18080/connect/medreview/rest/',
  //API_BASE: 'http://172.16.114.101:18080/connect/medreview/rest/',
  //API_BASE: 'http://172.16.82.99:18080/connect/medreview/rest/',

  //API_BASE: '/demoapi/',
  API_BASE: '/api/',
  //API_BASE: 'http://10.180.54.15:18180/connect/medreview/rest/',
  production: true,
};
