import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'kyc-loadmore',
  templateUrl: './kyc-loadmore.component.html',
  styleUrls: ['./kyc-loadmore.component.scss'],
})
export class KycLoadmoreComponent implements OnInit {
  constructor() {}

  preferredAns: string;
  charLimit: number;
  isLess: boolean;

  @Input()
  _data: any;

  @Input()
  get data(): any {
    return this._data;
  }
  set data(val: any) {
    this._data = val;
  }
  ngOnInit() {
    this.charLimit = 900;
    this.isLess = false;
    this.preferredAns = this.data.preferredAnswer.substr(0, this.charLimit);
  }

  public loadMore() {
    if (!this.isLess) {
      this.isLess = true;
      this.preferredAns = this.data.preferredAnswer;
    } else {
      this.isLess = false;
      this.preferredAns = this.data.preferredAnswer.substr(0, this.charLimit);
    }
  }
}
