import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';

@Component({
  selector: 'kyc-generic-message',
  templateUrl: './generic-message.component.html',
  styleUrls: ['./generic-message.component.scss'],
})
export class GenericMessageComponent implements OnInit {
  message: any;
  state: boolean;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit() {
    if (this.config) {
      this.message = this.config.data.message;
      this.state = this.config.data.status;
    }
  }
}
