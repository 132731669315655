import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateProgress'
})
export class CalculateProgressPipe implements PipeTransform {
  finalProgess = 'progress-10';
  transform(value: any, args?: any): any {
    if (value.clientId !== undefined || value.id !== undefined) {
      if (args) {
        if (args.status === 'COMPLETE') {
          value.completedCount = value.sourceCount;
        }
      }
      const res = Math.ceil((value.completedCount / value.sourceCount) * 100);

      this.finalProgess = this.convertToBaseTen(res);
      // this.finalProgess = 'progress-10'; // for temporary this line added
    } else if (value.runId !== undefined) {
      const res = Math.ceil((value.completedCount / value.jobCount) * 100);

      this.finalProgess = this.convertToBaseTen(res);
    } else {
      this.finalProgess = 'progress-10';
    }
    // } else if (args === 'calculateStatus') {
    //   // let sources = value.sources;
    //   // sources = sources.filter(source => source.status === 'Broken');
    //   // if (sources.length > 0) {
    //   //   this.finalProgess = 'Broken';
    //   // } else {
    //   //   sources = value.sources;
    //   //   sources = sources.filter(source => source.status === 'READY');
    //   //   if (sources.length > 0) {
    //   //     this.finalProgess = 'In Progress';
    //   //   } else {
    //   //     this.finalProgess = 'Completed';
    //   //   }
    //   // }
    //   this.finalProgess = 'In Progress';
    // }
    return this.finalProgess;
  }

  convertToBaseTen(res) {
    // at the end res should return 10, 20, 30,...., 100
    if (!res || res <= 10) {
      return 'progress-10';
    } else if (res > 90 && res < 100) {
      return 'progress-90';
    } else if (res % 10 > 0) {
      return 'progress-' + Math.ceil(res / 10) * 10;
    } else {
      return 'progress-' + res;
    }
  }
}
