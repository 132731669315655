import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { LicenseInfoService } from './license-info.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  readonly sessionKeys = {
    AUTH_TOKEN: 'auth_token',
    USER_INFO: 'user_detail',
    REFRESH_TOKEN: 'refresh_token',
    IS_LICENSED: 'isLicensed',
    NOTIFY_USER: 'notifyUser',
  };

  private session: Map<string, any> = new Map<string, any>();

  constructor(private license: LicenseInfoService) {}

  set(key: string, value: any): void {
    this.session.set(key, value);
    localStorage.setItem(key, value);
  }

  get(key: string): any {
    return localStorage.getItem(key);
    // return this.session.get(key);
  }

  isValid(): boolean {
    const accessToken = this.get(this.sessionKeys.AUTH_TOKEN);
    if (accessToken) {
      return true;
    }
    return false;
  }

  flush(): void {
    this.session.clear();
    localStorage.clear();
    this.license.clearData();
  }

  reInitialize(): Observable<boolean> {
    if (this.isStorageValid()) {
      this.mapLocalStorageData();
      Observable.of(true);
    }
    return Observable.of(false);
  }

  private isStorageValid(): boolean {
    const isValidStorage = localStorage.getItem(this.sessionKeys.AUTH_TOKEN) != null || undefined;
    return isValidStorage;
  }

  private mapLocalStorageData(): void {
    let storageKey;
    Object.keys(this.sessionKeys).map((key, value, temp) => {
      storageKey = this.sessionKeys[key];
      if (localStorage.getItem(storageKey)) {
        this.session.set(storageKey, localStorage.getItem(storageKey));
      }
    });
  }
}
