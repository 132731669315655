import { Injectable } from '@angular/core';

import { HTTPAPIService } from 'src/app/common/http.api.service';
import { DASHBOARD_CONFIG_CONSTANT } from '../constants/dashboard.config.constant';

@Injectable({
  providedIn: 'root',
})
export class DashboardConfigService {
  constructor(private http: HTTPAPIService) {}

  getClient() {
    const url = DASHBOARD_CONFIG_CONSTANT.CLIENT;
    return this.http.get(url);
  }

  getClientsForJob() {
    const url = DASHBOARD_CONFIG_CONSTANT.CLIENTS_FOR_JOB;
    return this.http.get(url);
  }

  getSelectedClientsForJob() {
    const url = DASHBOARD_CONFIG_CONSTANT.SELECTED_CLIENTS_FOR_JOB;
    return this.http.get(url);
  }

  getMyJobs() {
    const url = DASHBOARD_CONFIG_CONSTANT.MY_JOBS;
    return this.http.get(url);
  }

  getEditClientsForJob() {
    const url = DASHBOARD_CONFIG_CONSTANT.EDIT_CLIENTS_FOR_JOB;
    return this.http.get(url);
  }

  getEditSelectedClientsForJob() {
    const url = DASHBOARD_CONFIG_CONSTANT.EDIT_SELECTED_CLIENTS_FOR_JOB;
    return this.http.get(url);
  }

  getCompletedJobsConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.COMPLETED_JOBS;
    return this.http.get(url);
  }

  getCompletedJobClientsConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.COMPLETED_JOB_CLIENTS;
    return this.http.get(url);
  }

  getJobsInProgressClientsConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.JOBS_IN_PROGRESS_CLIENTS;
    return this.http.get(url);
  }

  getKeyControllerConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.KEYCONTROLLER_TAB_CONFIG;
    return this.http.get(url);
  }

  getShareHolderConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.SHAREHOLDER_TAB_CONFIG;
    return this.http.get(url);
  }

  getChooseSourceConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.CHOOSE_SOURCE_CONFIG;
    return this.http.get(url);
  }

  getChooseSourceDnBConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.CHOOSE_SOURCE_DNB_CONFIG;
    return this.http.get(url);
  }

  getSelectedSourceConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.SELECTED_SOURCE_CONFIG;
    return this.http.get(url);
  }

  getSelectedSourceDnBConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.SELECTED_SOURCE_DNB_CONFIG;
    return this.http.get(url);
  }

  getBriefNNConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.NN_BRIEF_TABLE;
    return this.http.get(url);
  }

  getKycProfileConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.KYC_PROFILE_TABLE;
    return this.http.get(url);
  }

  getChooseSourceBvDConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.CHOOSE_SOURCE_BvD_CONFIG;
    return this.http.get(url);
  }
  getChooseSourceLeiConfig(){
    const url = DASHBOARD_CONFIG_CONSTANT.CHOOSE_SOURCE_LEI_CONFIG;
    return this.http.get(url);
  }
  getSelectedSourceLeiConfig(){
    const url = DASHBOARD_CONFIG_CONSTANT.SELECTED_SOURCE_LEI_CONFIG;
    return this.http.get(url);
  }

  getSelectedSourceBvDConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.SELECTED_SOURCE_BVD_CONFIG;
    return this.http.get(url);
  }
  

  getClientProflieConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.CLIENT_PROFILE;
    return this.http.get(url);
  }

  getClientDuplicateConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.DUPLICATE_CONFIG;
    return this.http.get(url);
  }

  getNestedClientDuplicateConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.NESTED_DUPLICATE_CONFIG;
    return this.http.get(url);
  }

  getSanctionsPendingClientConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.SANCTION_PENDING_CLIENT;
    return this.http.get(url);
  }

  getSanctionsCompletedClientConfig() {
    const url = DASHBOARD_CONFIG_CONSTANT.SANCTION_COMPLETED_CLIENT;
    return this.http.get(url);
  }
}
