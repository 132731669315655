import { Injectable } from '@angular/core';
import { Server } from 'http';
import { HTTPAPIService } from 'src/app/common/http.api.service';
import { DashboardAPIService } from './dashboard/services/dashboard.api.service';

@Injectable({
  providedIn: 'root',
})
export class RootModuleConfiguraion {
  loaded: boolean = false;
  moduleConfig = {
    admin: false,
    alerts: false,
    profile: false,
    nn: false,
    nameScreening: false,
    transactions: false,
    sourceManagement: false,
    userManagement: false,
    userDashboard: false,
    isExos : false 
  };
  constructor(private dashboardApi: DashboardAPIService) {}

  setConfigurationObject(serverConfigObject) {
    if (serverConfigObject) {
      this.loaded = true;
      this.moduleConfig['admin'] = serverConfigObject['admin'] == 'true' ? true : false;
      this.moduleConfig['alerts'] = serverConfigObject['alerts'] == 'true' ? true : false;
      this.moduleConfig['profile'] = serverConfigObject['profile'] == 'true' ? true : false;
      this.moduleConfig['nn'] = serverConfigObject['negative-news'] == 'true' ? true : false;
      this.moduleConfig['nameScreening'] = serverConfigObject['name-screening'] == 'true' ? true : false;
      this.moduleConfig['transactions'] = serverConfigObject['transactions'] == 'true' ? true : false;
      this.moduleConfig['sourceManagement'] = serverConfigObject['source-management'] == 'true' ? true : false;
      this.moduleConfig['userManagement'] = serverConfigObject['user-management'] == 'true' ? true : false;
      this.moduleConfig['userDashboard'] = serverConfigObject['user-dashboard'] == 'true' ? true : false;
      this.moduleConfig['isExos'] = serverConfigObject['isExos'] == 'true' ? true : false;
    }
  }

  getConfiguration() {
    return this.moduleConfig;
  }

  public checkAvailableModules() {
    this.dashboardApi.availableModules().subscribe((res) => {
      if (res && res['login-permission-info']) {
        this.setConfigurationObject(res['login-permission-info']);
      }
    });
  }
}
