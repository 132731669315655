import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchSessionService {
  searchInputObj: any[];
  searchInput: any[];

  constructor() {}

  searchResult = new Subject();
  isSearchResultPresent = new Subject();
  isTabChangesAfterSearch = new Subject();

  setSearchInput(data: any): void {
    this.searchInput = data;
  }

  getSearchInput(): any {
    return this.searchInput;
  }

  set(data: any): void {
    this.searchInputObj = data;
  }

  get(): any {
    return this.searchInputObj;
  }

  flushSearchInput(): void {
    this.searchInput = null;
  }

  flush(): void {
    this.searchInputObj = null;
  }
}
