import { DatePipe, LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/api';
import { UtilService } from '../common/util.service';
import { ReRunMiniToFull } from '../dashboard/models/clients.model';
import { DashboardAPIService } from '../dashboard/services/dashboard.api.service';
import { ReRunMiniToFullComponent } from '../re-run-mini-to-full/re-run-mini-to-full.component';
import { ReportsAPIService } from '../reports/services/report.api.service';
import { IntertabEventChannelService } from '../shared/services/intertab-event-channel.service';

@Component({
  selector: 'app-client-mini-profile',
  templateUrl: './client-mini-profile.component.html',
  styleUrls: ['./client-mini-profile.component.scss'],
  providers: [DialogService],
})
export class ClientMiniProfileComponent implements OnInit {
  natureOfBusinessCharLimit = 900;
  clientId;
  orcaId: string;
  clientName: string;
  coi: string = 'N/A';
  address: string = 'N/A';
  natureOfBusiness: string = 'N/A';
  incorporatedYear: string = 'N/A';
  websiteURL: string = 'N/A';
  industryClsCode: string = 'N/A';
  totalAnnualRevenue: string = 'N/A';
  totalGrossProfits: string = 'N/A';
  operatingCountries: string = 'N/A';

  sources: any = [];
  allSources: any = [];
  ownershipData = undefined;
  activityLog: any[] = [];
  versionId: any = undefined;
  intervalRef;
  dialogRef;
  miniToFullInProgress: boolean = false;
  constructor(
    private dialogService: DialogService,
    private dashboardApi: DashboardAPIService,
    private api: ReportsAPIService,
    private activatedRoute: ActivatedRoute,
    private util: UtilService,
    private locationStrategy: LocationStrategy,
    private interTabEvent: IntertabEventChannelService,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.clientId = params['id'];
      this.getClientProfileData(this.clientId);
    });
  }

  miniToFullProfile() {
    let dataObj: ReRunMiniToFull = {
      action: 'miniToFull',
      title: `Convert Mini to Full Profile (${this.clientName}) `,
      description: `Run ${this.clientName} for Full Profile`,
      sources: this.allSources,
      clientId: this.clientId,
    };
    this.dialogRef = this.dialogService.open(ReRunMiniToFullComponent, {
      width: '90%',
      height: '60%',
      showHeader: false,
      closable: true,
      closeOnEscape: false,
      data: dataObj,
    });

    this.dialogRef.onClose.subscribe((data) => {
      if (data && data.action) {
        this.interTabEvent.reRunInitiated();
        this.miniToFullInProgress = true;
        this.intervalRef = setInterval(() => {
          this.pollForUpdates(data.runId);
        }, 30000);
      }
    });
  }

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  pollForUpdates(runId) {
    this.dashboardApi.profileRunStatus(runId).subscribe((response: any) => {
      if (response) {
        if (response.Status == 'COMPLETE') {
          clearInterval(this.intervalRef);
          this.navigateToFullProfile();
        } else if (response.isDeleted) {
          clearInterval(this.intervalRef);
          this.miniToFullProcessStopped();
        }
      }
    });
  }

  miniToFullProcessStopped() {
    this.miniToFullInProgress = false;
  }

  navigateToFullProfile() {
    this.router.navigateByUrl('/clientProfile/' + this.clientId);

    //let url = this.util.getApplicationBaseHref() + '#/miniProfile/' + this.clientId;
    //window.location.replace(url);
  }

  private getSourceList() {
    this.dashboardApi.getSourcesList().subscribe(
      (response: any) => {
        if (response && !response.isError) {
          this.allSources = response;
          this.allSources.sort(this.compare);
          // this.preSelectedSources.forEach((element) => {
          //   for (let i = 0; i < this.allSources.length; i++) {
          //     if (element.sourceId == this.allSources[i].id) {
          //       this.allSources[i].isSelected = true;
          //       break;
          //     }
          //   }
          // });
        }
      },
      (error) => {
        console.log('Unable to fetch list of sources');
      }
    );
  }

  compare(a, b) {
    if (a.sourceName < b.sourceName) {
      return -1;
    }
    if (a.sourceName > b.sourceName) {
      return 1;
    }
    return 0;
  }

  getClientProfileData(clientId) {
    this.api.getMiniClientProfile(clientId).subscribe((response) => {
      if (response) {
        this.setData(response);
        this.getSourceList();
      }
    });
  }

  setData(response) {
    this.versionId = response.versionId;
    if (response.clientDetails) {
      this.clientName = response.clientDetails.clientName;
      this.coi = response.clientDetails.coi;
      this.orcaId = response.clientDetails.orcaId;
      this.sources = this.sortSources(response.sources);
    }
    if (response.questions) {
      let parseJsonQuestions = JSON.parse(response.questions);
      this.address = parseJsonQuestions.registeredAddress;
      this.natureOfBusiness = parseJsonQuestions.customerPrincipalNatureOfBusiness;
      this.incorporatedYear = parseJsonQuestions.companyRegisterYear;
      // if (this.incorporatedYear != 'N/A') {
      //   this.incorporatedYear = new DatePipe('en-US').transform(this.incorporatedYear, 'yyyy');
      // }

      this.totalAnnualRevenue = parseJsonQuestions.approxTotalAnnualRevenue;
      this.totalGrossProfits = parseJsonQuestions.relevantKeyFinancialData;
      this.websiteURL = parseJsonQuestions.customerWebsiteUrl;
    }
  }

  sortSources(sources: any[]) {
    return sources.sort(function (a, b) {
      return a.pref - b.pref;
    });
  }
}
