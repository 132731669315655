import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardAPIService } from 'src/app/dashboard/services/dashboard.api.service';

@Injectable()
export class GenericDataService {
  private countryList;
  constructor(private api: DashboardAPIService) {}

  getCountryList() {
    if (this.countryList) {
      return Observable.of(this.countryList);
    } else {
      return this.api.getCoutryIncorporationList();
    }
  }
}
