import { DatePipe, LocationStrategy } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/api';
import { UtilService } from '../common/util.service';
import { ReRunMiniToFull } from '../dashboard/models/clients.model';
import { DashboardAPIService } from '../dashboard/services/dashboard.api.service';
import { ReRunMiniToFullComponent } from '../re-run-mini-to-full/re-run-mini-to-full.component';
import { ReportsAPIService } from '../reports/services/report.api.service';
import { SuccessComponent } from '../shared/components/success/success.component';
import { IntertabEventChannelService } from '../shared/services/intertab-event-channel.service';

@Component({
  selector: 'kyc-client-detailed-profile',
  templateUrl: './client-detailed-profile.component.html',
  styleUrls: ['./client-detailed-profile.component.scss'],
  providers: [DialogService],
})
export class ClientDetailedProfileComponent implements OnInit {
  clientId;
  intervalRef;
  natureOfBusinessCharLimit = 900;
  eventMapping = {
    client_added: 'client-added',
    report_generated: 'report-generated',
    full_refresh: 'full-refresh',
  };

  orcaId: string;
  clientName: string;
  coi: string = 'N/A';
  address: string = 'N/A';
  natureOfBusiness: string = 'N/A';
  incorporatedYear: string = 'N/A';
  websiteURL: string = 'N/A';
  industryClsCode: string = 'N/A';
  totalAnnualRevenue: string = 'N/A';
  totalGrossProfits: string = 'N/A';
  operatingCountries: string = 'N/A';
  isKycProfile: boolean = false;
  isSanctions: boolean = false;
  isTransactions: boolean = false;
  reRunDisabled: boolean = false;
  isNegativeNews: boolean = false;
  preSelectedSources: any = [];
  allSources: any = [];
  ownershipData = undefined;
  activityLog: any[] = [];
  versionId: any = undefined;
  dialogRef;
  constructor(
    private dialogService: DialogService,
    private api: ReportsAPIService,
    private dashboardApi: DashboardAPIService,
    private activatedRoute: ActivatedRoute,
    private util: UtilService,
    private interTabEvent: IntertabEventChannelService,
    private locationStrategy: LocationStrategy
  ) {}

  ngAfterViewInit() {
    this.preventBack();
  }

  private getSourceList() {
    this.dashboardApi.getSourcesList().subscribe(
      (response: any) => {
        if (response && !response.isError) {
          this.allSources = response;
          this.allSources.sort(this.compare);
          this.preSelectedSources.forEach((element) => {
            for (let i = 0; i < this.allSources.length; i++) {
              if (element.sourceId == this.allSources[i].id) {
                this.allSources[i].isSelected = true;
                break;
              }
            }
          });
        }
      },
      (error) => {
        console.log('Unable to fetch list of sources');
      }
    );
  }

  compare(a, b) {
    if (a.sourceName < b.sourceName) {
      return -1;
    }
    if (a.sourceName > b.sourceName) {
      return 1;
    }
    return 0;
  }

  reRun() {
    let dataObj: ReRunMiniToFull = {
      action: 'rerun',
      description: `Re-Run ${this.clientName} for Full Refresh`,
      title: `Re-Run (${this.clientName})`,
      sources: this.allSources,
      clientId: this.clientId,
    };
    this.dialogRef = this.dialogService.open(ReRunMiniToFullComponent, {
      width: '90%',
      height: '60%',
      showHeader: false,
      closable: true,
      closeOnEscape: false,
      data: dataObj,
    });

    this.dialogRef.onClose.subscribe((data) => {
      if (data && data.action) {
        this.interTabEvent.reRunInitiated();
        this.reRunDisabled = true;
        this.intervalRef = setInterval(() => {
          this.pollForUpdates(data.runId);
        }, 30000);
      }
    });
  }
  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  pollForUpdates(runId) {
    this.dashboardApi.profileRunStatus(runId).subscribe((response: any) => {
      if (response) {
        if (response.Status == 'COMPLETE') {
          clearInterval(this.intervalRef);
          this.reRunDisabled = false;
          this.getClientProfileData(this.clientId);
        } else if (response.isDeleted) {
          clearInterval(this.intervalRef);
          this.reRunDisabled = false;
        }
      }
    });
  }

  preventBack() {
    history.pushState(null, null, window.location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.clientId = params['id'];
      this.getClientProfileData(this.clientId);
    });
  }

  openReport(e) {
    const url = this.util.getApplicationBaseHref() + '#/report/' + this.versionId;
    window.open(url, '_blank');
    e.stopPropagation();
  }

  openProfile(e, profileId) {
    const url = this.util.getApplicationBaseHref() + '#/report/' + profileId;
    window.open(url, '_blank');
    e.stopPropagation();
  }

  openOwnership(e) {
    const url = this.util.getApplicationBaseHref() + '#/report/' + this.versionId + '?tab=ownership';
    window.open(url, '_blank');
    e.stopPropagation();
  }

  openAlertsDashboard(e) {
    const url = this.util.getApplicationBaseHref() + '#/dashboard/alerts';
    window.open(url, '_blank');
    e.stopPropagation();
  }

  getClientProfileData(clientId) {
    this.api.getDetailedClientProfileData(clientId).subscribe((response) => {
      if (response) {
        this.setData(response);
        if (this.allSources.length == 0) {
          this.getSourceList();
        }
      }
    });
  }

  setData(response) {
    this.versionId = response.versionId;
    if (response.clientDetails) {
      this.clientName = response.clientDetails.clientName;
      this.coi = response.clientDetails.coi;
      this.orcaId = response.clientDetails.orcaId;
      this.isKycProfile = response.clientDetails.monitorKycProfile == 1 ? true : false;
      this.isSanctions = response.clientDetails.monitorSanction == 1 ? true : false;
      this.isTransactions = response.clientDetails.monitorTransaction == 1 ? true : false;
      this.isNegativeNews = response.clientDetails.monitorNegativeNews == 1 ? true : false;
      this.preSelectedSources = this.sortSources(response.sources);
    }
    if (response.questions) {
      this.address = response.questions.address;
      // '<span class="label">Nature Of Business: </span>' +
      this.natureOfBusiness = response.questions.businessNature;
      this.incorporatedYear = response.questions.incoporatedYear;
      // if (this.incorporatedYear != 'N/A') {
      //   this.incorporatedYear = new DatePipe('en-US').transform(this.incorporatedYear, 'yyyy');
      // }

      this.totalAnnualRevenue = response.questions.annualRevenue;
      this.totalGrossProfits = response.questions.grossProfit;
      this.operatingCountries = response.questions.operatingCountries;
      //this.industryClsCode = response.questions.industryCode;
      this.websiteURL = response.questions.websiteUrl;
    }
    if (response.activityLog) {
      this.activityLog = response.activityLog.activityLogs;
    }
    if (response.ownershipData) {
      let data = JSON.parse(response.ownershipData);
      this.ownershipData = data;
    }
  }

  sortSources(sources: any[]) {
    return sources.sort(function (a, b) {
      return a.pref - b.pref;
    });
  }

  handleChange(e) {
    this.updateOnServer();
  }

  updateOnServer() {
    let payload = {
      kycProfile: this.isKycProfile ? 1 : 0,
      negativeNews: this.isNegativeNews ? 1 : 0,
      sanction: this.isSanctions ? 1 : 0,
      transaction: this.isTransactions ? 1 : 0,
    };
    this.dashboardApi.updateMonitorStatus(this.clientId, payload).subscribe((response) => {
      console.log(response);
    });
  }
}
