import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CHIPS_VALUE_ACCESSOR } from 'primeng/primeng';

@Pipe({
  name: 'dateTimeFormat',
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let formattedDate = '';
    // const _value = Number(value);
    // const _value = Date.parse(value);
    const _value = new Date(value).getTime();
    const dif = Math.floor((Date.now() - _value) / 1000 / 86400);
    if (args === 'startDateTime') {
      formattedDate = super.transform(value, 'MMM d, y');
    } else if (args === 'currentDate') {
      const d = value.getDate();
      const sfx = ['th', 'st', 'nd', 'rd'];
      const val = d % 100;

      const suffix = sfx[(val - 20) % 10] || sfx[val] || sfx[0];
      formattedDate = super.transform(value, 'EE. d');
      formattedDate = formattedDate + suffix;
      formattedDate = formattedDate + super.transform(value, ' MMM.');
    } else if (args === 'currentMonthAndYear') {
      formattedDate = super.transform(value, 'MMM. y');
    } else if (args === 'timeAndDate') {
      formattedDate = super.transform(value, 'h:mm a MMM d, y');
      return formattedDate;
    } else if (args == 'profileFilter') {
      return super.transform(value, 'd MMM y');
    } else if (args === 'alertDates') {
      return super.transform(value, 'MMM d, y');
    } else if (args === 'timeDateDay') {
      formattedDate =
        super.transform(value, 'EEEE') +
        ', ' +
        this.getSuffix(super.transform(value, 'd')) +
        ' ' +
        super.transform(value, 'LLLL y');
    } else if (args !== undefined) {
      formattedDate = super.transform(value, args);
    } else {
      formattedDate = super.transform(value, 'MMM d, y');
    }
    if (IE()) {
      value = this.IEDateFormat(value);
    }

    return formattedDate + ', ' + formatAmPm(new Date(value));
  }

  IEDateFormat(value) {
    let v1 = value.split('T');
    let dateOnly = v1[0];
    let timeString = v1[1];
    let v2 = dateOnly.split('-');
    let year = v2[0];
    let day = v2[2];
    let month = v2[1];
    return month + '/' + day + '/' + year + ' ' + timeString;
  }

  getSuffix(value) {
    const d = value;
    const sfx = ['th', 'st', 'nd', 'rd'];
    const val = d % 100;

    const suffix = sfx[(val - 20) % 10] || sfx[val] || sfx[0];
    return value + suffix;
  }
}

function formatAmPm(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? ' PM' : ' AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ampm;
  return strTime;
}

function IE() {
  return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
}

function convertToNiceDate(time: string) {
  const date = new Date(time),
    diff = (new Date().getTime() - date.getTime()) / 1000,
    daydiff = Math.floor(diff / 86400);

  return (
    (daydiff === 0 &&
      ((diff < 60 && 'Just now') ||
        (diff < 120 && '1 min ago') ||
        (diff < 3600 && Math.floor(diff / 60) + ' mins ago') ||
        (diff < 7200 && '1 hr ago') ||
        (diff < 86400 && Math.floor(diff / 3600) + ' hrs ago'))) ||
    (daydiff === 1 && 'Yesterday')
  );
}
