import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kyc-generic-load-more',
  templateUrl: './generic-load-more.component.html',
  styleUrls: ['./generic-load-more.component.scss'],
})
export class GenericLoadMoreComponent implements OnInit {
  constructor() {}

  @Input()
  charLimit: number;
  isLess: boolean;

  _data: any;
  dataString: string;

  @Input()
  get data(): any {
    return this._data;
  }
  set data(val: any) {
    this._data = val;
    if (val && val.length > this.charLimit) {
      this.dataString = this._data.substr(0, this.charLimit) + '.....';
    } else {
      this.dataString = this._data;
    }
  }

  ngOnInit() {
    this.isLess = false;
  }

  public loadMore() {
    if (!this.isLess) {
      this.isLess = true;
      this.dataString = this._data;
    } else {
      this.isLess = false;
      this.dataString = this._data.substr(0, this.charLimit) + '.....';
    }
  }
}
