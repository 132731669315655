import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/api';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {
  messageLine1: string;
  messageLine2: string;
  icon: string;
  constructor(public config: DynamicDialogConfig) {}

  ngOnInit() {
    console.log(this.config);
    this.messageLine1 =
      this.config.data.messageLine1 == undefined
        ? ''
        : this.config.data.messageLine1;
    this.messageLine2 =
      this.config.data.messageLine2 == undefined
        ? ''
        : this.config.data.messageLine2;
    this.icon = this.config.data.icon == undefined ? '' : this.config.data.icon;
    console.log(this.messageLine1);
  }
}
