import { Injectable } from '@angular/core';
import { Http, ConnectionBackend, RequestOptions, RequestOptionsArgs, Response, Headers, Request } from '@angular/http';

import { throwError as observableThrowError, Observable } from 'rxjs';
import 'rxjs/Rx';

import { environment } from '../../environments/environment';

@Injectable()
export class HttpInterceptor extends Http {
  private headers: Headers;
  private inputUrl: string;
  constructor(backend: ConnectionBackend, defaultOptions: RequestOptions) {
    super(backend, defaultOptions);
  }

  /**
   * Performs a request with `get` http method.
   * @param url
   * @param options
   * @returns {Observable<>}
   */
  get(url: string, options?: RequestOptionsArgs): Observable<any> {
    this.inputUrl = url;
    this.beforeRequest();
    return super
      .get(this.getFullUrl(url), this.requestOptions(options))
      .catch(this.onCatch)
      .do(
        (res: Response) => {
          this.onSuccess(res);
        },
        (error: any) => {
          this.onError(error);
        }
      )
      .finally(() => {
        this.onFinally();
      });
  }

  /**
   * Performs a request with `post` http method.
   * @param url
   * @param options
   * @returns {Observable<>}
   */
  post(url: string, body, options?: RequestOptionsArgs): Observable<any> {
    this.inputUrl = url;
    this.beforeRequest();
    return super
      .post(this.getFullUrl(url), body, this.requestOptions(options))
      .catch(this.onCatch)
      .do(
        (res: Response) => {
          this.onSuccess(res);
        },
        (error: any) => {
          this.onError(error);
        }
      )
      .finally(() => {
        this.onFinally();
      });
  }

  /**
   * Performs a request with `put` http method.
   * @param url
   * @param options
   * @returns {Observable<>}
   */
  put(url: string, body, options?: RequestOptionsArgs): Observable<any> {
    this.inputUrl = url;
    this.beforeRequest();
    return super
      .put(this.getFullUrl(url), body, this.requestOptions(options))
      .catch(this.onCatch)
      .do(
        (res: Response) => {
          this.onSuccess(res);
        },
        (error: any) => {
          this.onError(error);
        }
      )
      .finally(() => {
        this.onFinally();
      });
  }

  /**
   * Performs a request with `delete` http method.
   * @param url
   * @param options
   * @returns {Observable<>}
   */
  delete(url: string, options?: RequestOptionsArgs): Observable<any> {
    this.beforeRequest();
    return super
      .delete(this.getFullUrl(url), this.requestOptions(options))
      .catch(this.onCatch)
      .do(
        (res: Response) => {
          this.onSuccess(res);
        },
        (error: any) => {
          this.onError(error);
        }
      )
      .finally(() => {
        this.onFinally();
      });
  }

  //  Implement POST, PUT, DELETE HERE

  /**
   * Request options.
   * @param options
   * @returns {RequestOptionsArgs}
   */
  private requestOptions(options?: RequestOptionsArgs): RequestOptionsArgs {
    if (options == null) {
      options = new RequestOptions({ headers: this.headers });
    } else if (options.params) {
      options = new RequestOptions({
        headers: this.headers,
        params: options.params,
      });
    }
    let headers;
    if (this.inputUrl.indexOf('/login') >= 0) {
      headers = new Headers({
        // 'Access-Control-Allow-Origin': '*',
        // 'Content-Type': 'text/plain'
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Headers':'X-Requested-With, origin, content-type, accept'
      });
    } else {
      if (this.inputUrl.indexOf('addBulkClients') >= 0 || this.inputUrl.indexOf('executeAlertAction') >= 0) {
        headers = new Headers({
          auth_token: localStorage.getItem('auth_token'),
        });
      } else {
        headers = new Headers({
          'Content-Type': 'application/json',
          auth_token: localStorage.getItem('auth_token'),
        });
      }
    }
    //  let headers = new Headers({ 'Content-Type': 'application/json'});
    if (options.headers == null) {
      options.headers = headers;
    }
    return options;
  }

  /**
   * Build API url.
   * @param url
   * @returns {string}
   */
  private getFullUrl(url: string): string {
    if (url.indexOf('assets/') >= 0) {
      return url;
    }

    return environment.API_BASE + url; //  TODO: use this to add dev or prod base url.
  }

  /**
   * Before any Request.
   */
  private beforeRequest(): void {
    //
  }

  /**
   * After any request.
   */
  private afterRequest(): void {
    //
  }

  /**
   * Error handler.
   * @param error
   * @param caught
   * @returns {ErrorObservable}
   */
  private onCatch(error: any, caught: Observable<any>): Observable<any> {
    return observableThrowError(error);
  }

  /**
   * onSuccess
   * @param res
   */
  private onSuccess(res: Response): void {
    //
  }

  /**
   * onError
   * @param error
   */
  private onError(error: any): void {
    if (error.status !== undefined && error.status === 0) {
      console.error('Please check your internet connection.');
    } else if (error.status !== undefined && error.status === 500) {
      console.error('We are unable to process your request, Please try after some time.');
    }
    //
  }

  /**
   * onFinally
   */
  private onFinally(): void {
    this.afterRequest();
  }
}
