import { Injectable } from '@angular/core';
import { DashboardAPIService } from '../dashboard/services/dashboard.api.service';
import { Observable } from 'rxjs';

export const IS_EXPIRED = 'isExpired';
export const LICENSE_VALID_UPTO = 'licenseValidUpto';
export const REMAINING_CLIENTS = 'remainingClients';
export const REMAINING_DAYS = 'remainingDays';
@Injectable()
export class LicenseInfoService {
  info: { [LICENSE_VALID_UPTO]: string; [REMAINING_DAYS]: string; [REMAINING_CLIENTS]: string; [IS_EXPIRED]: string } = {
    [LICENSE_VALID_UPTO]: undefined,
    [REMAINING_DAYS]: '0',
    [REMAINING_CLIENTS]: '0',
    [IS_EXPIRED]: 'false',
  };
  //the service will recieve data from login
  //from profile header dropdown
  //from job creation response
  constructor(private api: DashboardAPIService) {}

  setLicenseInfo(data) {
    for (let key in this.info) {
      this.info[key] = data[key];
    }

    //localStorage.setItem('licenseInfo', JSON.stringify(this.info));
  }

  getLicenseInfoFromServer(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.getLicensingInfo().subscribe(
        (response: any) => {
          this.setLicenseInfo(response.userLicenseInfo);
          resolve(this.info);
        },
        (error) => {
          console.log('Failed to load license info : ' + error);
          reject(error);
        }
      );
    });
  }

  getLicenseInfoFromLocal() {
    return this.info;
  }

  clearData() {
    for (let key in this.info) {
      this.info[key] = undefined;
    }
  }
}
