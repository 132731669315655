import { RequestInterceptorService } from './auth/request-interceptor.service';
import { AuthService } from './auth/auth.service';
// import { HttpAuthInterceptor } from './auth/http-auth-interceptor';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule, RequestOptions, XHRBackend } from '@angular/http';
import { CommonModule } from '@angular/common';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { JwtHelperService } from '@auth0/angular-jwt';

import { KYCSharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTPAPIService } from './common/http.api.service';
import { AuthGuard } from './auth/auth.guard.service';
import { HttpInterceptor } from './auth/http.interceptor.service';
import { SessionService } from './auth/session.service';
import { AuthNService } from './auth/authn.service';
import { CoreComponent } from './core/core.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { UtilService } from './common/util.service';
import { DiscardJobService } from './common/discard-job.service';

import { LicenseInfoService } from './auth/license-info.service';
import { ToastModule } from 'primeng/toast';
import { RootGuard } from './root-guard';
import { OverlayPanelModule, ButtonModule, TooltipModule, InputSwitchModule } from 'primeng/primeng';
import { OwnershipClusterModule } from './ownership-cluster/ownership-cluster.module';
import { ClientDetailedProfileComponent } from './client-detailed-profile/client-detailed-profile.component';
import { ClientMiniProfileComponent } from './client-mini-profile/client-mini-profile.component';
import { ReRunMiniToFullComponent } from './re-run-mini-to-full/re-run-mini-to-full.component';
import { DynamicDialogModule } from 'primeng/components/dynamicdialog/dynamicdialog';
import { IntertabEventChannelService } from './shared/services/intertab-event-channel.service';
import { GenericLoadMoreComponent } from './shared/components/generic-load-more/generic-load-more.component';
import { GenericDataService } from './shared/services/generic-data.service';
import { DashboardContainerComponent } from './dashboard/dashboard-container/dashboard-container.component';
import { AddClientsComponent } from './dashboard/add-clients/add-clients.component';

@NgModule({
  declarations: [
    AppComponent,
    CoreComponent,
    HeaderComponent,    
    FooterComponent,
    ClientDetailedProfileComponent,
    ClientMiniProfileComponent,
    ReRunMiniToFullComponent,
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    KYCSharedModule,
    HttpModule,
    OverlayPanelModule,
    ButtonModule,
    HttpClientModule,
    ToastModule,
    TooltipModule,
    InputSwitchModule,
    OwnershipClusterModule,
    DynamicDialogModule,
  ],
  providers: [
    UtilService,
    Title,
    SessionService,
    HTTPAPIService,
    AuthNService,
    DiscardJobService,
    AuthService,
    AuthGuard,
    RootGuard,
    LicenseInfoService,
    IntertabEventChannelService,
    GenericDataService,

    // {
    //   provide: HttpInterceptor,
    //   useFactory: getHttpInterceptorLinking,
    //   deps: [XHRBackend, RequestOptions]
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    // ,
    // JwtHelperService
  ],
  entryComponents: [ReRunMiniToFullComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function getHttpInterceptorLinking(backend: XHRBackend, defaultOptions: RequestOptions) {
  return new HttpInterceptor(backend, defaultOptions);
}
