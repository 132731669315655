// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  /**
   *  QA server
   */
  // API_BASE: 'https://devmrsapp02sc:28188/connect/medreview/rest/',
  // API_BASE: 'https://172.16.115.174:8181/connect/medreview/rest/',
  // API_BASE: 'https://10.162.17.69:8181/connect/medreview/rest/',
  // API_BASE: 'https://10.162.17.110:8181/connect/medreview/rest/',
  //API_BASE: 'http://172.16.92.96:18080/connect/medreview/rest/',
  //API_BASE: 'http://172.16.82.99:18080/connect/medreview/rest/',
  //API_BASE: 'http://172.16.92.94:18180/connect/medreview/rest/',
  //API_BASE: 'http://172.16.92.95:18080/connect/medreview/rest/',
  //API_BASE: 'http://172.16.114.101:18080/connect/medreview/rest/',
  //API_BASE: 'https://localhost:18181/connect/kyc/rest/',

  // API_BASE: '/demoapi/',
  /**
   *  Proxy server
   */
  API_BASE: '/api/',
 //API_BASE: 'http://10.180.54.15:18180/connect/medreview/rest/',

  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
