import { Component, OnInit, ElementRef, NgZone } from '@angular/core';
import { FileUpload, DomHandler } from 'primeng/primeng';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'kyc-fileUpload',
  templateUrl: './kyc-fileupload.component.html',
  styleUrls: ['./kyc-fileupload.component.scss']
})
export class KycFileuploadComponent extends FileUpload {
  constructor(
    private el1: ElementRef,
    public domHandler1: DomHandler,
    public sanitizer1: DomSanitizer,
    public zone1: NgZone
  ) {
    super(el1, domHandler1, sanitizer1, zone1);
  }

  // ngOnInit() {}
}
