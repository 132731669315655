import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { SessionService } from './session.service';
import { AuthSuccessRes } from './model/auth.response.model';
import { RootModuleConfiguraion } from '../root-module-config';

@Injectable()
export class AuthNService {
  constructor(
    private router: Router,
    // private http: HttpClient,
    private session: SessionService,
    private rootModulePermissions: RootModuleConfiguraion
  ) {}

  onAuthSuccess(authResponse: AuthSuccessRes) {
    this.initializeSession(authResponse);
  }

  onAuthFailure() {
    this.session.flush();
    this.router.navigate(['/login']);
  }

  getAuthorizationToken() {
    const accessToken = this.session.get(this.session.sessionKeys.AUTH_TOKEN);
    return accessToken;
  }

  // doLogout() {
  //     const logoutUrl = 'logout';
  //     return this.http.post(logoutUrl, {});
  // }

  initializeSession(authResponse: AuthSuccessRes): void {
    //console.log(authResponse);
    this.session.set(this.session.sessionKeys.AUTH_TOKEN, authResponse.auth_token);
    if (authResponse.refreshToken.split(' ')[1]) {
      this.session.set(this.session.sessionKeys.REFRESH_TOKEN, authResponse.refreshToken.split(' ')[1]);
    }
    this.rootModulePermissions.checkAvailableModules();
    this.session.set(this.session.sessionKeys.USER_INFO, JSON.stringify(authResponse.userDetails));
    this.session.set(this.session.sessionKeys.IS_LICENSED, authResponse.isLicensed);
    if (authResponse.isLicensed) {
      this.session.set(this.session.sessionKeys.NOTIFY_USER, authResponse.notifyUser);
    }
  }
}
