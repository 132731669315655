import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KycProfileWidgetComponent } from './admin/admin-tab/user-dashboard/kyc-profile-widget/kyc-profile-widget.component';
import { AuthGuard } from './auth/auth.guard.service';
import { ClientDetailedProfileComponent } from './client-detailed-profile/client-detailed-profile.component';
import { ClientMiniProfileComponent } from './client-mini-profile/client-mini-profile.component';
import { CoreComponent } from './core/core.component';
import { AddClientsComponent } from './dashboard/add-clients/add-clients.component';
import { RootGuard } from './root-guard';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
    canActivate: [AuthGuard, RootGuard],
  },
  { path: 'clientProfile/:id', component: ClientDetailedProfileComponent, canActivate: [AuthGuard] },
  { path: 'miniProfile/:id', component: ClientMiniProfileComponent, canActivate: [AuthGuard] },

  { path: 'login', loadChildren: './login/login.module#LoginModule' },
  // { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule',
  //  canActivate: [AuthGuard], canActivateChild: [AuthGuard] },
  // { path: 'report', loadChildren: './reports/reports.module#ReportsModule', canActivate: [AuthGuard] },
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
 
  {
    path: '',
    component: CoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
      },
      {
        path: 'report/:id',
        loadChildren: './reports/reports.module#ReportsModule',
        canActivate: [AuthGuard],
      },

      {
        path: 'searchOld',
        loadChildren: './search/search.module#SearchModule',
        canActivate: [AuthGuard],
      },
      {
        path: 'search',
        loadChildren: './categorized-search/categorized-search.module#CategorizedSearchModule',
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
